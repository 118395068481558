import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  reqSetIsShowListInvestorModal,
  reqSetIsShowUserTypeModal,
  reqSetUserSelectTypeModal,
  reqSetIsShowListConsultantModal,
} from "../../../reduxs/guide-session/action";
import closeIcon from "../../../assets/images/close-white.svg";

const UserTypeModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isShowUserTypeModal = useSelector(
    (state) => state.guideSession.isShowUserTypeModal
  );

  const handleClose = () => {
    dispatch(reqSetIsShowUserTypeModal(false));
  };

  const showListConsultantModal = () => {
    dispatch(reqSetIsShowListConsultantModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("consultant"));
  };

  const showListInvestorModal = () => {
    dispatch(reqSetIsShowListInvestorModal(true));
    dispatch(reqSetIsShowUserTypeModal(false));
    dispatch(reqSetUserSelectTypeModal("investor"));
  };

  return (
    <>
      <Modal
        className="bg-[rgba(0,_0,_0,_0.9019607843)] w-screen h-screen wrap-user-type-modal"
        show={isShowUserTypeModal}
        onHide={handleClose}
        centered
        size="lg"
      >
        <Modal.Body className="px-[40px] py-[36px] overflow-y-hidden static">
          <div className="absolute right-[80px] top-[50px] w-[26px] h-[24px] z-110">
            <img src={closeIcon} alt="close-icon" onClick={handleClose} />
          </div>
          <div className="text-[#002846] text-[30px] font-['Adobe_Caslon_Pro'] tracking-[2.1px] uppercase leading-[normal]">{t("UserTypeModal.title1")}</div>
          <div className="text-[#002846] text-[30px] font-['Adobe_Caslon_Pro'] tracking-[2.1px] uppercase leading-[normal]">{t("UserTypeModal.title2")}</div>
          <p className="text-[14px] mb-[40px] font-['Proxima_Nova']">
            {t("UserTypeModal.subTitle")}
          </p>
          <div className="flex flex-row gap-4">
            <button
              onClick={showListConsultantModal}
              className="border-[1px] border-[solid] border-[#59899d] flex h-[37px] px-[18px] py-[12px] items-start bg-transparent text-[13px] font-['Adobe_Caslon_Pro'] uppercase text-[#59899d] tracking-[0.12em] leading-[normal] font-normal"
            >
              <span>{t("UserTypeModal.btn1")}</span>
            </button>
            <button
              onClick={showListInvestorModal}
              className="border-[1px] border-[solid] border-[#59899d] flex h-[37px] px-[18px] py-[12px] items-start bg-transparent text-[13px] font-['Adobe_Caslon_Pro'] uppercase text-[#59899d] tracking-[0.12em] leading-[normal] font-normal"
            >
              <span>{t("UserTypeModal.btn2")}</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserTypeModal;
