import "./index.scss";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import CreateIcon from "../../../framework/components/cms/create";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { reqGetListFAQs } from "../../../reduxs/cms/action";
import { CollapsibleContent } from "../../../framework/components/collapsable-content";
import { ZendeskTicketModal } from "../../../framework/components/cms/modals/zendesk-support-ticket";

const CMSFaqs = () => {
  const dispatch = useDispatch();
  const faqList = useSelector((state) => state.cms.faqs);
  const authUser = useSelector((state) => state.user.data);
  
  const [isShowForm, setIsShowForm] = useState(false);

  useEffect(() => {
    dispatch(reqGetListFAQs('groupByTab=t'));
  }, []);

  return (
    <div className="page-container">
      <div className="page-header flex items-center">
        <h1 className="f-title mb-0">FAQS</h1>
      </div>
      <div className="page-body content-page-body">
        <Tabs
          defaultActiveKey={faqList?.[0]?.code}
          className="faq-tabs flex justify-center items-center"
        >
          {faqList.map((tab, i) => {
            return (
              <Tab
                key={i}
                eventKey={tab.code}
                title={tab.displayname}
                className={`flex-col items-center justify-center overflow-auto faq-tab-panes`}
              >
                <div className='scroll-view'>
                  {
                    tab?.faqs?.length
                      ? tab.faqs?.map((item, i) => {
                        return <CollapsibleContent
                          key={i}
                          title={item?.question}
                          content={item?.answer}
                        />
                      })
                      : <div>No Content</div>
                  }
                </div>
              </Tab>
            )
          })}
        </Tabs>
      </div>
      {
        isShowForm &&
        <ZendeskTicketModal
          show={isShowForm}
          setShow={setIsShowForm}
          user={authUser}
        />
      }
      <div onClick={() => setIsShowForm(true)}>
        <CreateIcon />
      </div>
    </div>
  );
};
export default CMSFaqs;
