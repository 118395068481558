import React, { useState, useEffect, useRef } from "react";
import { Form, FormControl, Modal } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import Select from 'react-select';
import closeIcon from "../../../assets/images/close-white.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import mediaAPI from '../../../apis/api/media';
import galleryApi from '../../../apis/api/gallery';
import { getUploadedServerMediaUrl } from "../../../helper/media";
import { ContentSectionOptions } from ".";

const validationSchema = yup.object().shape({
	order: yup.number().required("Order is a required field"),
	contentTitle: yup.string().trim().required("Content title is a required field"),
	contentSection: yup.string().trim().required("Content section is a required field"),
});

const EditContentModal = ({ content, gallery, show, setShow, onSaveSuccess, contentType }) => {
	const [file, setFile] = useState();
	const [order, setOrder] = useState();
	const [contentTitle, setContentTitle] = useState(content?.name || '');
	const [contentSection, setContentSection] = useState(ContentSectionOptions.find(v => v.label === contentType)?.label);
	const [associatedResidence, setAssociatedResidence] = useState('');
	const [isShowDeleteContentModal, setIsShowDeleteContentModal] = useState(false);
	const [isShowReplaceContentModal, setIsShowReplaceContentModal] = useState(false);
  const [errors, setErrors] = useState({}) 
  const fileRef = useRef();

	useEffect(() => {
		if (content) {
			setOrder(content?.order);
		}
	}, [content])

	useEffect(() => {
		if (file) {
			setContentTitle(file.name);
		}
	}, [file])

	const handleUpdateContent = () => {
		switch(contentSection) {
			case "Images":
				handleUpdateContentImage();
				return;
			default:
				return;
		}
	}

	const handleUpdateContentImage = async () => {
		if (!content) return;
		
		try {
			const data = {
				order,
				contentTitle,
				contentSection,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			
			const formData = new FormData();
			formData.append('type', 'image');
			formData.append('order', result?.order);
			formData.append('name', result?.contentTitle)
			formData.append('path', 'media/media_images/image_gallery');

			if (file) {
				formData.append('file', file);
			}

			const updated = await mediaAPI.updateMedia(content.id, formData);
			if (updated?.data) {
				toast.success('Content updated successfully!');
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}

		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleDeleteContent = async () => {
		if (!content) return;

		try {
			const deleted = await mediaAPI.deleteMedia(content.id);
			if (deleted.data) {
				await galleryApi.deleteGallery(gallery?.id);

				toast.success("Content deleted successfully!");
				handleOpenDeleteContentModal();
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {
			toast.error(err.message);
		}
	}

	const handleOpenDeleteContentModal = () => {
		setIsShowDeleteContentModal(true);
	}

	const handleOpenReplaceContentModal = () => {
		setIsShowReplaceContentModal(true);
	}

	const handleClose = () => {
		setShow(false);
	};

	const handleCloseDeleteContentModal = () => {
		setIsShowDeleteContentModal(false);
	}

	const handleCloseReplaceContentModal = () => {
		setIsShowReplaceContentModal(false);
	}

	const onOpenFileInput = () => {
    fileRef.current.click();
  }

	const handleBrowserFile = (e) => {
		setFile(e.target.files[0]);
		handleCloseReplaceContentModal();
	} 

	const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

		if (content && content.path) {
			return getUploadedServerMediaUrl(content.path);
		}

    return '/images/image.png';
  }

	if (isShowReplaceContentModal) {
		return (
			<Modal
				className="wrap-replace-content-modal"
				show={isShowReplaceContentModal}
				onHide={handleCloseReplaceContentModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>REPLACE CONTENT</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseReplaceContentModal} />
					</div>
					<div className="browser-file" onClick={onOpenFileInput}>
						BROWSE FILES
						<input ref={fileRef} accept="image/*" id="file" className="hidden" type="file" onChange={(e) => handleBrowserFile(e)} />
					</div>
					<div className="import" onClick={() => {}}>
						<span className="import-title">Or import from a Matterport URL</span>
						<div className="import-form">
							<input type="text" placeholder="Add a Matterport URL" />
							<span className="import-button">IMPORT</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}

	if (isShowDeleteContentModal) {
		return (
			<Modal
				className="wrap-delete-content-modal"
				show={isShowDeleteContentModal}
				onHide={handleCloseDeleteContentModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Delete Content?</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseDeleteContentModal} />
					</div>
					<div className="confirm">
						Are you sure you want to delete ?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div onClick={handleDeleteContent}>YES, DELETE</div>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<Modal
			className="wrap-update-content-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Update Content</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="content">
					<div className="title">CONTENT</div>
					<img className="img-fluid cursor-pointer" src={renderImagePreview()} alt="" />
					<div onClick={handleOpenReplaceContentModal} className="upload-btn">
						<img className="upload-icon" src={uploadIcon} alt="upload-icon" />
					</div>
				</div>
				<div className="info">
					<div className="title">INFORMATION</div>
					<div className="wrap-form-group">
						<Form.Group>
							<Form.Label>Content Title*</Form.Label>
							<FormControl
								id="contentTitle"
								name="contentTitle"
								type="input"
								value={contentTitle}
								placeholder="Content Title"
								onChange={(e) => setContentTitle(e.target.value)}
							/>
							<span className="error">{errors?.contentTitle}</span>
						</Form.Group>
					</div>
					<div className="wrap-form-group">
						<Form.Group>
							<Form.Label>Content Section*</Form.Label>
							<Select
								className="content-section"
								classNamePrefix="select"
								value={{ value: contentSection , label: contentSection }}
								options={ContentSectionOptions}
								name="contentSection"
								isSearchable={false}
								isDisabled={true}
								menuPortalTarget={document.body}
								styles={{
									menuPortal: base => ({...base, zIndex: 9999})
								}}
								onChange={(item) => setContentSection(item?.value)}
							/>
							<span className="error">{errors?.contentSection}</span>
						</Form.Group>
					</div>
					<div className="wrap-form-group">
						<Form.Group>
							<Form.Label>Content Order*</Form.Label>
							<FormControl
								id="order"
								name="order"
								type="number"
								value={order}
								onChange={(e) => setOrder(e.target?.value)}
							/>
							<span className="error">{errors?.order}</span>
						</Form.Group>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<span className="save" onClick={handleUpdateContent}>SAVE</span>
				<span className="delete" onClick={handleOpenDeleteContentModal}>DELETE CONTENT</span>
			</Modal.Footer>
		</Modal>
	);
};

export default EditContentModal;
