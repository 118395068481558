import React, { useMemo } from "react";
import SideNav from "../../../components/sidenav";
import { classNames } from "../../../helper/utils";
import useUnitList from "./hooks/useUnitList";
import { groupBy } from "lodash";


const UnitList = (props) => {
  const {
    t,
    refs,
    selectedUnit,
    filteredUnits,
    isShowFilter,
    handleUnitClick,
    onScroll,
    onShowFilter,
    pageRef,
  } = useUnitList(props);

  const renderUnitGroup = useMemo(() => {
    const unitGroup = groupBy(filteredUnits || [], "level");
    return (
      <>
        {unitGroup &&
          (Object.keys(unitGroup) || []).map((valueKey, key) => {
            const items = unitGroup[valueKey];
            return (
              <li
                className={"list-group-item list-group-item-unit-list"}
                key={key}
              >
                <div>
                  <p className={"level-title"}>
                    {t("UnitFilter.LEVELS")} {valueKey}
                  </p>
                  <ul>
                    {(items || []).map((item, key2) => {
                      return (
                        <li
                          className={`list-group-item-unit-content ${selectedUnit?.id && selectedUnit?.id != item.id
                            ? "inactive"
                            : ""
                            }`}
                          key={"item-" + key2}
                          onClick={() => handleUnitClick(item)}
                          ref={(ref) => (refs.current[item.id] = ref)}
                        >
                          {item.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
      </>
    );
  }, [filteredUnits, selectedUnit]);

  return (
    <SideNav
      heading={t("common.RESIDENCES")}
      position="left"
      id="lots"
      isDark={false}
    >
      <ul
        ref={pageRef}
        className="list-group scroll-unit disable-scroll-bar"
        onScroll={onScroll}
      >
        {renderUnitGroup}
      </ul>
      <div className="btn-bottom">
        <span
          onClick={() => {
            !isShowFilter && onShowFilter();
          }}
          className={classNames(
            ("text-center mt-1", isShowFilter ? "show-filter" : ""),
          )}
        >
          {t("UnitFilter.SHOW_FILTERS")}
        </span>
      </div>
    </SideNav>
  );
};

export default UnitList;
