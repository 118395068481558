import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "../../utils/helper";
import { PAGES } from "../../constants/options";
import { ReactComponent as Person } from "../../../assets/images/iconPerson.svg";

const CMSButton = ({
    className = "",
}) => {
    const page = useSelector((state) => state.home.page);

    return (
        <span
            className={classNames(
                "cursor-pointer",
                className
            )} 
            onClick={() => window.location.href = "/cms"}
        >
            <Person fill={page === PAGES.ONBOARD_PAGE ? "white" : "black"} />
        </span>
    );
}

export default CMSButton;
