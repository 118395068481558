import React from "react";

const SaveButton = ({
  onClick,
  label = 'Save',
  themeColor = '#6b8fa3',
  className = '',
  btnClassName = '',
}) => {
  return (
    <div className={`${className}`}>
      <button
        className={
          `bg-[#fff] font-normal font-serif text-sm text-[#6b8fa3] text-[${themeColor}]
          border-solid border-1 px-5 py-3 border-[#6b8fa3] border-[${themeColor}]
          transition-all ease-in duration-200 uppercase hover:bg-[#6b8fa3] hover:bg-[${themeColor}] hover:text-white ${btnClassName}`
        }
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  )
};

export default SaveButton;
