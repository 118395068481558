import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import Films from "./components/films";
import FloorPlans from "./components/floor-plans/floor-plans";
import galleryApi from "../../../apis/api/gallery";
import { useDispatch } from "react-redux";
import { reqGetGalleryList } from "../../../reduxs/gallery/action";
import { TopNavTitle } from "../../../components/top-nav/top-nav-title";
import MediaImages from "./components/media-images";
import { FILM_TYPE, FLOOR_TYPE, LEVEL_TYPE, MEDIA_TYPE_ENUM } from "../../constants/master-data";
import { useTranslation } from "react-i18next";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const getSubTabs = (t) => ({
  films: {
    id: "films",
    name: t("FILMS"),
  },
  floorplans: {
    id: "floorplans",
    name: t("FLOORPLANS"),
  },
  images: {
    id: "images",
    name: t("IMAGES"),
  },
});

const GalleryLanding = ({isPresentation}) => {
  const { t } = useTranslation();
  const SUB_TABS = getSubTabs(t);
  const [subTabActive, setSubTabActive] = useState(SUB_TABS.images.id);
  const [floor, setFloor] = useState([]);
  const [floorDetail, setFloorDetail] = useState({});
  // const [imageRender, setImgRender] = useState([]);
  const [filmRender, setFilmRender] = useState([]);

  const dispatch = useDispatch();

  const pageRef = useRef(null)

  useEffect(() => {
    dispatch(reqGetGalleryList());

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB) {
          return onClickSubTab(content.data.name);
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if(pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      })
    }
  }, [isPresentation]);

  useEffect( () => {
    handleFetchData();
  }, [subTabActive]);

  const handleFetchData = async () => {
    if (subTabActive === SUB_TABS.films.id) {
      await handleGetFilmRender(FILM_TYPE.BAKU_FILM);
    }
    if (subTabActive === SUB_TABS.floorplans.id) {
      await handleGetListLevel(LEVEL_TYPE.BAKU_LEVEL);
      await handleGetGalleryDetail(FLOOR_TYPE.BAKU_FLOOR);
    }
  }

  const handleGetListLevel = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setFloor(res.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const handleGetFilmRender = async (type) => {
    const res = await galleryApi.getListGalleryFloor({
      "type[equal]": type,
    });
    if (res && res.data?.length) {
      const sortedDataByOrder = res.data
        .map(data => {
          const order = data.media?.find(m => m.type === MEDIA_TYPE_ENUM.VIDEO)?.order || null;
          return { ...data, order };
        })
        .sort((a, b) => {
          if (a.order === null && b.order === null) return 0;
          else if (a.order === null) return 1;
          else if (b.order === null) return -1;
          else return a.order - b.order;
        });
      setFilmRender(sortedDataByOrder);
    }
  };

  // const handleGetImageRender = async (galleryId) => {
  //   const res = await galleryApi.getGalleryDetail(galleryId);
  //   if (res) {
  //     if (subTabActive === SUB_TABS.films.id) {
  //       setFilmRender(res.data);
  //     } else {
  //       // setImgRender(res.data);
  //     }
  //   }
  // };

  // const handleGetListFloor = async (type) => {
  //   try {
  //     const data = {
  //       "type[equal]": type,
  //     };
  //     const res = await galleryApi.getListGalleryFloor(data);
  //     if (res) {
  //       setFloor(res.data);
  //     }
  //   } catch (e) {
  //     console.log("error get floor");
  //   }
  // };

  const handleGetGalleryDetail = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const floorDetailRes = await galleryApi.findOneGalleryDetail(data);
      if (floorDetailRes) {
        setFloorDetail(floorDetailRes.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const onClickSubTab = (name) => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_ACTIVE_SUB_GALLERY_TAB, {
        name
      })
    }
    console.log('name', name)
    setSubTabActive(name);
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  const renderTab = () => {
    return (
      <div className="sticky-nav">
        <div className="sticky-nav-body">
          {Object.values(SUB_TABS).map((item, idx) => (
            <div
              onClick={() => onClickSubTab(item.id)}
              key={`subTab-${idx}`}
              className="sticky-nav-link"
            >
              <div className="sticky-nav-text">{item.name}</div>
              <div
                style={{
                  height: 1,
                  backgroundColor: "#2D2927",
                  width: "100%",
                  opacity: item.id === subTabActive ? 1 : 0,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderOrla = () => {
    switch (subTabActive) {
      case SUB_TABS.films.id:
        return <Films isPresentation={isPresentation} data={filmRender} />;
      case SUB_TABS.floorplans.id:
        return <FloorPlans isPresentation={isPresentation} data={floor} />;
      case SUB_TABS.images.id:
        return <MediaImages isPresentation={isPresentation}/>;
    }
  };
  const renderContent = () => {
    return <div className="gallery-content" ref={pageRef} onScroll={onScroll}>{renderOrla()}</div>;
  };
  return (
    <div className={`static-page-wf gallery-landing ${isPresentation && "presentation"}`}>
      <TopNavTitle title={t("common.MEDIA")} />
      {renderTab()}
      {renderContent()}
      {
        !isPresentation && <div className="bottom-top" />
      }
    </div>
  );
};
export default GalleryLanding;
