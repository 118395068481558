import endPoint from '../endPoint';

const callZendeskApi = async (endpoint, method, data = {}) => {
  const config = {
    method: method,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const url = process.env.REACT_APP_ZENDESK_API_URL;
  const fetchResult = await fetch(url + endpoint, config);
  const payload = await fetchResult.json();
  if ([201, 200].includes(fetchResult.status)) {
    return payload;
  }
  throw payload;
};

const sendSupportTicket = (data) => callZendeskApi(endPoint.SUPPORT_TICKET, 'POST', data);

export default { sendSupportTicket };
