import "./index.scss";
import { Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import socket from "../../../../helper/socket";
import { useTranslation } from "react-i18next";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import { reqSetIsShowLanguageModal } from "../../../../reduxs/guide-session/action";

const ALLOW_LANGUAGES = [
  { key: 'en', label: 'EN' },
  { key: 'az', label: 'AZ' },
  { key: 'ru', label: 'RU' },
];

const LanguageModal = ({ isPresentation }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isShowLanguageModal = useSelector(
    (state) => state.guideSession.isShowLanguageModal
  );

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLOSE_LANGUAGE) {
          handleClose();
        }
        if (content.action === ACTION_NAME.SELECT_LANGUAGE) {
          selectLanguage(content.data);
        }
      });
    }
  }, [isPresentation]);

  const handleClose = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_LANGUAGE);
    }

    dispatch(reqSetIsShowLanguageModal(false));
  };

  const selectLanguage = (lang) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SELECT_LANGUAGE, lang);
    }
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <>
      <Modal
        className="wrap-user-language-modal bg-black/50"
        show={isShowLanguageModal}
        onHide={() => selectLanguage(ALLOW_LANGUAGES[0].key)}
        centered
        size="md"
      >
        <Modal.Body className="overflow-y-hidden px-10 py-9">
          <div className="title-group flex flex-col mb-10">
            <span className="title">{t('LanguageModal.title')}</span>
            <span className="description">{t('LanguageModal.description')}</span>
          </div>
          <div className="language-buttons flex">
            {
              ALLOW_LANGUAGES.map((lang, index) => (
                <button
                  key={index}
                  onClick={() => selectLanguage(lang.key)}
                  className={`flex flex-col items-center justify-center border-1 border-[#59899d] bg-transparent mr-9 w-14 h-9 pt-1 text-xs text-[#59899d] font-serif hover:!bg-[#6B8FA3] hover:!text-white`}
                >
                  {t(lang.label)}
                </button>
              ))
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LanguageModal;
