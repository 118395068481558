import ApiClient from '../apiClient';
import { defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(defaultFetcher);

const syncUpdateCustomer = (data) =>
  	client.post(endPoint.HUBSPOT_SYNC_UPDATE_CUSTOMER, data);

const syncBatchCustomers = (data) =>
  client.post(endPoint.HUBSPOT_SYNC_BATCH_CUSTOMERS, data);

const poolAllCustomers = (data) =>
  client.post(endPoint.HUBSPOT_POLL_ALL_CUSTOMERS, data);

const syncBatchResidences = (data) =>
  client.post(endPoint.HUBSPOT_SYNC_BATCH_RESIDENCES, data);

const poolAllResidences = (data) =>
  client.post(endPoint.HUBSPOT_POLL_ALL_RESIDENCES, data);

export default {
  syncUpdateCustomer,
  syncBatchCustomers,
  poolAllCustomers,
  syncBatchResidences,
  poolAllResidences,
};
