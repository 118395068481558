import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import LoadingIcon from "../loading-icon";
import socket from "../../../helper/socket";
import Video from "../video";
import { ExploreButton, VideoSkipButton } from "../buttons";
import UserTypeModal from "../../../framework/components/customer-sessions/user-type-modal";
import ListUserSelectModal from "../../../framework/components/customer-sessions/list-user-select-modal";
import CreateUserProfileModal from "../../../framework/components/customer-sessions/create-user-profile-modal";
import { reqSetIsShowReplayVideo } from "../../../reduxs/precinct-explore/action";
import { reqGetUserProfile } from "../../../reduxs/user/action";
import { reqSetPage } from "../../../reduxs/home/action";
import {
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
} from "../../../reduxs/guide-session/action";
import { classNames } from "../../utils/helper";
import "./index.scss";

const Loading = ({
  isLoading,
  isPresentation,
  className = "",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isShowVideo, setShowVideo] = useState(false);
  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);

  const videoRef = useRef(null);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (authUser) {
      analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SATRT_SESSION) {
          onStartSession();
        }
        if (content.action === ACTION_NAME.SKIP_SESSION_VIDEO) {
          startDiscover();
        }
      });
    }
  }, [isPresentation]);

  const startDiscover = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SKIP_SESSION_VIDEO);
    }
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    analytics.track("Agent Started Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientName: customer?.name,
      clientEmail: customer?.email,
      clientPhone: customer?.phone,
    });
  };

  const onStartSession = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SATRT_SESSION);
    }

    dispatch(reqSetIsShowReplayVideo(true));
    setShowVideo(true);
    if (videoRef.current) {
      if (isPresentation) {
        videoRef.current?.mute();
      }
      videoRef.current?.play();
    }
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
  };

  return (
    <div className={classNames(
      "absolute top-[0] left-[0] w-screen h-screen overflow-hidden opacity-100 [transition:1s_opacity] flex items-center text-[#fff] text-center bg-[black] z-100",
      className
    )}>
      <div className={`${isShowVideo ? "hidden" : "block"}`}>
        <div
          className="fixed top-[0] left-[0] min-w-[100vw] min-h-screen bg-no-repeat bg-cover bg-center"
          style={{ backgroundImage: `url("uploads/images/poster.png")`, zIndex: -1 }}
        />
        <div className="pl-[246px] w-[900px]">
          <div className="mb-[30px] text-[48px] tracking-[0.055em] leading-[normal] text-start">
            <span>A NEW HOME, HAVEN</span><br/>
            <span>& HUB IN BAKU</span>
          </div>
          {!!isLoading && <LoadingIcon />}
          <div className={`flex ${isLoading && "opacity-0 pointer-events-none"}`}>
            <ExploreButton isPresentation={isPresentation}/>
          </div>
        </div>
      </div>
      <div className={`[transition:all_1s] ${isShowVideo ? "opacity-100" : "hidden"}`}>
        <Video
          ref={videoRef}
          autoPlay={false}
          onEnded={startDiscover}
          preload="auto"
          muted={isPresentation}
          urlVideo="/uploads/videos/2269289e.mp4"
        />
        <VideoSkipButton isPresentation={isPresentation}/>
      </div>
      <div className="relative">
        <UserTypeModal />
      </div>
      <ListUserSelectModal startDiscover={onStartSession} />
      <CreateUserProfileModal startDiscover={onStartSession} />
      {!isShowVideo && <div className="text-center absolute bottom-[25px] w-[calc(100% - 300px)] left-[350px] z-100"><span>{t('disclaimer')}</span></div>}
    </div>
  );
};

export default Loading;
