import { Suspense, useEffect, useRef, useState } from 'react'
import { Environment, OrbitControls, PerspectiveCamera } from '@react-three/drei'
import SSRBuffers from './SSRBuffers';
import Blit from './blit';
import { ACESFilmicToneMapping, FloatType, PMREMGenerator, Scene, WebGLRenderTarget, sRGBEncoding } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { SphereSceneContainer } from './SphereSceneContainer';
import { Models } from './Models';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { computeSSR } from './ssr';
import { GroundPlane } from './GroundPlane';
import { computeBlur } from './blur';
import { computeDOF } from './dof';

const cameraFar = 25000;
const ssrBuffersProgram = new SSRBuffers(window.innerWidth, window.innerHeight);
const blitProgram = new Blit();
const ssrRadianceRT = new WebGLRenderTarget(window.innerWidth, window.innerHeight,
  { type: FloatType }
);
const radianceRT = new WebGLRenderTarget(window.innerWidth, window.innerHeight,
  // we can't do that: on iphones / ipadPro unfortunately.
  // check the docs to understand the full setup
  // { samples: 4, type: FloatType }
);
const dofRT = new WebGLRenderTarget(window.innerWidth, window.innerHeight, { type: FloatType });
const ssrRT = new WebGLRenderTarget(window.innerWidth, window.innerHeight, { type: FloatType });
const normalBuffer = ssrBuffersProgram.GBuffer.texture[0];
const positionBuffer = ssrBuffersProgram.GBuffer.texture[1];

export const sharedData = {
  envMap: null,
  equirectEnvMap: null,
  stencilTestScene: new Scene(),
  ssrRT: ssrRT,
};

let envmapDownloadStarted = false;

function App() {
  const [envmapDownloaded, setEnvmapDownloaded] = useState(false);
  const controlsRef = useRef(null);

  const { gl } = useThree();

  useEffect(() => {
    gl.toneMapping = ACESFilmicToneMapping;
    gl.outputEncoding = sRGBEncoding;
    gl.setSize( window.innerWidth, window.innerHeight );
    gl.setPixelRatio(1);  
  }, []);

  useEffect(() => {
    if (envmapDownloadStarted) return;
    envmapDownloadStarted = true;

    (async function() {
      let pmrem = new PMREMGenerator(gl);
      let envmapTexture = await new RGBELoader()
        .setDataType(FloatType)
        .loadAsync("envmap2_blur_4.hdr");
      sharedData.equirectEnvMap = envmapTexture;
      let envMap = pmrem.fromEquirectangular(envmapTexture).texture;
    
      sharedData.envMap = envMap;
      setEnvmapDownloaded(true);
    })();
  }, []);

  useFrame(({ gl, scene, camera }) => {
    // if (!controlsRef.current) return;

    // controlsRef.current.update();



    // gl.render(scene, camera);



    function turnOffTonemapping(child) {
      if (child.material?.userData?.uniforms?.uApplyGamma) {
        child.material.userData.uniforms.uApplyGamma.value = false;
      }
    }
    scene.children.forEach(turnOffTonemapping);
    gl.setRenderTarget(ssrRadianceRT);
    gl.render(scene, camera);
    gl.setRenderTarget(null);

    ssrBuffersProgram.compute(gl, scene, camera);

    computeSSR(gl, camera, ssrRT, ssrRadianceRT, ssrBuffersProgram.GBuffer, sharedData.equirectEnvMap);
    let {rt1, rt0} = computeBlur(gl, camera, ssrRT, positionBuffer, normalBuffer);



    gl.autoClear = false;
    gl.setRenderTarget(radianceRT);
    gl.clear(true, true, true);
    function turnOnTonemapping(child) {
      if (child.material?.userData?.uniforms?.uApplyGamma) {
        child.material.userData.uniforms.uApplyGamma.value = true;
      }
    }
    scene.children.forEach(turnOnTonemapping);
    sharedData.stencilTestScene.children.forEach(turnOnTonemapping);
    gl.render(scene, camera);
    gl.render(sharedData.stencilTestScene, camera);
    gl.setRenderTarget(null);
    gl.autoClear = true;  



    computeDOF(gl, radianceRT.texture, positionBuffer, null, cameraFar);


    // blitProgram.blit(gl, ssrRadianceRT.texture, null);
    // blitProgram.blit(gl, rt1.texture, null);
    // blitProgram.blit(gl, positionBuffer, null);
    // blitProgram.blit(gl, normalBuffer, null);
    // blitProgram.blit(gl, radianceRT.texture, null);
    // blitProgram.blit(gl, ssrRT.texture, null);
  }, 2);


  if (!envmapDownloaded) return null;

  return (
    <>
      <Suspense fallback={null}>
        {/* <PerspectiveCamera
          makeDefault
          position={[-84.77, 17.13, -124.55]}
          fov={12.5}
          aspect={window.innerWidth / window.innerHeight}
          near={1}
          far={cameraFar}
        /> */}
        {/* <OrbitControls ref={controlsRef} target={[3.13, 2.98, -7.97]} dampingFactor={0.05} rotateSpeed={0.5} /> */}

        <GroundPlane />
        {/* <SphereSceneContainer /> */}
        {/* <Models /> */}
      </Suspense>
    </>
  )
}

export default App;
