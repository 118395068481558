import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import socket from "../../../../helper/socket";
import * as unitExploreAct from "../../../../reduxs/unit-explore/action";
import { WEBSOCKET_CHANNEL, ACTION_NAME } from "../../../../constants/options";
import debounce from "lodash";
const useUnitFilter = (props) => {
  const { isPresentation, isTransparent } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refFilterRangePrice = useRef();
  const refFilterRangeLot = useRef();
  const ref = useRef([]);
  const pageRef = useRef(null);
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList,
  );
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom,
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect,
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice,
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize,
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability,
  );

  useEffect(() => {
    document.addEventListener("UNIT_EXPLORER_PAGE", function (e) {
      onResetAllFilter();
    });
  }, []);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_UNIT_FILTER) {
          handleApplyFilter(content.data);
        } else if (content.action == ACTION_NAME.CLEAR_ALL_FILTERS) {
          onResetAllFilter();
        } else if (content.action == ACTION_NAME.HIDE_FILTER) {
          onHideFilter();
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight;
          }
        }
      });
    }
  }, [isPresentation]);

  const handleApplyFilter = debounce((data) => {
    const filterName = data.name;
    const item = data?.item;

    switch (filterName) {
      case "FILTER_BEDROOM":
        onChangeBedroom(item, data.filterUnitBedroom);
        break;
      case "FILTER_ASPECT":
        onFilterUnitAspect(item, data.filterUnitAspect);
        break;
      case "FILTER_PRICE":
        onChangePrice(data.price);
        break;
      case "FILTER_LOT_SIZE":
        onChangeLotSize(data.filterLotSize);
        break;
      case "FILTER_AVAILABILITY":
        onChangeAvailability(data.checked, item, data.filterUnitAvailability);
        break;
      default:
        break;
    }
  }, 500);

  const onHideFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  const onChangePrice = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        price: data,
        name: "FILTER_PRICE",
      });
    }
    dispatch(unitExploreAct.reqFilterUnitPrice(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeLotSize = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        filterLotSize: data,
        name: "FILTER_LOT_SIZE",
      });
    }
    dispatch(unitExploreAct.reqFilterUnitLotSize(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeAvailability = (checked, item, filterUnitAvailability) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitAvailability,
        checked,
        name: "FILTER_AVAILABILITY",
      });
    }
    if (checked) {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability([
          ...filterUnitAvailability,
          item,
        ]),
      );
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability(
          filterUnitAvailability.filter((i) => i.id !== item.id),
        ),
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onResetAllFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLEAR_ALL_FILTERS);
    }
    unChecked();
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    dispatch(unitExploreAct.reqFilterUnitAspect(""));
    dispatch(unitExploreAct.reqSetIsShowUnitList(false));
    if (refFilterRangePrice.current) {
      refFilterRangePrice?.current?.reset &&
        refFilterRangePrice.current.reset();
      dispatch(unitExploreAct.reqFilterUnitPrice(""));
    }
    if (refFilterRangeLot.current) {
      refFilterRangeLot?.current?.reset && refFilterRangeLot.current.reset();
      dispatch(unitExploreAct.reqFilterUnitLotSize(""));
    }
  };

  const unChecked = () => {
    if (!ref.current) return;

    for (let i = 0; i < ref.current.length; i++) {
      if (ref.current[i]) {
        ref.current[i].checked = false;
      }
    }
  };

  const onChangeBedroom = (item, filterUnitBedroom) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitBedroom,
        name: "FILTER_BEDROOM",
      });
    }
    if (_.find(filterUnitBedroom, (el) => el.id === item.id)) {
      const newBedRoom = filterUnitBedroom.filter((i) => i.id !== item.id);
      dispatch(unitExploreAct.reqFilterUnitBedroom(newBedRoom));
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitBedroom([...filterUnitBedroom, item]),
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onFilterUnitAspect = (item, filterUnitAspect) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitAspect,
        name: "FILTER_ASPECT",
      });
    }
    const unitAspect = filterUnitAspect?.id == item.id ? "" : item;
    dispatch(unitExploreAct.reqFilterUnitAspect(unitAspect));
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  return {
    isTransparent,
    refFilterRangePrice,
    t,
    refFilterRangeLot,
    ref,
    pageRef,
    isShowUnitList,
    filterUnitPrice,
    filterUnitAspect,
    filterUnitLotSize,
    filterUnitAvailability,
    filterUnitBedroom,
    onHideFilter,
    onScroll,
    isShowFilter,
    onResetAllFilter,
    onChangeAvailability,
    onChangePrice,
    onChangeLotSize,
    onChangeBedroom,
    onFilterUnitAspect,
  };
};

export default useUnitFilter;
