import * as Types from './type';
import authApi from '../../apis/api/auth';
import customerApi from '../../apis/api/customer';
import perferenceApi from '../../apis/api/preference';

const actGetUserProfile = (data) => ({
  type: Types.GET_USER_PROFILE,
  data,
});

const actGetCustomerProfile = (data) => ({
  type: Types.GET_CUSTOMER_PROFILE,
  data,
});

const actResetCustomerProfile = () => ({
  type: Types.RESET_CUSTOMER_PROFILE,
});

export const reqGetUserProfile = () => (dispatch) => {
  return authApi
    .getMe()
    .then((data) => {
      dispatch(actGetUserProfile(data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reqGetCustomerProfile = (id) => (dispatch) => {
  if (!id) return;

  return customerApi
    .getCustomerDetail(id)
    .then((data) => {
      dispatch(actGetCustomerProfile(data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reqSetCustomerProfile = (data) => (dispatch) => {
  return dispatch(actGetCustomerProfile(data));
};

export const reqResetCustomerProfile = () => (dispatch) => {
  return dispatch(actResetCustomerProfile());
};
