import './index.scss';
import * as yup from 'yup';
import Select from 'react-select';
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import customersAPI from "../../../../../apis/api/customer";
import closeIcon from "../../../../../assets/images/close-white.svg";
import HubSpotSyncCreateCustomer from "../../../hubspot/hubspot-sync-create-customer";
import { CountryOptions, getSafeCountryCallingCode } from '../../../phone-input/phone-input';

const validationSchema = yup.object().shape({
	firstname: yup.string().trim().required("First name is a required field"),
	surname: yup.string().trim().required("Last name is a required field"),
	email: yup.string().trim().email("Invalid email format").required("Email is a required field"),
	mobile: yup.string().trim().min(8).required("Phone is a required field"),
	countryCode: yup.string().trim().required("Country code is a required field"),
	isPushedToHubSpot: yup.bool().default(false),
});

const AddCustomerModal = ({ show, setShow, onCreateSuccess }) => {
	const [firstname, setFirstName] = useState('');
	const [surname, setSurName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [address, setAddress] = useState('');
	const [countryCode, setCountryCode] = useState('');
  	const [errors, setErrors] = useState({}) 
	const [isPushedToHubSpot, setIsPushedToHubSpot] = useState(false);

	useEffect(() => {
		setCountryCode("az");
	}, [])

	const handleAddNewCustomer = async () => {		
		try {
			const data = {
				userGroup: "610a4a3351400773f94bfa89", //tenant
				firstname,
				surname,
				email,
				mobile,
				address,
				countryCode,
				isPushedToHubSpot,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await customersAPI.createCustomer(result);
			
			if (res.data) {
				toast.success("Customer created successfully!"); 
				handleClose && handleClose();
				onCreateSuccess && onCreateSuccess();
			} else {
				toast.error(res.message)
			}
		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	const onPushCustomerToHubSpot = (isPushed) => {
		setIsPushedToHubSpot(isPushed);
	}

	return (
		<Modal
			className="wrap-create-customer-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>CREATE NEW CUSTOMER</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>First Name*</Form.Label>
						<FormControl
							id="firstname"
							name="firstname"
							type="input"
							value={firstname}
							placeholder="First Name"
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<span className="error">{errors?.firstname}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>Last Name*</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							type="input"
							value={surname}
							placeholder="Last Name"
							onChange={(e) => setSurName(e.target.value)}
						/>
						<span className="error">{errors?.surname}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Address</Form.Label>
						<FormControl
							id="address"
							name="address"
							type="input"
							value={address}
							placeholder="Address"
							onChange={(e) => setAddress(e.target.value)}
						/>
					</Form.Group>
				</div>				
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Phone Number*</Form.Label>
						<div className="phone">
							<div className="phone-input">
								<div className="flag">
									<div className={`country ${countryCode || 'hidden'}`} />
								</div>
							</div>
							<Select
								className="mobile-code"
								classNamePrefix="select"
								value={{ value: countryCode, label: `+${+getSafeCountryCallingCode(countryCode)}`}}
								options={CountryOptions}
								name="countryCode"
								isSearchable={false}
								menuPortalTarget={document.getElementsByClassName("modal-content")[0]}
								styles={{
									menuPortal: base => ({...base, zIndex: 9999})
								}}
								onChange={(item) => setCountryCode(item?.value)}
							/>
							<FormControl
								className="mobile-input"
								id="mobile"
								name="mobile"
								type="input"
								value={mobile}
								placeholder="Phone"
								onChange={(e) => setMobile(e.target.value)}
							/>
						</div>
						<span className="error">{errors?.countryCode || errors.mobile}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Email*</Form.Label>
						<FormControl
							id="email"
							name="email"
							type="input"
							value={email}
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<span className="error">{errors?.email}</span>
					</Form.Group>
				</div>
				{/* <div className="wrap-form-group">
					<HubSpotSyncCreateCustomer onCreateSuccess={onPushCustomerToHubSpot} />
				</div> */}
			</Modal.Body>
			<Modal.Footer>
				<div className="finish" onClick={handleAddNewCustomer}>Finish</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCustomerModal;
