import "./index.scss";
import React, { useEffect } from "react";
import ROUTE_PATH from "../../routers/path";
import { useHistory } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";
import { useDispatch, useSelector } from "react-redux";
import icBaku from "../../assets/images/baku_logo.svg";
import { reqGetUserProfile } from "../../reduxs/user/action";
import LoginHolding from "../../framework/components/auth/login-holding";
import { reqSetIsShowLanguageModal } from "../../reduxs/guide-session/action";
import LanguageModal from "../../framework/components/modals/language-modal";

const HoldingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);

  useEffect(() => {
    getUserProfile();
    dispatch(reqSetIsShowLanguageModal(true));
  }, []);

  useEffect(() => {
    redirectIfAuthenticated();
  }, [authUser]);

  const redirectIfAuthenticated = () => {
    if (jwtAuth.checkToken() && authUser) {
      history.push("/");
    }
  };

  const handleForgotPassword = () => {
    history.push(ROUTE_PATH.FORGOT_PASSWORD);
  };

  const getUserProfile = async () => {
    try {
      dispatch(reqGetUserProfile());
    } catch (error) {
      return;
    }
  };

  return (
    <div className="wrap-holding-page h-100 bg-black">
      <div className="absolute left-1/2 top-32 -translate-x-1/2 -translate-y-1/2">
        <img
          src={icBaku}
          alt="icon-cube"
          className="w-56"
        />
      </div>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="text-black text-sm w-[666px] bg-white px-10 py-9">
          <h4 className="form__title mb-0">
            WELCOME TO THE BAKU
            <br />
            RITZ-CARLTON RESIDENCES APP
          </h4>
          <LoginHolding />
        </div>
      </div>
      <LanguageModal />
    </div>
  );
};

export default HoldingPage;
