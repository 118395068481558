import React, { useEffect, useRef, useState } from "react";

import { getImagePath } from "../../../../helper/media";
import { IconPlay } from "../../../../components/svgs/icons";
import { AwModal } from "../../../../components/modal-aw/aw-modal";

import "../../../../components/home-gallery/index.scss";
import "./index.css";
import socket from "../../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";
import Video from "../../video";

const Films = ({ data, isInfinity, isPresentation }) => {
  const [isShowVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const videoRef = useRef(null);

  useEffect(() => {
    if(isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if(content.action === ACTION_NAME.PLAY_VIDEO) {
          handlePlayVideo(content.data.url)
        }
        if(content.action === ACTION_NAME.STOP_VIDEO) {
          onStopVideo()
        }
      })
    }
  })

  const handlePlayVideo = (url) => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_VIDEO, {
        url
      })
    }
    setShowVideo(true);
    setUrlVideo(url);
  };

  const onStopVideo = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_VIDEO)
    }
    setShowVideo(false);
    setUrlVideo("");
  };

  return (
    <div className="films-content">
      {data[0]?.media?.map((i, idx) => {
        return (
          <div
            key={`films-${idx}`}
            // data-fancybox="gallery"
            className={`card-films ${isInfinity && "infinity"}`}
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${getImagePath(
                data.thumbnail
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: "lightgray",
            }}
            onClick={() => handlePlayVideo(i.path)}
          >
            {/*<span className="sub">{i.description}</span>*/}
            {/*<span className="title">{`${data.name} ${i.name}`}</span>*/}
            {/*<div className="divider" />*/}
            <IconPlay style={{ width: 43, height: 50 }} />
          </div>
        );
      })}
      {isShowVideo && (
        <AwModal show={true} onClose={onStopVideo}>
          <div className="wrap-video-film">
            <Video 
              loop={true}
              autoPlay={true}
              preload="auto"
              muted={isPresentation}
              urlVideo={getImagePath(urlVideo)}
            />
          </div>
        </AwModal>
      )}
    </div>
  );
};
export default Films;
