import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { GALLERY_TYPE } from "../../../constants/options";
import galleryApi from "../../../apis/api/gallery";
import AddContentModal from "./AddContentModal";
import EditContentModal from "./EditContentModal";
import SearchIcon from "../../../framework/components/cms/search";
import CreateIcon from "../../../framework/components/cms/create";
import { getUploadedServerMediaUrl } from "../../../helper/media";
import mediaAPI from '../../../apis/api/media';

const ContentImage = () => {
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');
  const [galleries, setGalleries] = useState([]);
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [isShowEditContentModal, setIsShowEditContentModal] = useState(false);
  const [seletedGallery, setSeletedGallery] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [search, isSortAsc]);

  const loadData = async () => {
    try {
      const data = {
        "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res && res.data) {
        
        let result = res.data;
        if (search) {
          result = result.filter(
            gallery => gallery.media && gallery.media[0]?.name?.toLowerCase().includes(search)
          )  
        }

        setGalleries(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateSuccess = () => {
    loadData();
  }

  const onSaveSuccess = () => {
    loadData();
  }

  const onEditContent = (gallery) => {
    setSeletedGallery(gallery);
    setIsShowEditContentModal(true);
  }

  const handleUpdateActive = async (e, selectedItem) => {
    if (!selectedItem) return;

    try {			
			const formData = new FormData();
			formData.append('type', 'image');
			formData.append('isActive', e.target.checked);
			const updated = await mediaAPI.updateMedia(selectedItem.id, formData);
			if (updated?.data) {
				toast.success('Content updated successfully!');
			}

		} catch (err) {
			toast.error('Content updated failed!');
		}
  }

  const onSearch = (e) => {
    setSearch(e.target?.value?.toLowerCase());
  } 

  const renderListMedia = () => {
    if (!galleries?.length) {
      return (
        <div className="d-flex justify-content-center">
          NO IMAGE
        </div>
      )
    }
    return galleries?.map((g, index) => {
      const item = g?.media?.[0];
      return (
        <div key={index} className="wrap-media-item" >
          <img className="content-image" src={getUploadedServerMediaUrl(item?.path)} alt="" />
          <div className="content-title-row">
            <span className="content-title">{item?.name || 'Content title'}</span>
            <div className="content-action">
              <div className="status">
                <div className="form-check form-switch my-n1">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="content-state-1"
                        defaultChecked={item?.isActive}
                        onChange={(e) => handleUpdateActive(e, item)}
                      />
                      <label className="form-check-label" htmlFor="content-state-1" />
                    </div>
              </div>
              <div onClick={() => onEditContent(g)}>
                <div className="action-edit">
                  <img className="img-fluid" src="/icons/edit-button.svg" alt="" />
                </div>
              </div>
            </div>  
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="wrap-media-list">
        {renderListMedia()}
      </div>
      {
        isShowAddContentModal &&
        <AddContentModal
          show={isShowAddContentModal}
          setShow={setIsShowAddContentModal}
          onCreateSuccess={onCreateSuccess}
        />
      }
      {
        isShowEditContentModal &&
        <EditContentModal
          show={isShowEditContentModal}
          setShow={setIsShowEditContentModal}
          onSaveSuccess={onSaveSuccess}
          content={seletedGallery?.media?.[0]}
          gallery={seletedGallery}
          contentType={"Images"}
        />
      }
      <div onClick={() => setIsShowAddContentModal(true)}>
        <CreateIcon />
      </div>
      <SearchIcon handleInputChange={onSearch}/>
    </>
  )
}

export default ContentImage;
