import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Video from "../video";
import { reqSetIsShowReplayVideo } from "../../../reduxs/precinct-explore/action";
import { ACTION_NAME } from "../../constants/options";
import { classNames } from "../../utils/helper";
import socket from "../../../helper/socket";
import { VideoSkipReplayButton } from "../buttons";

const VideoReplay = ({
    isPresentation,
    className = "",
}) => {
  const dispatch = useDispatch();

  const onSkipVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SKIP_REPLAY_VIDEO);
    }
    dispatch(reqSetIsShowReplayVideo(false));
  };

  return (
    <div className={classNames(
        "absolute top-[0] left-[0] h-full w-full bg-[black]",
        className
    )}>
        <Video 
            autoPlay={true}
            onEnded={onSkipVideo}
            preload="auto"
            muted={isPresentation}
            urlVideo="/uploads/videos/2269289e.mp4"
        />
        <VideoSkipReplayButton isPresentation={isPresentation}/>
    </div>
  );
};

export default VideoReplay;
