import "./login-holding.scss";
import SaveButton from "../cms/save-button";
import authApi from "../../../apis/api/auth";
import { useTranslation } from "react-i18next";
import jwtAuth from "../../../apis/utils/jwtAuth";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';

const LoginHolding = ({
  postLoginRedirectUrl = '/',
  enableForgotPassword = false,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (email || password) {
      setErrorMessage("");
    }
    // validateEmail(email)
    //   ? setErrorMessage('')
    //   : setErrorMessage('The email must be a valid email address.');
    // validatePassword(password)
    //   ? setErrorMessage('')
    //   : setErrorMessage(
    //       'The password must be minimum eight characters, at least one letter and one number.'
    //     );
  }, [email, password]);

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      history.push(postLoginRedirectUrl || "/");
    } else {
      setErrorMessage("Email not found or password does not match");
    }
  };

  return (
    <div className="modal-form__body">
      <span className='label-form'>Email</span>
      <div className="form-group">
        <input
          type="email"
          id="email"
          placeholder={t('Email')}
          className="form-control"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <span className='label-form'>Password</span>
      <div className="form-group">
        <input
          type="password"
          id="password"
          placeholder="Password"
          aria-describedby="passwordHelp"
          className="form-control"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <p id="passwordHelp" className="text-danger mt-2 mb-0">{errorMessage}</p>
      <div className="flex">
        {
          enableForgotPassword &&
          <div className="flex w-[50%] items-center">
            <Link to="/forgot-password" className="btn-text">
              Forgot password?
            </Link>
          </div>
        }
        <SaveButton
          label={t('Log in')}
          onClick={handleLogin}
          className={`flex flex-col ${enableForgotPassword ? 'w-[50%]' : 'w-[100%]'}`}
          btnClassName="!p-[12px_18px] !text-[13px] !tracking-[0.12em] !leading-4"
        />
      </div>
    </div>
  );
};

export default LoginHolding;
