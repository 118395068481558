import React, { useMemo, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../../../reduxs/unit-explore/action";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
} from "../../../../reduxs/home/action";
import { useTranslation } from "react-i18next";
import socket from "../../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";

const useUnitDetail = (props) => {
  const { isPresentation } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan,
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour,
  );

  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const roomAreas = useMemo(
    () => unit?.roomAreas?.[i18n.language].split(",") ?? [],
    [unit],
  );
  const activeKeys = useSelector((state) => state.unitExplore.activeDetailKeys);
  const pageRef = useRef(null);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_UNIT_FLOORPLAN) {
          handleShowFloorplan();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_FLOORPLAN) {
          handleCloseFloorplan();
        }

        if (content.action === ACTION_NAME.SHOW_UNIT_GALLERY) {
          handleShowGallery();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
          handleCloseGallery();
        }

        if (content.action === ACTION_NAME.SHOW_UNIT_VIEWLINE) {
          handleShowVirtualTour();
        }

        if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
          handleCloseVirtualTour();
        }

        if (content.action === ACTION_NAME.CLICK_UNIT_DETAIL_INFO) {
          handleClickUnitDetail(
            content.data.currentActiveKeys,
            content.data.key,
          );
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight;
          }
        }
      });
    }
  }, [isPresentation]);

  const handleShowFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(unitExploreAct.reqIsShowGallery(false));
    let timeout = setTimeout(() => {
      dispatch(unitExploreAct.reqIsShowFloorplan(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  const handleShowGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.gallery?.id));
      dispatch(unitExploreAct.reqIsShowGallery(!isShowGallery));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleShowVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.virtualTour?.id));
      dispatch(unitExploreAct.reqIsShowVirtualTour(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE);
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
  };

  const handleClickUnitDetail = (currentActiveKeys, key) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_DETAIL_INFO, {
        currentActiveKeys,
        key,
      });
    }

    if (currentActiveKeys.includes(key)) {
      dispatch(unitExploreAct.reqCloseUnitDetailInfo(key));
    } else {
      dispatch(unitExploreAct.reqShowUnitDetailInfo(key));
    }
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };
  return {
    isShowGallery,
    isShowFloorplan,
    isShowVirtualTour,
    roomAreas,
    activeKeys,
    pageRef,
    onScroll,
    handleClickUnitDetail,
    handleCloseVirtualTour,
    handleShowVirtualTour,
    handleShowFloorplan,
    handleCloseFloorplan,
    handleCloseGallery,
    handleShowGallery,
    t,
    unit,
  };
};

export default useUnitDetail;
