import './index.scss';
import React from "react";
import RowUnits from "./row/units";
import RowCustomers from './row/customers';
import RowAmenities from './row/amenities';
import RowFavorites from './row/favorites';

const CmsDataTable = ({
  data,
  tBodyStyles = {},
  wrapperStyles = {},
  selectedData = null,
  onSelectData,
  onEdit,
  onViewFavorite,
  onSaveSuccess,
  type, // should be one of: units, amenities, customers, favorites
}) => {

  const renderTableHeader = () => {
    switch (type) {
      case 'units':
        return <RowUnits onlyTitle />;
      case 'amenities':
        return <RowAmenities onlyTitle />;
      case 'customers':
        return <RowCustomers onlyTitle />;
      case 'favorites':
        return <RowFavorites onlyTitle />;
      default:
        return null;
    }
  }

  const renderListProperty = () => {
    return (data || []).map((item, index) => {
      switch (type) {
        case 'units':
          return (
            <RowUnits
              key={index}
              data={item}
              index={index}
              onClickDetail={() => onSelectData?.(item)}
            />
          );
        case 'amenities':
          return (
            <RowAmenities
              key={index}
              data={item}
              index={index}
              onEdit={onEdit}
            />
          );
        case 'customers':
          return (
            <RowCustomers
              key={index}
              data={item}
              index={index}
              onEditCustomer={onEdit}
              onViewFavorite={onViewFavorite}
              onSaveSuccess={onSaveSuccess}
            />
          );
        case 'favorites':
          return (
            <RowFavorites
              key={index}
              data={item}
              index={index}
            />
          );
        default:
          return null;
      }
    });
  };

  if (!data || !data.length) return;

  return (
    <div className="table-responsive-custom" style={{ ...wrapperStyles }}>
      <table className="w-[100%]">
        <thead>
          {renderTableHeader()}
        </thead>
        <tbody className='block overflow-x-hidden'
          style={{ maxHeight: "calc(100vh - 420px)", ...tBodyStyles }}
        >
          {renderListProperty()}
        </tbody>
      </table>
    </div>
  )
};

export default CmsDataTable;
