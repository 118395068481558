import * as Types from './type';
import customerApi from '../../apis/api/customer';
import unitApi from '../../apis/api/unit';
import media from '../../apis/api/media';
import gallery from '../../apis/api/gallery';
import appointment from '../../apis/api/appointment';
import amenities from '../../apis/api/amenities';
import faqApi from '../../apis/api/faq';

const actSetListCustomers = (data) => ({
  type: Types.GET_LIST_CUSTOMERS,
  data,
});

export const actSetListUnits = (data) => ({
  type: Types.GET_LIST_UNITS,
  data,
});

export const actSetEditableCustomer = (data) => ({
  type: Types.SET_EDITABLE_CUSTOMER,
  data,
});

export const actSetEditableCustomerNote = (data) => ({
  type: Types.SET_EDITABLE_CUSTOMER_NOTE,
  data,
});

export const actSetEditablePropertyColumn = (data) => ({
  type: Types.SET_EDITABLE_PROPERTY_COLUMN,
  data,
});

export const actAddOneCustomer = (data) => ({
  type: Types.ADD_ONE_CUSTOMER,
  data,
});

export const actEditOneCustomer = (data, id) => ({
  type: Types.EDIT_ONE_CUSTOMER,
  data,
  id
})

export const removeOneCustomer = (id) => ({
  type: Types.REMOVE_ONE_CUSTOMER,
  id
})

export const actSetIsShowUploadMediaModal = (data) => ({
  type: Types.IS_SHOW_UPLOAD_MEDIA_MODAL,
  data,
});

export const actSetListMedia = (data) => ({
  type: Types.GET_LIST_MEDIA,
  data,
});

export const actSetEditableMedia = (data) => ({
  type: Types.SET_EDITABLE_MEDIA,
  data,
});

export const actSetIsShowCreateGalleryModal = (data) => ({
  type: Types.SET_IS_SHOW_CREATE_GALLERY_MODAL,
  data,
});

export const actSetSelectedMedia = (data) => ({
  type: Types.SET_SELECTED_MEDIA,
  data,
});

export const actGetListGalleries = (data) => ({
  type: Types.GET_LIST_GALLERIES,
  data,
});

export const actSetEditableGallery = (data) => ({
  type: Types.SET_EDITABLE_GALLERY,
  data,
});

export const actSetIsShowDeleteGalleryModal = (data) => ({
  type: Types.SET_IS_SHOW_DELETE_GALLERY_MODAL,
  data,
});

export const actGetListAppointment = (data) => ({
  type: Types.GET_LIST_APPOINTMENT,
  data,
});

export const actGetListAmenity = (data) => ({
  type: Types.GET_LIST_AMENITY,
  data,
});

export const reqGetListAppointment = (data) => (dispatch) => {
  return appointment.getListAppointment(data).then((res) => {
    dispatch(actGetListAppointment(res));
  });
};

export const reqSetEditableGallery = (galleryId, data) => (dispatch) => {
  if (!galleryId) return dispatch(actSetEditableGallery(false));

  return gallery.getGalleryDetail(galleryId, data).then((res) => {
    dispatch(actSetEditableGallery(res));
  });
};

export const reqSetIsShowDeleteGalleryModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowDeleteGalleryModal(data));
};

export const reqSetSelectedMedia = (data) => (dispatch) => {
  return dispatch(actSetSelectedMedia(data));
};

export const reqSetIsShowCreateGalleryModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowCreateGalleryModal(data));
};

export const reqGetListGalleries = (data) => (dispatch) => {
  return gallery.getListGallery(data).then((res) => {
    dispatch(actGetListGalleries(res));
  });
};

export const reqGetEditableMedia = (mediaId, data) => (dispatch) => {
  if (!mediaId) return dispatch(actSetEditableMedia(false));
  return media.getMediaDetail(mediaId, data).then((res) => {
    dispatch(actSetEditableMedia(res));
  });
};

export const reqGetListMedia = (data) => (dispatch) => {
  return media.getMediaList(data).then((res) => {
    dispatch(actSetListMedia(res));
  });
};

export const reqSetIsShowUploadMediaModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowUploadMediaModal(data));
};

export const reqSetEditableCustomer = (data) => (dispatch) => {
  return dispatch(actSetEditableCustomer(data));
};

export const reqSetEditableCustomerNote = (data) => (dispatch) => {
  return dispatch(actSetEditableCustomerNote(data));
};

export const reqSetEditablePropertyColumn = (data) => (dispatch) => {
  return unitApi.putUnitUpdate(data.id, data).then((res) => {
    dispatch(actSetEditablePropertyColumn(res));
  });
};

export const reqGetListCustomers = (query) => (dispatch) => {
  return customerApi.getCustomerList(query).then((data) => {
    dispatch(actSetListCustomers(data));
  });
};

export const reqGetListUnits = (query) => (dispatch) => {
  return unitApi.getUnitList(query).then((data) => {
    dispatch(actSetListUnits(data));
  });
};

export const reqGetListAmenities = (query) => (dispatch) => {
  return amenities.getAmenitiesList(query).then((data) => {
    dispatch(actGetListAmenity(data));
  });
};

export const actSetListFAQs = (data) => ({
  type: Types.GET_LIST_FAQS,
  data,
});

export const reqGetListFAQs = (query) => (dispatch) => {
  return faqApi.getFaqList(query).then((data) => {
    dispatch(actSetListFAQs(data));
  });
};
