import CommonRow from "./common-row";
import React, { useState } from "react";
import { aspectTitleMapper } from "./units";

const RowFavorite = ({
  onlyTitle = false,
  data = null,
}) => {
  if (onlyTitle) {
    return (
      <CommonRow onlyTitle disableOptions>  
        <th className="col-4">RESIDENCE</th>
        <th className="col-3">FLOOR</th>
        <th className="col-4">AVAILABILITY</th>
        <th className="col-2">FIT</th>
        <th className="col-3">LOT SIZE</th>
        <th className="col-2">BEDS</th>
        <th className="col-3">ASPECT</th>
        <th className="col-4">BASE PRICE</th>
        <th className="col-5">FULL-FIT PRICE</th>
        <th className="col-4">DESCRIPTION</th>
      </CommonRow>
    );
  }

  if (!data) return null;

  const [show, setShow] = useState(false);

  return (
    <CommonRow
      disableOptions
      show={show}
      onShow={() => setShow(!show)}
    >
      <td className="col-4">{data?.name}</td>
      <td className="col-3">{data?.floorplanNumber}</td>
      <td className="col-4">{data?.availabilityStatus}</td>
      <td className="col-2">{data?.fit}</td>
      <td className="col-3">{data?.totalLot}</td>
      <td className="col-2">{data?.bedrooms}</td>
      <td className="col-3">{aspectTitleMapper(data?.aspect)}</td>
      <td className="col-4">{data?.price}</td>
      <td className="col-5">{data?.price}</td>
      <td className="col-4">{show ? data?.description : "..."}</td>
    </CommonRow>
  );
}

export default RowFavorite;
