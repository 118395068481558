import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import classNames from "classnames";

import { getMediaUrl, getUploadedMediaUrl } from "../../helper/media";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import CMSExtraLeft from "../../assets/images/cms-extra-left.svg";
import CMSExtraRight from "../../assets/images/cms-extra-right.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const FloorPlanGallery = (props) => {
  const { floorPlanGalleryRef, isPresentation } = props;
  const [media, setMedia] = useState([]);
  const refs = useRef({});
  const [activeSlide, setActiveSlide] = useState(0);
  const [zoomSize, setZoomSize] = useState(60);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const dispatch = useDispatch();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    className: "slides",
    focusOnSelect: true,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
    afterChange: (index) => {
      setZoomSize(60);
      refs.current[activeSlide].style.backgroundSize = `100%`;
      refs.current[index].style.backgroundSize = `100%`;
      setActiveSlide(index);
    },
  };

  useEffect(() => {
    if (selectedUnit) {
      const floorPlan = selectedUnit?.floorPlan;
      setMedia(floorPlan?.media ? floorPlan.media : []);
    }
  }, [selectedUnit]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        if (content.action === ACTION_NAME.ZOOM_IN_FLOORPLAN) {
          return zoomIn(content.data.currentZoomSize);
        }

        if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
          return zoomOut(content.data.currentZoomSize);
        }

        if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
          return enableTransparent();
        }

        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          return showFilter();
        }
      })
    }
  }, [isPresentation]);

  const zoomIn = (currentZoomSize) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ZOOM_IN_FLOORPLAN, {currentZoomSize});
    }
    refs.current[activeSlide].style.backgroundSize = `${currentZoomSize + 10}%`;
    setZoomSize(currentZoomSize + 10);
  };

  const zoomOut = (currentZoomSize) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN, {currentZoomSize});
    }
    if (currentZoomSize > 10) {
      refs.current[activeSlide].style.backgroundSize = `${currentZoomSize - 10}%`;
      setZoomSize(currentZoomSize - 10);
    }
  };

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(false));
  };

  const enableTransparent = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(true));
  }

  const hideFloorplan = () => {
    if (!isTransparent) {
      enableTransparent()
    }
    else {
      handleCloseBtn()
    }
  };

  const handleCloseBtn = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  return (
    <>
      {isShowFloorplan && (
        <div className="close-btn-floor" onClick={handleCloseBtn}>
          <img
            id="btn_close_gallery"
            width=""
            className="btn_close_gallery"
            src="uploads/images/icons/icon-close.svg"
            alt=""
          />
        </div>
      )}
      <div className="wrap-floor-plan-gallery">
        <Slider
          {...settings}
          ref={floorPlanGalleryRef}
          className="slider-floorplan"
        >
          {media.map((item, key) => {
            return (
              <div key={key}>
                <div
                  ref={(ref) => (refs.current[key] = ref)}
                  style={{
                    backgroundImage: `url(${getUploadedMediaUrl(item.path)})`,
                  }}
                  className="floorplan-item"
                />
              </div>
            );
          })}
        </Slider>
      </div>

      <div className="wrap-zoom-btn">
        {isShowFloorplan && (
          <>
            {isTransparent && (
              <div>
                <img src={CMSExtraLeft} alt="cms-extra-left" />
              </div>
            )}
            <div
              onClick={hideFloorplan}
              className={classNames({
                "m-none": !isTransparent,
              })}
            >
              <span>{isTransparent ? "Close floorplan" : "Close panels"}</span>
              {/* <img className="rotate-image" src="/icons/arrow.svg" alt="" /> */}
            </div>
            {!isTransparent && (
              <div>
                <img
                  src={CMSExtraRight}
                  alt="cms-extra-right"
                  className={classNames({
                    "ml-0": !isTransparent,
                  })}
                />
              </div>
            )}
          </>
        )}
        {isTransparent && (
          <>
            <img
              onClick={() => zoomOut(zoomSize)}
              className="zoom-out"
              src="/icons/minus.svg"
              alt=""
            />
            <img className="zoom-in" onClick={() => zoomIn(zoomSize)} src="/icons/plus.svg" alt="" />
            <div onClick={showFilter}>
              {/* <img src="/icons/arrow.svg" alt="" /> */}
              <span>Reopen panels</span>
            </div>
            <div>
              <img src={CMSExtraRight} alt="cms-extra-right" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FloorPlanGallery;
