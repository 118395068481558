import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "../../utils/helper";
import { USER_GROUP } from "../../constants/master-data";
import { ACTION_NAME, PAGES } from "../../constants/options";
import { reqSetPage } from "../../../reduxs/home/action";
import socket from "../../../helper/socket";
import { reqSetIsShowUserTypeModal } from "../../../reduxs/guide-session/action";
import { useTranslation } from "react-i18next";

const ExploreButton = ({
    isPresentation,
    className = "",
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.user.data);
    const customer = useSelector((state) => state.user.customer);

    const handleExplore = () => {
        if ([USER_GROUP.INVESTOR, USER_GROUP.CONSULTANT].includes(authUser?.userGroup.name)) {
            if (!isPresentation) {
                socket.emitUIActionEvent(ACTION_NAME.SKIP_SESSION_VIDEO);
            }
            dispatch(reqSetPage(PAGES.LANDING_PAGE));
            analytics.track("Agent Started Session", {
                agentId: authUser?.id,
                clientId: customer?.id,
                clientName: customer?.name,
                clientEmail: customer?.email,
                clientPhone: customer?.phone,
            });
        }

        if (authUser?.userGroup.name === USER_GROUP.ADMIN) {
            dispatch(reqSetIsShowUserTypeModal(true));
        }
    }

    if (!authUser) return;

    return (
        <button
            className={classNames(
                "text-[#FFF] font-['Adobe_Caslon_Pro'] text-[13px] not-italic font-normal leading-[normal] tracking-[1.56px] uppercase bg-transparent border-[1px] border-[solid] border-[#FFF] px-[18px] py-[12px] h-[37px]",
                className
            )}
            onClick={handleExplore}>
            {t("DISCOVER")}
        </button>
    );
}

export default ExploreButton;
