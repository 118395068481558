import CommonRow from "./common-row";
import React, { useState } from "react";
import { formatMoney } from "../../../../../helper/unit";

export const aspectTitleMapper = (aspectKey) => {
  switch (aspectKey) {
    case "sea_view":
      return "Sea View";
    case "city_view":
      return "City View";
    default:
      return "";
  }
}

const RowUnits = ({
  onlyTitle = false,
  data = null,
  onClickDetail = () => {},
}) => {
  if (onlyTitle) {
    return (
      <CommonRow onlyTitle>
        <th className="col-3">Residence</th>
        <th className="col-2">Level</th>
        <th className="col-4">Availability</th>
        <th className="col-3">Lot Size</th>
        <th className="col-2">Beds</th>
        <th className="col-3">Aspect</th>
        <th className="col-3">Price</th>
        <th className="col-3">Service</th>
        <th className="col-4">Description</th>
        <th className="col-3">HubSpot ID</th>
      </CommonRow>
    );
  }

  if (!data) return null;

  const [show, setShow] = useState(false);

  return (
    <CommonRow
      show={show}
      onShow={() => setShow(!show)}
      onClickOptions={onClickDetail}
    >
      <td className="col-3">{data?.name}</td>
      <td className="col-2">{data?.level}</td>
      <td className="col-4 text-capitalize">{data?.availabilityStatus}</td>
      <td className="col-3 text-capitalize">{data?.totalLot}</td>
      <td className="col-2">{data?.bedrooms}</td>
      <td className="col-3">{aspectTitleMapper(data?.aspect)}</td>
      <td className="col-3">{formatMoney(data?.price)}</td>
      <td className="col-3">{formatMoney(data?.serviceCharge)}</td>
      <td className="col-4">{show ? data?.description : "..."}</td>
      <td className="col-3">{data?.hubspotId}</td>
    </CommonRow>
  );
}

export default RowUnits;
