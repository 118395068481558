import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { StarIcon } from "../svgs/icons";
import preferenceApi from "../../../apis/api/preference";
import { sendToastMessage } from "../../utils/helper";
import { reqGetCustomerPreference } from "../../../reduxs/precinct-explore/action";
import { classNames } from "../../utils/helper";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

const FavoriteButton = ({
    className = "",
}) => {
    const dispatch = useDispatch();
    const customerId = useQuery().get("customer");
    const preference = useSelector((state) => state.precinctExplore.customerPreference);
    const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
    const customer = useSelector((state) => state.user.customer);
    const authUser = useSelector((state) => state.user.data);

    const listUnitIds = useMemo(() => {
        const units = preference?.units || [];
        return units.map((item) => item.id);
    }, [preference]);
    
    const handleUpdatePreference = (isSave) => {
        if (!selectedUnit) {
            return sendToastMessage("Please select an unit!", "error");
        }
        let data = {};
    
        if (selectedUnit) {
            let unitIds = listUnitIds;
        
            if (unitIds.includes(selectedUnit.id)) {
                unitIds = unitIds.filter((item) => item !== selectedUnit.id);
                data.units = unitIds;
            } else {
                unitIds.push(selectedUnit.id);
                data.units = unitIds;
            }
        }
    
        if (customerId && Object.keys(data).length) {
            data.customer = customerId;
            preferenceApi
                .savePreference(data)
                .then(() => {
                    sendToastMessage(isSave ? "Saved Preference successfully!" : "Unsaved Preference successfully!" , "success");
                    dispatch(reqGetCustomerPreference(customerId));
                    analytics.track("Agent Unsaved Property", {
                        agentId: authUser?.id,
                        clientId: customer?.id,
                        clientEmail: customer?.email,
                        clientPhone: customer?.phone,
                        propertyId: selectedUnit?.id,
                    });
                })
        }
    };

    if (listUnitIds.includes(selectedUnit?.id)) {
        return (
            <span
                className={classNames(
                    className
                )}
                onClick={() => handleUpdatePreference(false)}
            >
                <StarIcon style={{ opacity: 1 }} fill="#59899D" />
            </span>
        );
    }

    return (
        <span 
            className={classNames(
                className
            )}
            onClick={() => handleUpdatePreference(true)}
        >
            <StarIcon style={{ opacity: selectedUnit ? 1 : 0.25 }} />
        </span>
    );
}

export default FavoriteButton;
