import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import customerApi from "../../../apis/api/customer";
import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
} from "../../../reduxs/guide-session/action";
import { PhoneInput } from "../phone-input/phone-input";
import closeIcon from "../../../assets/images/close-white.svg";

import "./index.scss";
import HubSpotSyncCreateCustomer from "../hubspot/hubspot-sync-create-customer";

const CreateUserProfileModal = (props) => {
  const { startDiscover } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [emailAddress, setEmailAddress] = useState();
  const [address, setAddress] = useState();
  const [mobile, setMobile] = useState({
    value: "",
    country: "az",
  });
  const [error, setError] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [isPushedToHubSpot, setIsPushedToHubSpot] = useState(false);

  const notify = (message) => toast.info(message);
  const history = useHistory();
  const authUser = useSelector((state) => state.user.data);
  const isShowCreateInvestorProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateInvestorProfileModal
  );
  const isShowCreateTenantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateTenantProfileModal
  );
  const isShowCreateConsultantProfileModal = useSelector(
    (state) => state.guideSession.isShowCreateConsultantProfileModal
  );

  const handleCreateCustomer = async () => {
    try {
      let userGroup = "606d6f356af09f50f367ee23";

      if (isShowCreateTenantProfileModal) {
        userGroup = "610a4a3351400773f94bfa89";
      }

      if (isShowCreateConsultantProfileModal) {
        userGroup = "63030d2f94bc7bf551da79e3";
      }

      const data = {
        userGroup: userGroup,
        email: emailAddress,
        firstname: firstName,
        surname: lastName,
        address: address,
        mobile: mobile?.value || "",
        countryCode: mobile?.country || "",
        isPushedToHubSpot,
      };

      const res = await customerApi.createCustomer(data);

      if (res.data) {
        analytics.track("Agent Created Client", {
          agentId: authUser.id,
          clientId: res.data?.id,
          clientFistName: res.data?.firstname,
          clientEmail: res.data?.email,
          clientSurname: res.data?.surname,
        });
        history.push({
          search: `?customer=${res?.data?.id}`,
        });
  
        if (isShowCreateInvestorProfileModal) {
          dispatch(reqSetIsShowCreateInvestorProfileModal(false));
          notify("Investor created successfully!");
        }
        if (isShowCreateTenantProfileModal) {
          dispatch(reqSetIsShowCreateTenantProfileModal(false));
          notify("Tenant created successfully!");
        }
        if (isShowCreateConsultantProfileModal) {
          dispatch(reqSetIsShowCreateConsultantProfileModal(false));
          notify("Consultant created successfully!");
        }
        handleResetData();
        startDiscover();
			} else {
        toast.error(res.data || "Create new customer failed!");
      }
    } catch (err) {
      setError(err);
    }
  };

  const handleClose = () => {
    history.push({
      search: "",
    });
    if (isShowCreateInvestorProfileModal) {
      dispatch(reqSetIsShowCreateInvestorProfileModal(false));
      dispatch(reqSetIsShowListInvestorModal(true));
    }
    if (isShowCreateTenantProfileModal) {
      dispatch(reqSetIsShowCreateTenantProfileModal(false));
      dispatch(reqSetIsShowListTenantModal(true));
    }
    if (isShowCreateConsultantProfileModal) {
      dispatch(reqSetIsShowCreateConsultantProfileModal(false));
      dispatch(reqSetIsShowListConsultantModal(true));
    }
    handleResetData();
  };

  const handCloseAllModal = () => {
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    handleResetData();
  };

  const handleResetData = () => {
    setEmailAddress("");
    setMobile({ value: "", country: "au" });
  };

  const onPushCustomerToHubSpot = (isPushed) => {
		setIsPushedToHubSpot(isPushed);
	}

  return (
    <Modal
      className="bg-[#000000e6] w-screen h-screen wrap-list-user-modal"
      show={
        isShowCreateInvestorProfileModal ||
        isShowCreateTenantProfileModal ||
        isShowCreateConsultantProfileModal
      }
      onHide={handCloseAllModal}
      centered
    >
      <Modal.Body className="px-[40px] py-[36px] overflow-y-hidden static">
        <div className="absolute right-[80px] top-[50px] w-[26px] h-[24px] z-110">
          <img src={closeIcon} alt="close-icon" onClick={handleClose} />
        </div>
        <div className="text-[#002846] text-[30px] font-['Adobe_Caslon_Pro'] tracking-[2.1px] uppercase" style={{ fontSize: "28px" }}>
          {t("CreateUserProfileModal.title")}
        </div>
        <p className="mb-4 text-[#2d2927] text-[14px] font-['Proxima_Nova']">
          {t("CreateUserProfileModal.subTitle")}
        </p>
        <div className="mb-[50px]">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col w-[48%]">
              <label className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]" htmlFor="email">{t("CreateUserProfileModal.fn")}</label>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                id="firsName"
                placeholder={t("CreateUserProfileModal.fn")}
                className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
              />
            </div>
            <div className="flex flex-col w-[48%]">
              <label className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]" htmlFor="email">{t("CreateUserProfileModal.ln")}</label>
              <input
                onChange={(e) => setLastName(e.target.value)}
                id="lastName"
                placeholder={t("CreateUserProfileModal.ln")}
                className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
              />
            </div>
          </div>
          <div className="flex flex-col w-[48%] w-100 mt-4">
            <label className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]" htmlFor="address">
              {t("CreateUserProfileModal.Address")}
            </label>
            <input
              onChange={(e) => setAddress(e.target.value)}
              id="address"
              placeholder={t("CreateUserProfileModal.Address")}
              className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
            />
          </div>
          <div className="flex flex-col w-[48%] w-100 mt-4">
            <label className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]" htmlFor="mobile">{t("CreateUserProfileModal.Phone")}</label>
            <PhoneInput
              id="mobile"
              placeholder={t("CreateUserProfileModal.Phone")}
              mobile={mobile}
              setMobile={setMobile}
              className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
            />
          </div>
          <div className="flex flex-col w-[48%] w-100 mt-4">
            <label className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]" htmlFor="email">{t("CreateUserProfileModal.Email")}</label>
            <input
              onChange={(e) => setEmailAddress(e.target.value)}
              id="email"
              placeholder={t("CreateUserProfileModal.Email")}
              className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
            />
          </div>
          {/* <div className="flex w-[48%] w-100 mt-4 items-baseline gap-2">
            <HubSpotSyncCreateCustomer onCreateSuccess={onPushCustomerToHubSpot}/>
          </div> */}
        </div>
        <div className="items-center flex justify-between">
          <button 
            onClick={handleCreateCustomer}
            className="bg-transparent uppercase border-[1px] border-[solid] border-[#59899d] text-[#59899d] text-[13px] font-['Adobe_Caslon_Pro'] tracking-[1.56px] px-[18px] py-[12px] h-[37px] leading-[normal]"  
          >
            {t("common.Submit")}
          </button>
          <button 
            onClick={handleClose}
            className="bg-transparent uppercase border-[1px] border-[solid] border-[#59899d] text-[#59899d] text-[13px] font-['Adobe_Caslon_Pro'] tracking-[1.56px] px-[18px] py-[12px] h-[37px] leading-[normal]"  
          >
            {t("common.Cancel")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserProfileModal;
