import React from 'react';
import { IconCloseButton } from "../../svgs/icons";

export const BaseModal = ({
  open,
  onClose,
  children = null,
  fill = '#2D2927',
  classNames = '',
  isShowBtnClose = true,
  isOutSideClose = false,
  style = {}
}) => {
  return (
    <div
      className={`fixed inset-0 z-111 flex items-center justify-center ${classNames}`}
      style={{ display: open ? "flex" : "none", ...style }}
      onClick={isOutSideClose ? onClose : () => {}}
    >
      {isShowBtnClose && (
        <div className="absolute right-9 top-9 z-111 cursor-pointer" onClick={onClose}>
          <IconCloseButton style={{width: 26}} fill={fill}/>
        </div>
      )}
      <div className="flex items-center justify-center h-[100%] w-[100%]">
        <div className="py-[70px] h-[100%] w-[100%]">{children}</div>
      </div>
    </div>
  )
};
