import React from "react";
import { useSelector } from "react-redux";
import { classNames } from "../../utils/helper";

const CustomerName = ({
    className = "",
}) => {
    const customer = useSelector((state) => state.user.customer);
    
	return (
        <span className={classNames(
            "text-[#ffffff] text-right font-['Adobe_Caslon_Pro'] text-[16px] not-italic font-semibold leading-[normal] uppercase h-[20px]",
            className
        )}>
            {customer ? `${customer?.name}` : `customer name`}
        </span>
    );
}

export default CustomerName;
