import React, { useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";
import parse from "html-react-parser";
import {
  reqSetActiveAreaId,
} from "../../reduxs/home/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { reqFilterUnitPrecinct } from "../../reduxs/unit-explore/action";
import { reqSetIsShowPrecinctExploreDetail } from "../../reduxs/precinct-explore/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import {
  reqSetActiveGalleryModal,
  reqSetIsShowGalleryModal,
} from "../../reduxs/district-future-detail/action";
import GalleryDetail from "../gallery/_gallery-detail";
import { USER_GROUP } from "../../constants/master-data";
import { useTranslation } from "react-i18next";
import socket from "../../helper/socket";
import {selectedHotspot, selectedHotspot3D} from '../3d-scene/CanvasBox';

const RightPanel = (props) => {
  const { i18n } = useTranslation();
  const { page, isPresentation } = props;
  const isShowRightPanel = useSelector(
    (state) => state.exploreModal.isShowExploreModal
  );
  const modal = useSelector((state) => state.exploreModal.modal);
  const dispatch = useDispatch();
  const isShowGallery = useSelector(
    (state) => state.districtdetail.isShowGalleryModal
  );
  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_CLOSE_MODAL) {
          onClose()
        }
      })
    }
  }, [])

  const onClose = () => {
    // handleClickTransportOptions([], {});
    // dispatch(reqSetActiveTransportOption([]));
    // dispatch(reqSetActiveTransportOptionDistricts([]));
    if (selectedHotspot3D?.material?.map && selectedHotspot?.texture) {
      selectedHotspot3D.material.map = selectedHotspot.texture;
    }
    
    dispatch(reqSetActivePrecinctID());
    dispatch(reqSetIsShowExploreModal(false));
  };

  const handleButtonInContent = (event) => {
    const clickedEl = event.target;
    if (clickedEl.id == "btn_close_right_panel") {
      dispatch(reqSetIsShowGalleryModal(false));
      return onClose();
    }

    if (clickedEl.id == "btn_explore_precinct") {
      if (clickedEl.dataset?.area_id) {
        dispatch(reqSetActiveAreaId(clickedEl.dataset.area_id));
      } else {
        dispatch(reqSetActiveAreaId());
      }

      if (clickedEl.dataset?.precinct) {
        dispatch(reqFilterUnitPrecinct(clickedEl.dataset.precinct));
      } else {
        dispatch(reqFilterUnitPrecinct());
      }

      dispatch(reqSetIsShowPrecinctExploreDetail(true));
      onClose();
    }

    if (clickedEl.id === "btn_view_gallery") {
      if (clickedEl.innerHTML?.toLowerCase() === "view gallery") {
        clickedEl.innerHTML = "CLOSE GALLERY";
        dispatch(reqSetActiveGalleryModal(clickedEl.dataset?.gallery_id));
        dispatch(reqSetIsShowGalleryModal(true));
      } else {
        clickedEl.innerHTML = "VIEW GALLERY";
        dispatch(reqSetActiveGalleryModal());
        dispatch(reqSetIsShowGalleryModal(false));
      }
    }
  };

  const renderModalContent = () => {
    try {
      if (page === PAGES.AMENITIES_PAGE) {
        return getContentAmenities(i18n.language);
      }
      if (
        customer?.userGroup?.name === USER_GROUP.INVESTOR ||
        authUser?.userGroup?.name === USER_GROUP.INVESTOR
      ) {
        return parse(modal?.content || "");
      }
      return parse(modal?.content || "", {
        replace: (domNode) => {
          if (domNode.attribs?.id === "investor-details") return <></>;
        },
      });
    } catch (e) {
      console.log("Error open modal", e);
    }
  };

  const getContentAmenities = (type) => {
    switch (type) {
      case "ru":
        return parse(modal?.content?.ru || "");
      case "az":
        return parse(modal?.content?.az || "");
      default:
        return parse(modal?.content?.en || "");
    }
  };

  const handleClickWrapper = (clickedId) => {
    if (clickedId === "id-wrapper-right-panel") {
      //TODO: bug full callstack
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_CLOSE_MODAL)
      }
      return onClose();
    }
  };

  return (
    <>
      <TransitionGroup>
        {isShowRightPanel && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              id="id-wrapper-right-panel"
              className="wrap-right-panel-out"
              onClick={(e) => {
                e.stopPropagation()
                const clickedId = e.target.id
                handleClickWrapper(clickedId)
              }}
            >
              <div
                className={`wrap-right-panel ${page === PAGES.EXPLORE_PRECINCT_PAGE && "above-timeline"
                  }`}
                onClick={(e) => handleButtonInContent(e)}
              >
                {renderModalContent()}
              </div>
            </div>
          </CSSTransition>
        )}
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              className={`wrap-gallery-modal`}
              onClick={(e) => handleButtonInContent(e)}
            >
              <GalleryDetail />
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default RightPanel;
