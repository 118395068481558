import './index.scss';
import { Modal } from "react-bootstrap";
import SaveButton from "../../save-button";
import ModalFormRow from "./modal-form-row";
import ModalFormItem from "./modal-form-item";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../../assets/images/close-white.svg";
import { parseResidenceData, validationResidenceSchema } from "./utils";

const UpdateResidenceModal = ({ show, data, onSave, onHide }) => {
  const [formData, setFormData] = useState()
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (show && data) {
      setFormData(parseResidenceData(data))
    }
  }, [show, data])

  const handleChange = (value, field) => {
    setFormData((currentData) => ({
      ...currentData,
      [field]: value
    }))
    if (!isDirty) {
      setIsDirty(true)
    }
  }

  const handleSubmit = async () => {
    if (!isDirty) return handleClose()
    try {
      await validationResidenceSchema.validate(formData, { abortEarly: false })
      setErrors({})
      await onSave(formData)
      handleClose()
    } catch (error) {
      if (error.inner) {
        const errs = {}
        error.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs)
      }
    }
  }

  const handleClose = () => {
    setFormData(undefined)
    setErrors({})
    setIsDirty(false)
    onHide()
  }

  return (
    <>
      {show && <div className="absolute right-12 top-12 z-[9999] cursor-pointer">
        <img src={closeIcon} alt="close-icon" onClick={handleClose} />
      </div>}
      <Modal
        className="cms-residence-modal bg-black/50"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Body className="rounded-none bg-white w-[630px] px-10 py-9 overflow-y-hidden">
          <div className="flex flex-col mb-10">
            <span className="text-[#002846] font-serif text-3xl font-normal tracking-[2.1px] uppercase">
              Update residence
            </span>
          </div>
          <div>
            <ModalFormRow>
              <ModalFormItem width={'48%'} label={'Residence Name*'} labelHtmlFor={'name'} error={errors?.name}>
                <input
                  onChange={(e) => handleChange(e.target.value, "name")}
                  value={formData?.name}
                  id="name"
                  placeholder="Residence ####"
                  className="form-control"
                />
              </ModalFormItem>
              <ModalFormItem width={'48%'} label={'Residence Level*'} labelHtmlFor={'level'} error={errors?.level}>
                <input
                  onChange={(e) => handleChange(e.target.value, "level")}
                  value={formData?.level}
                  id="level"
                  placeholder="##"
                  className="form-control"
                />
              </ModalFormItem>
            </ModalFormRow>
            <ModalFormRow>
              <ModalFormItem width={'48%'} label={'Availability*'} labelHtmlFor={'availabilityStatus'} error={errors?.availabilityStatus}>
                <select
                  onChange={(e) => handleChange(e.target.value, "availabilityStatus")}
                  id="availabilityStatus"
                  value={formData?.availabilityStatus}
                  placeholder="Residence ####"
                  className="form-control"
                  disabled={!!formData?.hubspotId}
                >
                  <option value="available">Available</option>
                  <option value="reserved">Reserved</option>
                  <option value="sold">Sold</option>
                </select>
              </ModalFormItem>
              <ModalFormItem width={'48%'} label={'Lot Size*'} labelHtmlFor={'totalLot'} error={errors?.totalLot}>
                <input
                  onChange={(e) => handleChange(e.target.value, "totalLot")}
                  value={formData?.totalLot}
                  id="totalLot"
                  placeholder="#####"
                  className="form-control"
                />
              </ModalFormItem>
            </ModalFormRow>
            <ModalFormRow>
              <ModalFormItem width={'48%'} label={'Beds*'} labelHtmlFor={'bedrooms'} error={errors?.bedrooms}>
                <input
                  onChange={(e) => handleChange(e.target.value, "bedrooms")}
                  value={formData?.bedrooms}
                  id="bedrooms"
                  placeholder="#"
                  className="form-control"
                />
              </ModalFormItem>
              <ModalFormItem width={'48%'} label={'Aspect*'} labelHtmlFor={'aspect'} error={errors?.aspect}>
                <select
                  onChange={(e) => handleChange(e.target.value, "aspect")}
                  id="aspect"
                  value={formData?.aspect}
                  placeholder="Sea"
                  className="form-control"
                >
                  <option value="city_view">City View</option>
                  <option value="Sea View">Sea View</option>
                </select>
              </ModalFormItem>
            </ModalFormRow>
            <ModalFormRow>
              <ModalFormItem width={'48%'} label={'Price*'} labelHtmlFor={'price'} error={errors?.price}>
                <input
                  onChange={(e) => handleChange(e.target.value, "price")}
                  value={formData?.price}
                  id="price"
                  placeholder="$$$$$$"
                  className="form-control"
                />
              </ModalFormItem>
              <ModalFormItem width={'48%'} label={'Service Charge*'} labelHtmlFor={'serviceCharge'} error={errors?.serviceCharge}>
                <input
                  onChange={(e) => handleChange(e.target.value, "serviceCharge")}
                  value={formData?.serviceCharge}
                  id="serviceCharge"
                  placeholder="$$$$$$"
                  className="form-control"
                />
              </ModalFormItem>
            </ModalFormRow>
            <ModalFormRow>
              <ModalFormItem width={'48%'} label={'HubSpot ID'} labelHtmlFor={'hubspotId'}>
                <input
                  id="hubspotId"
                  placeholder=""
                  value={formData?.hubspotId}
                  onChange={(e) => handleChange(e.target.value, "hubspotId")}
                  className="form-control"
                />
              </ModalFormItem>
            </ModalFormRow>
            <ModalFormRow>
              <ModalFormItem label={'Description*'} labelHtmlFor={'description'} error={errors?.description}>
                <textarea
                  id="description"
                  placeholder="Description"
                  value={formData?.description}
                  onChange={(e) => handleChange(e.target.value, "description")}
                  className="form-control"
                />
              </ModalFormItem>
            </ModalFormRow>
            <SaveButton onClick={handleSubmit} className="flex flex-col w-[100%]" />
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

export default UpdateResidenceModal;
