import React from 'react';

const ModalFormItem = ({ children, label, labelHtmlFor, error = '', labelStyle = {}, width = '100%' }) => {
  return (
    <div className={`flex flex-col w-[${width}]`}>
      {
        label &&
        <label htmlFor={labelHtmlFor} className='text-[#2d292780] text-left text-sm font-sans font-normal' style={labelStyle}>
          {label}
        </label>
      }
      {children}
      {
        error &&
        <span className="font-sans text-sm font-normal text-[#AE6537]">
          {error}
        </span>
      }
    </div>
  );
};

export default React.memo(ModalFormItem);
