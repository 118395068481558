import { toast } from "react-toastify";
import socket from "../../helper/socket";
import { ACTION_NAME, PAGES } from "../constants/options";

export const sendToastMessage = (message, type, isPresentation = false) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
        message,
        type
      })
    }
    toast[type](message, {
      toastId: "socket-toast"
    });
}

export const isDarkPage = (page) => ([
  PAGES.ONBOARD_PAGE,
  PAGES.UNIT_EXPLORER_PAGE,
  PAGES.AMENITIES_PAGE,
  PAGES.LANDING_PAGE,
].includes(page));

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const handleCollapse = (target) => {
  const selector = target.getAttribute('data-target') || target.getAttribute('href');
  const targets = Array.from(document.querySelectorAll(selector));
  targets.forEach((target) => {
    target.classList.toggle('show');
  });
};

export const isTouchDevice = () => {
  return "ontouchstart" in window || "onmsgesturechange" in window;
};

export const clearFilterUnit = () => {
  const event = new CustomEvent("UNIT_EXPLORER_PAGE");
  document.dispatchEvent(event);
};
