import * as type from './type';

const initialState = {
  data: '',
  metadata: {},
  obj: {},
  customer: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_USER_PROFILE:
      return {
        ...state,
        data: action.data.data,
        metadata: action.data.metadata,
      };
    case type.GET_CUSTOMER_PROFILE:
      return {
        ...state,
        customer: action.data.data,
      };
    case type.RESET_CUSTOMER_PROFILE:
      return {
        ...state,
        customer: null,
      };
    default:
      return state;
  }
};
