import React, { useMemo, useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { USER_GROUP } from "../../constants/master-data";
import { isDarkPage } from "../../utils/helper";
import { PAGES } from "../../constants/options";
import {
    reqSetPage,
} from "../../../reduxs/home/action";
import { classNames } from "../../utils/helper";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const EndSessionButton = ({
    setActiveObjectIds,
    className = "",
}, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const customerId = useQuery().get("customer");
    const customer = useSelector((state) => state.user.customer);
    const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);
    const page = useSelector((state) => state.home.page);
    const authUser = useSelector((state) => state.user.data);

    useImperativeHandle(ref, () => ({
        handleShowPostSessionPopup: () => {
            handleShowPostSessionPopup();
        }
    }))

    const handleShowPostSessionPopup = () => {
        setActiveObjectIds([])
        analytics.track("Agent Ended Session", {
          agentId: authUser?.id,
          clientId: customer?.id,
          clientEmail: customer?.email,
          clientPhone: customer?.mobile,
        });
        dispatch(reqSetPage(PAGES.SESSION_PAGE));
    };

    if (!customerId || !customer) return;

    return (
        <span
            className={classNames(
                "ml-[25px] font-['Adobe_Caslon_Pro'] text-[16px] leading-[18px] text-[#ffffff] flex-none order-2 flex-grow-0 cursor-pointer uppercase font-semibold not-italic",
                isDarkPage(page) && !isShowFloorplan ? "text-[#ffffff]" : "text-[#01344B]",
                className
            )}
            onClick={() => handleShowPostSessionPopup()}
        >
            {t("common.endGuided")}
        </span>
    );
}

EndSessionButton.displayName = 'EndSessionButton';

export default forwardRef(EndSessionButton);
