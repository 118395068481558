import React from "react";

const CommonRow = ({
  onlyTitle,
  disableOptions = false,
  show = false,
  onShow = () => {},
  onClickOptions = () => {},
  children,
}) => {
  if (onlyTitle) {
    return (
      <tr className='table w-[100%] table-fixed'>
        <th className="col-1" />
        {children}
        {
          !disableOptions &&
          <th className="col-2 !text-transparent">Options</th>
        }
      </tr>
    );
  }

  return (
    <tr className={`table w-[100%] table-fixed`}>
      <td className="col-1 !border-b-0">
        <div className="arrow flex items-center h-8">
          <img
            alt=""
            className="cursor-pointer border-none"
            onClick={onShow}
            src={`/icons/arrow-${show ? "up" : "down"}.svg`}
          />
        </div>
      </td>
      {children}
      {
        !disableOptions &&
        <td className="col-1">
          <div className="flex items-center h-8">
            <div className="actionButton" onClick={onClickOptions}>
              <img src={`/icons/dots.svg`} alt="" />
            </div>
          </div>
        </td>
      }
    </tr>
  );
}

export default CommonRow;
