import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "../../utils/helper";
import { ACTION_NAME, PAGES } from "../../constants/options";
import { reqSetPage } from "../../../reduxs/home/action";
import socket from "../../../helper/socket";
import { useTranslation } from "react-i18next";

const VideoSkipButton = ({
    isPresentation,
    className = "",
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.user.data);
    const customer = useSelector((state) => state.user.customer);

    const handleSkipVideo = () => {
        if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.SKIP_SESSION_VIDEO);
        }
        dispatch(reqSetPage(PAGES.LANDING_PAGE));
        analytics.track("Agent Started Session", {
            agentId: authUser?.id,
            clientId: customer?.id,
            clientName: customer?.name,
            clientEmail: customer?.email,
            clientPhone: customer?.phone,
        });
    }

    return (
        <button
          onClick={handleSkipVideo}
          className={classNames(
            "absolute bottom-[75px] right-[35px] gap-[10px] flex-shrink-0 bg-transparent text-[white] justify-center items-center px-[18px] py-[12px] border-[1px] border-[solid] border-[#ffffff]",
            className
          )}
        >
          {t("common.skipVideo")}
        </button>
    );
}

export default VideoSkipButton;
