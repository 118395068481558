import React from 'react';
import { useSelector } from 'react-redux';
import socket from '../../helper/socket';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reqGetUserProfile } from '../../reduxs/user/action';
import Panorama from '../../framework/components/panorama/panorama';

const PanoramaPage = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.user.data);

    useEffect(() => {
        dispatch(reqGetUserProfile());
    }, []);

    useEffect(() => {
        if (authUser && authUser.id) {
            socket.auth = {
                userId: authUser.id,
            };
            socket.connect();

            return () => {
                socket.disconnect();
            };
        }
    }, [authUser]);

    const renderPanorama = () => {
        const names = [
          'Location 1',
          'Location 2',
          'Location 3',
          'Location 4',
          'Location 5',
          'Location 6',
          'Location 7',
          'Location 8',
        ];

      const images = [
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_0.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_1.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_2.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_3.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_4.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_5.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_6.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Day_7.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_33.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_34.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_35.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_36.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_37.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_38.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_39.jpg',
        '/uploads/panorama/day_night/PANO0021_Panorama_Night_40.jpg',
      ];

        return <Panorama name={"Location 1"} isPresentation={false} names={names} images={images} />;
    };

    return (
        <div>
            {renderPanorama()}
        </div>
    );
};

export default PanoramaPage;
