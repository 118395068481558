import React, { useEffect, useRef, useState } from 'react';
import * as PANOLENS from 'panolens';
import { useSelector } from 'react-redux';
import { ImageLoader } from 'three';
import './index.scss';

const DAY_NIGHT = {
  day: 'day',
  night: 'night',
}

const Panorama = (props) => {
  const { names, images } = props;

  const authUser = useSelector((state) => state.user.data);
  const isLoading = true;
  const refCurrentIndex = useRef(0);
  const [panos, setPanos] = useState([]);
  const [isDayOrNight, setIsDayOrNight] = useState(DAY_NIGHT.day);

  let currentIndex = 0;
  
  const viewerRef = useRef(null);

  useEffect(() => {
    onInitPanorama();
  }, [])

  if (authUser == null || authUser == undefined) {
    return (
      <>
        <div id="wrap-panorama" />
      </>
    );
  }

  const onInitPanorama = () => {
    const loader = new ImageLoader();
    let viewer = null;

    const length = images.length;
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < images.length; index++) {
      const imageURL = images[index];

      loader.load(imageURL, function ( image ) {
        let pano = new PANOLENS.ImagePanorama(image);

        setPanos(prev => ([...prev, pano]));
        viewer.add(pano);
      }, undefined, function (error) {
        console.error( 'An error happened.' , error);
      });
    };

    viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama-view")
    });

    let cameraPos = {};

    if (viewer.camera != null && authUser != null) {
      viewer.camera.userData.id = authUser.id;
    }
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    let control = viewer.getControl();
    control.noZoom = true;
    viewer.onChange = () => {
      viewer.update();
      viewer.render();

      let position = {
        x: viewer.camera.position.x,
        y: viewer.camera.position.y,
        z: viewer.camera.position.z
      };

      let quaternion = {
        x: viewer.camera.quaternion.x,
        y: viewer.camera.quaternion.y,
        z: viewer.camera.quaternion.z,
        w: viewer.camera.quaternion.w,
      }
      let content = {
        position: position,
        quaternion: quaternion,
        zoom: viewer.camera.zoom,
        fov: viewer.camera.fov,
        authUserId: viewer.camera.userData.id,
        index: currentIndex
      };
      if (cameraPos !== JSON.stringify(content)) {
        cameraPos = JSON.stringify(content);
      }
    }

    viewerRef.current = viewer;
  }

  const renderListPanorama = () => {
    let i = 0;
    const loading = () => {
      if (i == 0) {
        i = 1;
        let bar = document.getElementById('myBar');
        let progress = document.getElementById('myProgress');
        bar.style.display = 'block';
        progress.style.display = 'block';
        let width = 1;
        let id = setInterval(frame, 10);
        function frame() {
          if (width >= 100) {
            bar.style.display = 'none';
            progress.style.display = 'none';
            clearInterval(id);
            i = 0;
          } else {
            width++;
            bar.style.width = width + '%';
          }
        }
      }
    };
    const select = () => {
      let item = document.getElementsByClassName('item-name');
      for (let i = 0; i < item.length; i++) {
        item[i].addEventListener('click', function () {
          let current = document.getElementsByClassName('active');
          current[0].className = current[0].className.replace(' active', '');
          this.className += ' active';
        });
      }
    }
    return (
      <div className="panorama-side-bar">
        <div className="list-panorama">
          {names.map((item, index) => (
            <div key={index} className="list-panorama-item">
              <span
                id={`e${index}`}
                className={`item-name ${index === 0 ? 'active' : ''}`}
                onClick={() => {
                  loading();
                  select();
                  refCurrentIndex.current = index;
                  if (panos[index] == undefined) {
                    return;
                  }
                  changeDayNight(true)
                }}
              >
                {item}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const changeDayNight = (val) => {
    if(val === DAY_NIGHT.day){
      setIsDayOrNight(val);
      panos[0] && viewerRef.current.setPanorama(panos[0]);
    } else {
      setIsDayOrNight(val);
      panos[8] && viewerRef.current.setPanorama(panos[10]);
    }
  }

  const renderBtnDayNight = () => {
    return(
      <div className='absolute top-[150px] -translate-x-1/2 -translate-y-1/2 left-2/4 flex flex-row z-1'>
        <button
          className={`${isDayOrNight === DAY_NIGHT.day ? 'bg-[#000000] text-[#FFFFFF]' : ''}
            flex flex-row justify-center items-center gap-[15px] bg-[#FFFFFF] rounded-[50px] text-[#000000] mx-[5px] my-[0] border-[none] px-[20px] py-[5px]`
          }
          onClick={() => changeDayNight(DAY_NIGHT.day)}
        >
          <span>
              Day
          </span>
        </button>
        <button className={`${isDayOrNight === DAY_NIGHT.night ? 'bg-[#000000] text-[#FFFFFF]' : ''}
            flex flex-row justify-center items-center gap-[15px] bg-[#FFFFFF] rounded-[50px] text-[#000000] mx-[5px] my-[0] border-[none] px-[20px] py-[5px]`
          }
          onClick={() => changeDayNight(DAY_NIGHT.night)}
        >
          <span>
              Night
          </span>
        </button>
      </div>
    )
  }

  return (
    <>
      {isLoading &&
        <div className="position-absolute top-50 start-50 translate-middle intro-content pt-3">
          <div className='w-[620px] h-[223px] flex justify-center items-center bg-[#000000] [box-shadow:0_10px_20px_rgba(0,_0,_0,_0.25)]'>
            <span className='not-italic font-semibold text-[24px] leading-[31px] text-center uppercase text-[#FFFFFF]'>
              VIRTUAL TOUR LOADING ...
            </span>
          </div>
        </div>
      }
      <div id="wrap-panorama-view" className='absolute w-full h-full z-1'/>
      {renderBtnDayNight()}
      {/* {renderListPanorama()} */}
      <div id="myProgress" className='w-full absolute bottom-[0] h-[10px] bg-[black] hidden z-100'>
        <div id="myBar" className='absolute w-[1%] h-[10px] bg-[grey] hidden'/>
      </div>
    </>
  );
};

export default Panorama;
