import React, { useEffect, useState } from 'react';
import './index.scss';
import CloseBtn from '../close-btn';
import { PAGES } from '../../constants/options';
import { useSelector } from 'react-redux';
import {getMediaUrl} from "../../helper/media";
const PanoViewer = (props) => {
  const { handleClosePanoViewer } = props;
  const page = useSelector((state) => state.home.page);
  const isShowImmerse = useSelector((state) => state.home.isShowImmerse);
  const activePanotourId = useSelector((state) => state.home.activePanotourId);

  useEffect(() => {
    // const res = await mediaApi.getMediaDetail(activePanotourId);
    // setMedia(res.data);
  }, [activePanotourId]);
  const path = getMediaUrl('/panorama')//"http://localhost:3001/panorama" //"https://binyan-stanly.testbox.com.au/panorama" //media?.path;

  return (
    <>
      <div className={`wrap-pano-viewer`}>
        <iframe width="100%" height="100%" src={path} frameBorder="0" />
      </div>
      {PAGES.IMMERSE_PAGE !== page && isShowImmerse && (
        <CloseBtn handleClick={handleClosePanoViewer} />
      )}
    </>
  );
};

export default PanoViewer;
