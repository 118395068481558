import Markdown from "react-markdown";
import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from '../svgs/icons';

export const CollapsibleContent = ({
  className = '',
  title,
  content,
  textColor,
  showArrow = true,
  alwaysOpen = false,
  collapseIcon = undefined,
  expandIcon = undefined,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!alwaysOpen);

  const onToggle = () => {
    if (alwaysOpen) return;
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div className={`pb-2 ${className}`}>
      <div>
        <a
          className="cursor-pointer flex items-center justify-between mb-[10px]"
          onClick={onToggle}
        >
          <span className={`max-w-[95%] uppercase text-2xl`} style={{ color: textColor || '#01344b' }}>
            {title}
          </span>
          {!showArrow
            ? null
            : !isCollapsed ? (
              collapseIcon || <ChevronUp style={{ width: 24 }} fill={"#2D2927"} />
            ) : (
              expandIcon || <ChevronDown style={{ width: 24 }} fill={"#2D2927"} />
            )
          }
        </a>
        <div className='h-[1px] w-[100%] bg-[#2d292780]' />
        <div className={`my-[15px] w-[70%] collapse ${!isCollapsed ? "show visible" : ''}`}>
          <Markdown>{content}</Markdown>
        </div>
      </div>
    </div>
  );
};
