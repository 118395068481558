import React, { useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as unitExploreAct from "../../../../reduxs/unit-explore/action";

const useUnit = (props) => {
  const { handleUnitClick, setActiveObjectIds, isPresentation } = props;
  const dispatch = useDispatch();
  const floorPlanGalleryRef = useRef();

  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan,
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour,
  );
  const isShowUnitDetail = useSelector(
    (state) => state.unitExplore.isShowUnitDetail,
  );
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const units = useSelector((state) => state.unitExplore.units);
  const filteredUnits = useSelector((state) => state.unitExplore.filteredUnits);

  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom,
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect,
  );
  const filterUnitRoomType = useSelector(
    (state) => state.unitExplore.filterUnitRoomType,
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice,
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize,
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability,
  );
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);

  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList,
  );

  const isFiltered = useMemo(() => {
    return (
      filterUnitAspect ||
      filterUnitBedroom.length ||
      filterUnitPrice?.max ||
      filterUnitPrice?.min ||
      filterUnitRoomType.length ||
      filterUnitLotSize?.max ||
      filterUnitLotSize?.min ||
      filterUnitAvailability.length
    );
  }, [
    filterUnitAspect,
    filterUnitBedroom,
    filterUnitPrice,
    filterUnitRoomType,
    filterUnitLotSize,
    filterUnitAvailability,
  ]);

  useEffect(() => {
    if (isFiltered && !selectedUnit) {
      setActiveObjectIds(
        filteredUnits.map((item) => {
          return item["3d_asset"]?.id;
        }),
      );
    } else if (selectedUnit) {
      const unitFiltereds = units.filter((item) => item.id == selectedUnit.id);
      if (unitFiltereds.length)
        setActiveObjectIds([unitFiltereds[0]["3d_asset"]?.id]);
    } else {
      setActiveObjectIds([]);
    }
  }, [filteredUnits, isFiltered, selectedUnit]);

  const handleGetUnit = () => {
    dispatch(unitExploreAct.reqGetUnitList(unitQuery));
  };

  const handleFilterUnit = () => {
    if (!units && !units.length) return;
    let filterUnits = [...units];
    filterUnits = filterUnits.filter((unit) => {
      if (filterUnitAspect && unit.aspect != filterUnitAspect.value)
        return false;

      if (filterUnitBedroom.length) {
        const bedroomValue = filterUnitBedroom.map((x) => x.value);
        if (!bedroomValue.includes(unit.bedrooms)) return false;
      }

      if (filterUnitAvailability.length) {
        const availabilityValue = filterUnitAvailability.map((x) => x.value);
        if (!availabilityValue.includes(unit.availabilityStatus)) return false;
      }

      if (filterUnitPrice) {
        if (filterUnitPrice.min && unit.price < filterUnitPrice.min)
          return false;
        if (filterUnitPrice.max && unit.price > filterUnitPrice.max)
          return false;
      }

      if (filterUnitLotSize) {
        if (filterUnitLotSize.min && unit.totalLot < filterUnitLotSize.min)
          return false;
        if (filterUnitLotSize.max && unit.totalLot > filterUnitLotSize.max)
          return false;
      }

      return unit;
    });
    dispatch(unitExploreAct.reqSetFilteredUnit(filterUnits));
  };

  useEffect(() => {
    handleGetUnit();
  }, []);

  useEffect(() => {
    handleFilterUnit();
  }, [
    filterUnitAspect,
    filterUnitBedroom,
    filterUnitPrice,
    filterUnitLotSize,
    filterUnitAvailability,
    units,
  ]);

  return {
    isShowFilter,
    floorPlanGalleryRef,
    isFiltered,
    isShowFloorplan,
    isShowGallery,
    isShowUnitDetail,
    isShowUnitList,
    isTransparent,
    isShowVirtualTour,
    handleUnitClick,
    isPresentation,
    selectedUnit,
  };
};

export default useUnit;
