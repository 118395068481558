import React, { useRef, useState, useEffect } from "react";
import { Form, FormControl, Modal } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import Select from 'react-select';
import closeIcon from "../../../assets/images/close-white.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import mediaAPI from '../../../apis/api/media';
import galleryApi from '../../../apis/api/gallery';
import { ContentSectionOptions } from ".";

const validationSchema = yup.object().shape({
	file: yup.mixed().required("No file chosen"),
	order: yup.number().required("Order is a required field"),
	contentTitle: yup.string().trim().required("Content title is a required field"),
	contentSection: yup.string().trim().required("Content section is a required field"),
});

const AddContentModal = ({ show, setShow, onCreateSuccess }) => {
	const [file, setFile] = useState();
	const [order, setOrder] = useState();
	const [contentTitle, setContentTitle] = useState('');
	const [contentSection, setContentSection] = useState('');
	const [associatedResidence, setAssociatedResidence] = useState('');
	const [isShowReplaceContentModal, setIsShowReplaceContentModal] = useState(false);
  const [errors, setErrors] = useState({}) 
  const fileRef = useRef();

	useEffect(() => {
		if (file) {
			setContentTitle(file.name);
		}
	}, [file])

	const handleAddNewContent = () => {
		switch(contentSection) {
			case "Images":
				handleAddNewContentImage();
				return;
			// case "Floorplans":
			// 	return;
			default:
				setErrors({...errors, contentSection: "Content section is a required field"})
				return;
		}
	}

	const handleAddNewContentImage = async () => {		
		try {
			const data = {
				order,
				contentTitle,
				contentSection,
				file,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });

			const formData = new FormData();
			formData.append('type', 'image');
			formData.append('order', result?.order);
			formData.append('name', result?.contentTitle)
			formData.append('path', 'media/media_images/image_gallery');
			formData.append('file', file);
			
			const uploaded = await mediaAPI.uploadMedia(formData);
			if (uploaded?.data) {
				await galleryApi.createGallery({
					type: "media_images",
					media: [uploaded?.data?.id],
				});
				toast.success('Add new content successfully!');
				handleClose && handleClose();
				onCreateSuccess && onCreateSuccess();
			}
		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	const onOpenFileInput = () => {
		if (!file || isShowReplaceContentModal) {
			fileRef.current.click();
		} else {
			handleOpenReplaceContentModal();
		}
  }

	const renderImagePreview = () => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return '/images/image.png';
  }

	const handleBrowserFile = (e) => {
		setFile(e.target.files[0]);
		handleCloseReplaceContentModal();
	} 

	const handleOpenReplaceContentModal = () => {
		setIsShowReplaceContentModal(true);
	}

	const handleCloseReplaceContentModal = () => {
		setIsShowReplaceContentModal(false);
	}

	if (isShowReplaceContentModal) {
		return (
			<Modal
				className="wrap-replace-content-modal"
				show={isShowReplaceContentModal}
				onHide={handleCloseReplaceContentModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>REPLACE CONTENT</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseReplaceContentModal} />
					</div>
					<div className="browser-file" onClick={onOpenFileInput}>
						BROWSE FILES
						<input ref={fileRef} accept="image/*" id="file" className="hidden" type="file" onChange={(e) => handleBrowserFile(e)} />
					</div>
					<div className="import" onClick={() => {}}>
						<span className="import-title">Or import from a Matterport URL</span>
						<div className="import-form">
							<input type="text" placeholder="Add a Matterport URL" />
							<span className="import-button">IMPORT</span>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}

	return (
		<Modal
			className="wrap-create-content-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>ADD NEW CONTENT</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="content">
					<div className="title">CONTENT</div>
					{
						file 
						? 
							<>
								<img className="img-fluid cursor-pointer" src={renderImagePreview()} alt="" />
								<input ref={fileRef} accept="image/*" id="file" className="hidden" type="file" onChange={(e) => handleBrowserFile(e)} />
								<div onClick={onOpenFileInput} className="upload-btn">
									<img className="upload-icon" src={uploadIcon} alt="upload-icon" />
								</div>
							</>
						: 
							<>
								<div className="browser-file" onClick={onOpenFileInput}>
									BROWSE FILES
									<input ref={fileRef} accept="image/*" id="file" className="hidden" type="file" onChange={(e) => handleBrowserFile(e)} />
								</div>
								<div className="import" onClick={() => {}}>
									<span className="import-title">Or import from a Matterport URL</span>
									<div className="import-form">
										<input type="text" placeholder="Add a Matterport URL" />
										<span className="import-button">IMPORT</span>
									</div>
								</div>
							</>
					}
					<span className="error">{errors?.file}</span>
				</div>
				<div className="info">
					<div className="title">INFORMATION</div>
					<div className="wrap-form-group">
						<Form.Group>
							<Form.Label>Content Title*</Form.Label>
							<FormControl
								id="contentTitle"
								name="contentTitle"
								type="input"
								value={contentTitle}
								placeholder="Content Title"
								onChange={(e) => setContentTitle(e.target.value)}
							/>
							<span className="error">{errors?.contentTitle}</span>
						</Form.Group>
					</div>
					<div className="wrap-form-group">
						<Form.Group>
							<Form.Label>Content Section*</Form.Label>
							<Select
								className={`content-section ${contentSection ? "selected" : ""}`}
								classNamePrefix="select"
								value={{ value: contentSection || '', label: contentSection || 'Select a content section' }}
								options={ContentSectionOptions}
								name="contentSection"
								isSearchable={false}
								menuPortalTarget={document.body}
								styles={{
									menuPortal: base => ({...base, zIndex: 9999})
								}}
								onChange={(item) => setContentSection(item?.value)}
							/>
							<span className="error">{errors?.contentSection}</span>
						</Form.Group>
					</div>
					<div className="wrap-form-group">
						<Form.Group>
							<Form.Label>Content Order*</Form.Label>
							<FormControl
								id="order"
								name="order"
								type="number"
								value={order}
								onChange={(e) => setOrder(e.target?.value)}
							/>
							<span className="error">{errors?.order}</span>
						</Form.Group>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="submit" onClick={handleAddNewContent}>SUBMIT</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddContentModal;
