import React from "react";
import './index.scss';

const LoadingIcon = () => {
  return (
    <div className="animate-[fadeTop_forwards] [animation-duration:1.4s] opacity-0 w-[102px] h-[102px] inline-block overflow-hidden bg-none absolute left-[0] -bottom-[10px]">
      <div className="ldio-h0h1lsshjn">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoadingIcon;
