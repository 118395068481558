import React from "react";
import SideNav from "../../../components/sidenav";
import "./index.scss";
import AccordionSidebar from "../../../components/accordion-sidebar";
import useUnitDetail from "./hooks/useUnitDetail";
import { formatMoney } from "../../../helper/unit";

const UnitDetail = (props) => {
  const {
    isShowGallery,
    isShowFloorplan,
    isShowVirtualTour,
    roomAreas,
    activeKeys,
    pageRef,
    onScroll,
    handleClickUnitDetail,
    handleCloseVirtualTour,
    handleShowVirtualTour,
    handleShowFloorplan,
    handleCloseFloorplan,
    handleCloseGallery,
    handleShowGallery,
    t,
    unit
  } = useUnitDetail(props);



  const renderBtnFloorplan = () => {
    if (isShowFloorplan) {
      return (
        <li
          onClick={handleCloseFloorplan}
          className="list-group-item text-uppercase text-decoration-underline"
        >
          {t("CLOSE FLOORPLAN")}
        </li>
      );
    }

    return (
      <li
        onClick={handleShowFloorplan}
        className="list-group-item text-uppercase"
      >
        {t("VIEW FLOORPLAN")}
      </li>
    );
  };

  const renderBtnGallery = () => {
    if (isShowGallery) {
      return (
        <li
          onClick={handleCloseGallery}
          className="list-group-item text-uppercase"
        >
          {t("CLOSE GALLERY")}
        </li>
      );
    }

    return (
      <li
        onClick={handleShowGallery}
        className="list-group-item text-uppercase"
      >
        {t("VIEW GALLERY")}
      </li>
    );
  };

  const renderBtnVirtualTour = () => {
    if (isShowVirtualTour) {
      return (
        <li
          onClick={handleCloseVirtualTour}
          className="list-group-item text-uppercase"
        >
          {t("CLOSE VIEWLINES")}
        </li>
      );
    }

    return (
      <li
        onClick={handleShowVirtualTour}
        className="list-group-item text-uppercase"
      >
        {t("VIEW VIEWLINES")}
      </li>
    );
  };

  const renderAspect = (value) => {
    if (value === "sea_view" || value === "city_view") return t(`UnitDetail.${value}`)
    return value
  }

  return (
    <SideNav
      position="right"
      isDark={false}
      id="sidenavDetail"
      heading={t("UnitDetail.header")}
      icon={unit?.name}
      footer={
        <div className="wrap-btn-detail mt-auto">
          {/*<img src={upgrade} alt="upgrade" className="wrap-btn-detail-img" />*/}
          <ul className="list-group mb-0 py-2">
            {renderBtnFloorplan()}
            {renderBtnGallery()}
            {unit?.virtualTour?.id && renderBtnVirtualTour()}
          </ul>
        </div>
      }
    >
      <div ref={pageRef} onScroll={onScroll} className={"sidenav__wrapper accordion-sidebar scroll-detail disable-scroll-bar"}>
        <div className="details">
          <AccordionSidebar
            id="unit-detail-detail"
            onToggle={handleClickUnitDetail}
            activeKeys={activeKeys}
            data={[
              {
                title: t("UnitDetail.DetailTitle"),
                items: [
                  {
                    title: t("UnitDetail.Availability"),
                    value: <span>{unit?.availabilityStatus}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle1"),
                    value: <span>{unit?.totalLot}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle2"),
                    value: <span>{unit?.bedrooms}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle3"),
                    value: <span>{renderAspect(unit?.aspect)}</span>
                  },
                  {
                    title: t("UnitDetail.itemTitle4"),
                    value: <span>{unit?.price ? `${formatMoney(unit?.price)?.substring(1)} USD` : 0}</span>
                  }
                ]
              }
            ]}
          />
        </div>
        <div className="room-area">
          <AccordionSidebar
            id="unit-detail-room-area"
            onToggle={handleClickUnitDetail}
            activeKeys={activeKeys}
            data={[
              {
                title: t("UnitDetail.DetailedAreaTitle"),
                items: [
                  {
                    title: "",
                    value: <ul className="room-area-list">
                      {roomAreas?.map((room, ind) =>
                        <li key={ind + room}>
                          <div className="content">
                            <span>{`${room.split(':')[0]}:`}</span>
                            <span>{room.split(':')[1]}</span>
                          </div>
                        </li>)}
                    </ul>
                  }
                ]
              }
            ]}
          />
        </div>
        <div className="service-charge">
          <AccordionSidebar
            id="unit-detail-service-charge"
            onToggle={handleClickUnitDetail}
            activeKeys={activeKeys}
            data={[
              {
                title: t("UnitDetail.ServiceChargeTitle"),
                items: [
                  {
                    title: "",
                    value: <><b>{formatMoney(unit?.serviceCharge)}</b><span> per annum</span></>
                  }
                ]
              }
            ]}
          />
        </div>
      </div>
    </SideNav>
  );
};

export default UnitDetail;
