import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { classNames } from "../../utils/helper";

const Video = ({
    loop,
    autoPlay,
    preload,
    muted,
    urlVideo,
    onEnded = () => {},
    type = "video/mp4",
    className = "",
}, ref) => {
    let videoRef = useRef(null);

    useImperativeHandle(ref, () => ({
        play: () => {
            if (videoRef.current && videoRef.current.play) {
                videoRef.current.play();
            }
        },
        mute: () => {
            if (videoRef.current) {
                videoRef.current.muted = true;
            }
        }
    }))

	return (
        <video
            ref={videoRef}
            loop={loop}
            autoPlay={autoPlay}
            preload={preload}
            muted={muted}
            className={classNames(
                className
            )}
            onEnded={onEnded}
        >
            <source src={urlVideo} type={type} />
        </video>        
    );
}

Video.displayName = "Video";

export default forwardRef(Video);
