import * as THREE from "three";
export default class SSRBuffers {
	constructor(width, height) {
		this.GBuffer = new THREE.WebGLMultipleRenderTargets(
			width,
			height,
			2
		);
		
		for ( let j = 0, il = this.GBuffer.texture.length; j < il; j ++ ) {
			this.GBuffer.texture[ j ].minFilter = THREE.NearestFilter;
			this.GBuffer.texture[ j ].magFilter = THREE.NearestFilter;
			this.GBuffer.texture[ j ].type = THREE.FloatType;
		}
		this.GBuffer.texture[ 0 ].name = 'normal';
		this.GBuffer.texture[ 1 ].name = 'position';
		this.GTextures = { 
			normal:   this.GBuffer.texture[ 0 ],
			position: this.GBuffer.texture[ 1 ],
		};
		
		this.bufferMaterial = new THREE.RawShaderMaterial({
			uniforms: {
				meshId: { value: -1 },
			},
			
			vertexShader: `
					in vec3 position;
					in vec3 normal;
					in vec2 uv;
					out vec3 vNormal;
					out vec3 vViewPosition;
					out vec2 vUv;
					out float vDepth;
					uniform mat4 modelMatrix;
					uniform mat4 modelViewMatrix;
		uniform mat4 viewMatrix;
		uniform mat4 projectionMatrix;
		uniform mat3 normalMatrix;
					void main() {
							// world space normal
							// vNormal = (transpose(inverse(modelMatrix)) * vec4(normal, 1.0)).xyz;  
							// view space normal
							vNormal = normalMatrix * normal;
							
							// vPosition = (modelMatrix * vec4(position, 1.0)).xyz;  
							vViewPosition = (modelViewMatrix * vec4(position, 1.0)).xyz;  
							vDepth = - (modelViewMatrix * vec4(position, 1.0)).z;  
							vUv = uv;
							gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);    
					}
			`,
			fragmentShader: `
					precision highp float;
					precision highp int;
					in vec3 vNormal;
					in vec3 vViewPosition;
					in vec2 vUv;
					in float vDepth;
					layout(location = 0) out vec4 out_normal;
		layout(location = 1) out vec4 out_position;
					uniform float meshId;
					void main() {
							out_normal      = vec4(normalize(vNormal), 1.0);
							out_position    = vec4(vViewPosition, meshId);
					}
			`,
			glslVersion: THREE.GLSL3,
			side: THREE.DoubleSide,
		});
	}
	compute(renderer, scene, camera) {
		let autoClearOpt = renderer.autoClear;
		renderer.autoClear = false;
		renderer.setRenderTarget(this.GBuffer);
		renderer.clear();
		for(let i = scene.children.length - 1; i >= 0; i--) {
			let mesh = scene.children[i];
			// if we keep this uncommented we have to take into account that we might have
			// meshes that don't specify some textures / properties we might need
			// if(!mesh.material instanceof SSRMaterial) continue;
			
			// pointlights don't have materials assigned
			if(!mesh.material) continue;
			mesh.savedMaterial = mesh.material;
			mesh.material = this.bufferMaterial;
			this.bufferMaterial.uniforms.meshId.value = (mesh.meshId !== undefined ? mesh.meshId : -1);

			// Set membership to layer, and remove membership all other layers.
			// https://threejs.org/docs/#api/en/core/Layers
			mesh.layers.enable(31);
			camera.layers.set(31);
			renderer.render( scene, camera );
			camera.layers.enableAll();
			mesh.layers.disable(31);

			// reassign original material
			mesh.material = mesh.savedMaterial;
		}
		renderer.autoClear = autoClearOpt;
		renderer.setRenderTarget(null);
	}
}
