import './index.scss';
import * as yup from 'yup';
import Select from 'react-select';
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import customersAPI from '../../../../../apis/api/customer';
import closeIcon from "../../../../../assets/images/close-white.svg";
import HubSpotStopSyncCustomer from "../../../hubspot/hubspot-stop-sync-customer";
import HubSpotSyncUpdateCustomer from "../../../hubspot/hubspot-sync-update-customer";
import { CountryOptions, getSafeCountryCallingCode } from '../../../phone-input/phone-input';
import HubSpotConfirmSyncCustomer from '../../../hubspot/hubspot-confirm-sync-customer';

const validationSchema = yup.object().shape({
	firstname: yup.string().trim().required("First name is a required field"),
	surname: yup.string().trim().required("Last name is a required field"),
	email: yup.string().trim().email("Invalid email format").required("Email is a required field"),
	mobile: yup.string().trim().min(8).required("Phone is a required field"),
	countryCode: yup.string().trim().required("Country code is a required field"),
});

const EditCustomerModal = ({ customer, show, setShow, onSaveSuccess }) => {
	const [customerState, setCustomer] = useState(customer);
	const [firstname, setFirstName] = useState('');
	const [surname, setSurName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [address, setAddress] = useState('');
	const [countryCode, setCountryCode] = useState('');
	const [isShowDeleteCustomerModal, setIsShowDeleteCustomerModal] = useState(false);
	const [isShowHubSpotStopSyncModal, setIsShowHubSpotStopSyncModal] = useState(false);
	const [isShowHubSpotConfirmSyncModal, setIsShowHubSpotConfirmSyncModal] = useState(false);
	const [errors, setErrors] = useState({})

	useEffect(() => {
		if (customerState) {
			setFirstName(customerState.firstname || '');
			setSurName(customerState.surname || '');
			setEmail(customerState.email || '');
			setMobile(customerState.mobile || '');
			setAddress(customerState.address || '');
			setCountryCode(customerState.countryCode || '');
		}
	}, [customerState])

	const handleUpdateCustomer = async () => {
		if (!customerState) return;

		try {
			const data = {
				firstname,
				surname,
				email,
				mobile,
				address,
				countryCode,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await customersAPI.updateCustomer(customerState.id, result);
			if (res.data) {
				toast.success("Customer updated successfully!");
				handleClose && handleClose();
			}
		} catch (err) {
			if (err.inner) {
				const errs = {}
				err.inner.forEach(err => {
					errs[err.path] = err.message
				})
				setErrors(errs);
			}
		}
	};

	const handleDeleteCustomer = async () => {
		if (!customerState) return;

		try {
			const res = await customersAPI.deleteCustomer(customerState.id);
			if (res.data) {
				toast.success("Customer deleted successfully!");
				handleCloseDeleteCustomerModal();
				handleClose && handleClose();
			}
		} catch (err) {
			setErrors(err);
			toast.error(err.message);
		}
	}

	const handleOpenDeleteCustomerModal = () => {
		setIsShowDeleteCustomerModal(true);
	}

	const handleClose = () => {
		setShow(false);
		onSaveSuccess?.();
	};

	const handleCloseDeleteCustomerModal = () => {
		setIsShowDeleteCustomerModal(false);
	}

	const onSyncHubSpotSuccess = async () => {
		try {
			const res = await customersAPI.getCustomerDetail(customerState.id);
			setCustomer(res.data);
		} catch (ex) {
			console.err(ex);
		}
	}

	const onStopSyncHubSpot = () => {
		setIsShowHubSpotStopSyncModal(true);
	}

	const onSyncHubSpot = () => {
		setIsShowHubSpotConfirmSyncModal(true);
	}

	const handleCloseStopSyncHubSpotModal = () => {
		setIsShowHubSpotStopSyncModal(false);
	}

	const handleCloseConfirmSyncHubSpotModal = () => {
		setIsShowHubSpotConfirmSyncModal(false);
	}

	if (isShowDeleteCustomerModal) {
		return (
			<Modal
				className="wrap-delete-customer-modal"
				show={isShowDeleteCustomerModal}
				onHide={handleCloseDeleteCustomerModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Delete Customer?</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseDeleteCustomerModal} />
					</div>
					<div className="confirm">
						Are you sure you want to delete {firstname} {surname}?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div onClick={handleDeleteCustomer}>YES, DELETE</div>
				</Modal.Footer>
			</Modal>
		);
	}

	if (isShowHubSpotStopSyncModal) {
		return (
			<Modal
				className="wrap-delete-customer-modal"
				show={isShowHubSpotStopSyncModal}
				onHide={handleCloseStopSyncHubSpotModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>STOP SYNCING TO HUBSPOT</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseStopSyncHubSpotModal} />
					</div>
					<div className="confirm">
						Are you sure you want to stop syncing {firstname} {surname}? Hubspot will no longer receive updates about this profile.
					</div>
				</Modal.Body>
				<Modal.Footer>
					<HubSpotStopSyncCustomer
						customer={customerState}
						onStopSyncSuccess={() => {
							onSyncHubSpotSuccess();
							handleCloseStopSyncHubSpotModal();
						}}
					/>						
				</Modal.Footer>
			</Modal>
		);
	}

	if (isShowHubSpotConfirmSyncModal) {
		return (
			<Modal
				className="wrap-delete-customer-modal"
				show={isShowHubSpotConfirmSyncModal}
				onHide={handleCloseConfirmSyncHubSpotModal}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>SYNC TO HUBSPOT</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleCloseConfirmSyncHubSpotModal} />
					</div>
					<div className="confirm">
						Are you sure you want to sync {firstname} {surname} to Hubspot?
					</div>
				</Modal.Body>
				<Modal.Footer>
					<HubSpotConfirmSyncCustomer
						customer={customerState}
						onSyncSuccess={() => {
							onSyncHubSpotSuccess();
							handleCloseConfirmSyncHubSpotModal();
						}}
					/>						
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<Modal
			className="wrap-update-customer-modal"
			show={show}
			onHide={handleClose}
			centered
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title>Update Customer</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>First Name*</Form.Label>
						<FormControl
							id="firstname"
							name="firstname"
							type="input"
							value={firstname}
							placeholder="First Name"
							onChange={(e) => setFirstName(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
						<span className="error">{errors?.firstname}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>Last Name*</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							type="input"
							value={surname}
							placeholder="Last Name"
							onChange={(e) => setSurName(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
						<span className="error">{errors?.surname}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Address</Form.Label>
						<FormControl
							id="address"
							name="address"
							type="input"
							value={address}
							placeholder="Address"
							onChange={(e) => setAddress(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Email*</Form.Label>
						<FormControl
							id="email"
							name="email"
							type="input"
							value={email}
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
							disabled={customerState.isSyncHubSpot}
						/>
						<span className="error">{errors?.email}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Phone Number*</Form.Label>
						<div className="phone">
							<div className="phone-input">
								<div className="flag">
									<div className={`country ${countryCode || 'hidden'}`} />
								</div>
							</div>
							<Select
								className="mobile-code"
								classNamePrefix="select"
								value={{ value: countryCode || '', label: `+${+getSafeCountryCallingCode(countryCode)}` }}
								options={CountryOptions}
								name="countryCode"
								isSearchable={false}
								menuPortalTarget={document.getElementsByClassName("modal-content")[0]}
								styles={{
									menuPortal: base => ({ ...base, zIndex: 9999 })
								}}
								onChange={(item) => setCountryCode(item?.value)}
								isDisabled={customerState.isSyncHubSpot}
							/>
							<FormControl
								className="mobile-input"
								id="mobile"
								name="mobile"
								type="input"
								value={mobile}
								placeholder="Phone"
								onChange={(e) => setMobile(e.target.value)}
								disabled={customerState.isSyncHubSpot}
							/>
						</div>
						<span className="error">{errors?.countryCode || errors.mobile}</span>
					</Form.Group>
				</div>
				{/* <div className="wrap-form-group">
					<HubSpotSyncUpdateCustomer customer={customerState} onSyncSuccess={onSyncHubSpot} onStopSyncSuccess={onStopSyncHubSpot} />
				</div> */}
			</Modal.Body>
			<Modal.Footer>
				<span className="save" onClick={handleUpdateCustomer}>Save</span>
				{/* <span className="delete" onClick={handleOpenDeleteCustomerModal}>DELETE CUSTOMER</span> */}
			</Modal.Footer>
		</Modal>
	);
};

export default EditCustomerModal;
