import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TopNavTitle } from "../top-nav/top-nav-title";
import { ContentFloor } from "../../framework/components/gallery-landing/components/floor-plans/floor-plans";
import "./index.scss";
import { reqSetActiveGalleryId, reqSetPage } from "../../reduxs/home/action";
import { PAGES } from "../../constants/options";
import galleryApi from "../../apis/api/gallery";
import { LEVEL_TYPE } from "../../constants/master-data";

const Views = () => {
  const dispatch = useDispatch();

  const [floors, setFloors] = useState([]);

  useEffect(() => {
    handleGetListLevel(LEVEL_TYPE.BAKU_LEVEL);
  }, []);
  
  const handleGetListLevel = async (type) => {
    try {
      const data = {
        "type[equal]": type,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setFloors(res.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  const onSelectLevel = () => {
    //TODO: need update unit?.gallery?.id
    dispatch(reqSetActiveGalleryId("62ce8edd7d4f8bc038696ccb"));
    dispatch(reqSetPage(PAGES.VIEWS_LEVEL));
  };
  return (
    <div className="static-page-wf views">
      <div className="main">
        <TopNavTitle title={"VIEWS"} />
        <ContentFloor onClick={onSelectLevel} data={floors} />
      </div>
    </div>
  );
};

export default Views;
