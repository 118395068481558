import React from "react";
import UnitList from "./UnitList";
import UnitDetail from "./UnitDetail";
import UnitFilter from "./UnitFilter";
import "./index.scss";
import FloorPlanGallery from "../../../components/floor-plan-gallery";
import Gallery from "../../../components/gallery";
import VirtualTour from "../../../components/virtual-tour";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useUnit from "./hooks/useUnit";

const UnitExplore = (props) => {
  const {
    isShowFilter,
    floorPlanGalleryRef,
    isShowFloorplan,
    isShowGallery,
    isShowUnitDetail,
    isShowUnitList,
    isTransparent,
    isShowVirtualTour,
    handleUnitClick,
    isPresentation,
    selectedUnit
  } = useUnit(props);

  return (
    <>
      <div className={`wrap-sidenav`}>
        <CSSTransition
          in={isShowFilter}
          timeout={500}
          classNames="fade-left"
          unmountOnExit
        >
          <UnitFilter isPresentation={isPresentation} isTransparent={isTransparent} />
        </CSSTransition>
        <div
          className={`float-start position-relative`}
        >
          <CSSTransition
            in={!isTransparent && isShowUnitList}
            timeout={500}
            classNames="fade-left"
            unmountOnExit
          >
            <UnitList isPresentation={isPresentation} handleUnitClick={handleUnitClick} />
          </CSSTransition>
        </div>
      </div>
      <CSSTransition
        in={!!selectedUnit && isShowUnitDetail}
        timeout={500}
        classNames="fade-item"
        unmountOnExit
      >
        <div
          className={`wrap-sidenav wrap-sidenav--right ${isTransparent ? "invisible" : ""
            }`}
        >
          <UnitDetail isPresentation={isPresentation} />
        </div>
      </CSSTransition>

      <CSSTransition
        in={isShowFloorplan}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <FloorPlanGallery isPresentation={isPresentation} floorPlanGalleryRef={floorPlanGalleryRef} />
      </CSSTransition>

      <TransitionGroup>
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Gallery isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowVirtualTour && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <VirtualTour isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default UnitExplore;
