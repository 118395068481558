import React, { useImperativeHandle, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Language } from "../../../assets/images/language.svg";
import { isDarkPage } from "../../utils/helper";
import {
    reqSetIsShowLanguageModal,
} from "../../../reduxs/guide-session/action";
import socket from "../../../helper/socket";
import { ACTION_NAME } from "../../constants/options";
import { classNames } from "../../utils/helper";

const LanguageButton = ({
    isPresentation,
    className = "",
}, ref) => {
    const dispatch = useDispatch();
    const page = useSelector((state) => state.home.page);
    const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);

    useImperativeHandle(ref, () => ({
        handleLanguages: () => {
            handleLanguages();
        }
    }))

    const handleLanguages = () => {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.CLICK_LANGUAGE_BUTTON);
        }
        dispatch(reqSetIsShowLanguageModal(true));
    };

    return (
        <div
            onClick={handleLanguages}
            className={classNames(
                "cursor-pointer flex justify-center items-center",
                className
            )}
        >
            <Language fill={isDarkPage(page) && !isShowFloorplan ? "white" : "#01344B"} />
        </div>
    );
}

LanguageButton.displayName = 'LanguageButton';

export default forwardRef(LanguageButton);
