import React from "react";

const CreateIcon = ({
  onClick,
  className = "",
}) => {
  return (
    <div
      className={
        `${className} absolute right-0 bottom-20 z-111 cursor-pointer flex items-center shrink-0 gap-3 h-10 w-20\
				py-2 px-2 rounded-l-full bg-black shadow-[0_4px_5px_0_rgba(0,0,0,0.12)] create-component`
      }
      onClick={onClick}
    >
      <img className="img-fluid" src="/icons/icCreate.svg" alt="" />
    </div>
  )
};

export default CreateIcon;
