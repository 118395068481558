import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../../reduxs/home/action";
import { PAGES } from "../../constants/options";
import { actIntroduction } from "../../reduxs/scene/action";
import {
  reqGetCustomerProfile,
  reqResetCustomerProfile,
} from "../../reduxs/user/action";
import { useHistory } from "react-router-dom";
import { TopNavTitle } from "../top-nav/top-nav-title";
import { useTranslation } from "react-i18next";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import { EmailIcon, LocationIcon, PhoneIcon } from "../svgs/icons";

const Session = () => {
  const [counter, setCounter] = useState(5);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const customer = useSelector((state) => state.user.customer);
  const customerPreference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );

  const paths = history.location.pathname.split("/");
  const isPostEngagementPage = paths[paths.length - 1].length === 24; // mongodb object id length
  const customerId = paths[paths.length - 1];

  useEffect(() => {
    if (!customer && customerId) {
      dispatch(reqGetCustomerProfile(customerId));
    }
    if (!customerPreference) {
      dispatch(reqGetCustomerPreference(customer?.id));
    }
  }, [customer]);

  const refCount = useRef(5);
  const interValCountDown = useRef(null);

  useEffect(() => {
    if (isPostEngagementPage || localStorage.getItem("disableSessionCounter")) {
      return;
    }
    countTime();
    return () => clearInterval(interValCountDown.current);
  }, []);

  const countTime = () => {
    interValCountDown.current = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
      refCount.current -= 1;
      if (refCount.current <= 0) {
        handleCloseSession();
        clearInterval(interValCountDown.current);
      }
    }, 1000);
  };

  const handleCloseSession = () => {
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push("/");
    dispatch(actIntroduction(true));
    dispatch(reqResetCustomerProfile());
  };

  return (
    <div
      className="static-page-wf sessionPage"
      style={{ marginTop: isPostEngagementPage ? 0 : 80 }}
    >
      <div
        className="scroll-view"
        style={{
          marginBottom: isPostEngagementPage ? 0 : 80,
          height: isPostEngagementPage ? "100svh" : "calc(100svh - 80px)",
        }}
      >
        {!isPostEngagementPage ? (
          <TopNavTitle title={t("POST SESSION SUMMARY")} />
        ) : (
          <div className="session-title-wrapper">
            <div className="session-title-container">
              <div className="session-title-logo-wrapper">
                <img
                  className="session-title-logo"
                  src="/uploads/media/session-logo.png"
                />
              </div>
              <span className="session-title-text">
                {t("A NEW HOME, HAVEN & HUB IN BAKU")}
              </span>
            </div>
          </div>
        )}
        <div className="info-user">
          <span className="title-name">{`${t("HI")} ${customer?.name || ''},`}</span>
          <span className="title-des">
            {t(
              "Here are the items you expressed interest in during your session with us at The Ritz-Carlton Residences, Baku."
            )}
          </span>
        </div>
        {customerPreference?.units.map((unit, _index) => {
          // This is a loop with 4 different background color and 2 different image position (left / right)
          const index = _index % 4;
          const indexPosition = index % 2;
          return (
            <div className={`unit-view position-${index}`} key={_index}>
              <div className={`unit-details index-${index}`}>
                <span className={`unit-details-title index-${index}`}>
                  {t("Residence")} {unit.name}
                </span>
                <span className={`unit-details-des index-${index}`}>
                  {unit.description}
                </span>
              </div>
              <img
                className={`unit-image-1 index-${indexPosition}`}
                src="/uploads/media/session-unit-1.jpeg"
              />
              <img
                className={`unit-image-2 index-${indexPosition}`}
                src="/uploads/media/session-unit-2.jpeg"
              />
              <img
                className={`unit-image-3 index-${indexPosition}`}
                src="/uploads/media/session-unit-3.jpeg"
              />
              <img
                className={`unit-image-4 index-${indexPosition}`}
                src="/uploads/media/session-unit-4.jpeg"
              />
            </div>
          );
        })}
        <div className="post-session-contact-wrapper">
          <div className="post-session-contact">
            <img
              className="post-session-contact-image"
              src="/uploads/media/session-contact.png"
            />
            <div className="post-session-contact-info">
              <div className="post-session-contact-info-details">
                <span className="location-icon">
                  <LocationIcon />
                </span>
                <span>
                  3 Babek Avenue, Nasimi District, Baku, AZ1025 Azerbaijan
                </span>
              </div>
              <div className="post-session-contact-info-details">
                <span className="phone-icon">
                  <PhoneIcon />
                </span>
                <span>+994 55 268 0426</span>
              </div>
              <div className="post-session-contact-info-details">
                <span className="email-icon">
                  <EmailIcon />
                </span>
                <span>info@rcrbaku.com</span>
              </div>
            </div>
          </div>
          <span className="post-session-copyright-text">
            {t(
              'The Ritz-Carlton Residences, Baku are not owned, developed or sold by The Ritz-Carlton Hotel Company, L.L.C, or its affiliates ("Ritz-Carlton"). Yelken Operating Company MMC. uses The Ritz-Carlton marks under a license from Ritz-Carlton, which has not confirmed the accuracy of any of the statements or representations made herein.'
            )}
          </span>
        </div>
      </div>
      {!isPostEngagementPage && (
        <div className="close-view">
          <span>{`Closing and saving session in ${counter} seconds ...`}</span>
        </div>
      )}
    </div>
  );
};

export default Session;
