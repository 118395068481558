import React from "react";
import { Modal } from "react-bootstrap";

import closeIcon from "../../../assets/images/close-white.svg";

const ErrorModal = (props) => {
  const { onClose, isShow } = props;

  return (
    <Modal
      backdropClassName="opacity0"
      className="bg-[rgba(0,_0,_0,_0.8)] wrap-not-found"
      show={isShow}
      onHide={onClose}
      centered
    >
      <Modal.Body className="p-[16px] static">
        <div className="absolute right-[80px] top-[50px] w-[26px] h-[24px] z-110">
          <img src={closeIcon} alt="close-icon" onClick={onClose} />
        </div>
        <div className="flex flex-col">
          <span className="text-[#002846] font-['Adobe_Caslon_Pro'] text-[30px] not-italic font-normal leading-[normal] tracking-[2.1px] uppercase">CUSTOMER NOT FOUND</span>
          <span className="text-[#2d2927] font-['Proxima_Nova'] text-[14px] not-italic font-normal leading-[125%]">
            This combination of name and email does not match any customer in
            our system.{" "}
          </span>
          <button
            onClick={onClose}
            className="bg-transparent text-[#2d2927] border-[none] font-['Proxima_Nova'] text-[14px] w-[92px] uppercase text-left mt-[30px] p-0"
          >Close</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
