import { toast } from "react-toastify";
import unitApi from "../../../apis/api/unit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsMount } from "../../../helper/use-is-mount";
import { reqGetListUnits } from "../../../reduxs/cms/action";
import SearchIcon from "../../../framework/components/cms/search";
import CmsDataTable from "../../../framework/components/cms/data-table";
import UpdateResidenceModal from "../../../framework/components/cms/modals/update-residence";
import hubspotSettingsApi from "../../../apis/api/hubspotSettings";

const CMSResidence = () => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedData, setSelectedData] = useState()
  const [hubspotSettings, setHubspotSettings] = useState({});

  const isMount = useIsMount();

  useEffect(() => {
    getListProperties();
  }, []);

  useEffect(() => {
    if (!isMount) getListProperties();
  }, [search, isSortAsc])

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        search,
        sortBy: JSON.stringify({
          name: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const handleUpdate = async (data) => {
    try {
      await unitApi.putUnitUpdate(data.id, data)
      await getListProperties()
      toast.success("Unit updated successfully")
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getHubspotSettings();
  }, []);

  const getHubspotSettings = async () => {
    try {
      const res = await hubspotSettingsApi.getHubspotSettings();
      if (res && res.data) {
        setHubspotSettings(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSearch = (e) => {
    setSearch(e.target?.value || "");
  } 

  return (
    <>
      <UpdateResidenceModal
        show={!!selectedData} 
        data={selectedData} 
        onSave={handleUpdate} 
        onHide={() => setSelectedData(undefined)}
      />
      <div className="page-container">
        <div className="page-header">
          <h1 className="f-title mb-0">RESIDENCES CMS</h1>
        </div>
        <div className="text-center p-2">Last Synced timestamp: {hubspotSettings.lastSyncedUnitsTimestamp}</div>
        <div className="mt-20 pl-24 pr-20 page-body">
          <CmsDataTable
            type={'units'}
            data={units}
            selectedData={selectedData}
            onSelectData={setSelectedData}
          />
        </div>
        <SearchIcon handleInputChange={onSearch} />
      </div>
    </>
  );
};

export default CMSResidence;
