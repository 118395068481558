import CommonRow from "./common-row";
import React, { useMemo, useState } from "react";

const RowAmenities = ({
  onlyTitle = false,
  data = null,
  onEdit,
}) => {
  if (onlyTitle) {
    return (
      <CommonRow onlyTitle>
        <th className="col-4">Amenity</th>
        <th className="col-12">Description</th>
      </CommonRow>
    );
  }

  if (!data) return null;

  const [show, setShow] = useState(false);

  const collapsedRowClassName = useMemo(() => {
    return !show
      ? 'whitespace-nowrap overflow-hidden text-ellipsis !pr-3'
      : '';
  }, [show]);

  return (
    <CommonRow
      show={show}
      onShow={() => setShow(!show)}
      onClickOptions={() => onEdit?.(data)}
    >
      <td className={`col-4 ${collapsedRowClassName}`}>{data?.name}</td>
      <td className={`col-12 ${collapsedRowClassName}`}>{data?.description}</td>
    </CommonRow>
  );
}

export default RowAmenities;
