import CommonRow from "./common-row";
import { toast } from "react-toastify";
import React, { useState } from "react";
import customersAPI from '../../../../../apis/api/customer';
import { getSafeCountryCallingCode } from "../../../../../framework/components/phone-input/phone-input";

const RowCustomers = ({
  onlyTitle = false,
  data = null,
  onEditCustomer,
  onViewFavorite,
  onSaveSuccess
}) => {
  if (onlyTitle) {
    return (
      <CommonRow onlyTitle>
        <th className="col-3">Name</th>
        <th className="col-4">Email</th>
        <th className="col-3">Phone</th>
        <th className="col-4">ADDRESS</th>
        <th className="col-3">FAVORITES</th>
        <th className="col-4">UNIQUE URL</th>
        <th className="col-2">ACTIVE</th>
        <th className="col-3">HubSpot ID</th>
      </CommonRow>
    );
  }

  if (!data) return null;

  const [show, setShow] = useState(false);

  const handleCopyURL = () => {
    if (!data) return;
    const url = data?.uniqueUrl || `${process.env.REACT_APP_URL}/${data?.id}`;
    navigator.clipboard.writeText(url);
    toast.success('Copied customer session url!');
  }

  const handleUpdateActive = async (e) => {
    if (!data) return;

    try {
      const updated = {
        isActive: e?.target?.checked || false,
      };
      const res = await customersAPI.updateCustomer(data.id, updated);
      if (res.data) {
        toast.success("Customer updated successfully!");
        onSaveSuccess && onSaveSuccess();
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <CommonRow
      show={show}
      onShow={() => setShow(!show)}
      onClickOptions={() => onEditCustomer?.(data)}
    >
      <td className="col-3">{data?.name}</td>
      <td className="col-4">{data?.email}</td>
      <td className="col-3">{data?.countryCode ? `+${getSafeCountryCallingCode(data?.countryCode)}` : ''} {data?.mobile}</td>
      <td className="col-4">{show ? data?.address : '...'}</td>
      <td className="col-3">
        <text className="cursor-pointer hover:border-b hover:border-[#6B8FA3]" onClick={() => onViewFavorite?.(data)}>VIEW</text>
      </td>
      <td className="col-4">
        <div className="flex gap-1 h-7">
          <span className="py-1 !pl-0 !pr-[10px] w-[124px] text-[#2D2927] font-sans text-sm bg-white !whitespace-nowrap overflow-hidden text-ellipsis inline-block leading-none">{data?.uniqueUrl || `${process.env.REACT_APP_URL}/${data?.id}`}</span>
          <span className="inline-block !text-[#6B8FA3] font-serif text-[13px] uppercase px-[18px] py-1 bg-white cursor-pointer border-1 border-[#6B8FA3] leading-normal" onClick={() => handleCopyURL()}>COPY</span>
        </div>
      </td>
      <td className="col-2">
        <div className="form-check form-switch my-n1">
          <input
            type="checkbox"
            id="customer-state-1"
            defaultChecked={data?.isActive}
            onChange={handleUpdateActive}
            className="custom-checker form-check-input bg-[#d3d0d159] !w-14 h-6 !border-none shadow-none"
          />
          <label className="form-check-label" htmlFor="customer-state-1" />
        </div>
      </td>
      <td className="col-3">{data?.hubspotId}</td>
    </CommonRow>
  );
}

export default RowCustomers;
