import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PopupGallery from "./popup-gallery";

import galleryApi from "../../apis/api/gallery";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../framework/constants/options";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import socket from "../../helper/socket";

const VirtualTour = ({ isPresentation, isHidden }) => {
  const dispatch = useDispatch();
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const [gallery, setGallery] = useState("");
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const page = useSelector((state) => state.home.page);
  const isShowVirtualTour = useSelector(
    (state) => state.unitExplore.isShowVirtualTour
  );

  useEffect(() => {
    galleryApi.getGalleryDetail(activeGalleryId).then((res) => {
      setGallery(res.data);
    });
  }, [activeGalleryId]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
          enableTransparent();
        }
        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          showFilter();
        }
        if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
          hideViewLines()
        }
      })
    }
  }, [isPresentation]);

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideViewLines = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE)
    }
    dispatch(unitExploreAct.reqIsShowVirtualTour(false));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetActiveGalleryId(""));

    if (page === PAGES.VIEWS_LEVEL) dispatch(reqSetPage(PAGES.VIEWS_PAGE));
    else dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const enableTransparent = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(true))
  }

  return (
    <>
      <div className="virtual-tour">
        {activeGalleryId && (
          <PopupGallery
            isPresentation={isPresentation}
            listImage={gallery?.virtual_links || []}
            onClose={hideViewLines}
          />
        )}
      </div>
      {!isTransparent && isShowVirtualTour && (
        <div className="wrap-close-btn">
          <div onClick={enableTransparent} className="reopen">
            <span>Close panels</span>
            <img className="rotate-img" src="/icons/arrow.svg" alt="" />
          </div>
        </div>
      )}
      {isTransparent && !isHidden && isShowVirtualTour && (
        <div className="wrap-close-btn elementToFadeInAndOut">
          <div onClick={hideViewLines} className="close">
            <img src="/icons/arrow.svg" alt="" />
            <span>Close Viewlines</span>
          </div>
          <div onClick={showFilter} className="reopen">
            <span>Reopen panels</span>
            <img className="rotate-img" src="/icons/arrow.svg" alt="" />
          </div>
        </div>
      )}
    </>
  );
};

export default VirtualTour;
