import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ContentAppointment from "./ContentAppointment";
import ContentGallery from "./ContentGallery";
import ContentImage from "./ContentImage";
import "./index.scss";

export const ContentSectionOptions = [
  // { label: "Films", value: "Films" },
  // { label: "Floorplans", value: "Floorplans" },
  { label: "Images", value: "Images" },
  // { label: "Views", value: "Views" },
]

const CMSContent = () => {
  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">CONTENT CMS</h1>
      </div>
      <div className="page-body content-page-body">
        <Tabs
          defaultActiveKey="content-image"
          className=""
        >
          <Tab eventKey="content-film" title="FILMS">
            <div>Content Films</div>
          </Tab>
          <Tab eventKey="content-floorplan" title="FLOORPLANS">
            <div>Content FloorPlans</div>
          </Tab>
          <Tab eventKey="content-image" title="IMAGES">
            <ContentImage />
          </Tab>
          <Tab eventKey="content-view" title="VIEWS">
            <div>Content Views</div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default CMSContent;
