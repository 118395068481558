import React, { useEffect, useState } from "react";
import "./index.css";
import {
  ACTION_NAME,
  GALLERY_TYPE,
  WEBSOCKET_CHANNEL,
} from "../../../constants/options";
import socket from "../../../../helper/socket";
import { getUploadedServerMediaUrl } from "../../../../helper/media";
import PopupGallery from "./popup-gallery";
import galleryApi from "../../../../apis/api/gallery";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function splitToNChunks(array, n) {
  let result = [[], [], []];
  array.forEach((item, index) => {
    result[index % n].push(item);
  });
  return result;
}

const MediaImages = ({ isPresentation }) => {
  const [data, setData] = useState([]);
  const mediaData = data
    ?.map((item) => (item?.media?.length > 0 ? item?.media[0] : null))
    .filter((media) => media?.isActive);

  mediaData.sort((a, b) => (a?.order < b?.order ? -1 : 1));
  const mediaLayout = splitToNChunks(mediaData, 3);

  const [showGallery, setShowGallery] = useState(null);

  const loadData = async () => {
    try {
      const data = {
        "type[equal]": GALLERY_TYPE.MEDIA_IMAGES,
      };
      const res = await galleryApi.getListGalleryFloor(data);
      if (res) {
        setData(res?.data);
      }
    } catch (e) {
      console.log("error get floor");
    }
  };

  useEffect(() => {
    loadData();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_GALLERY) {
          handleShowGallery(content.data.idx);
        }
        if (content.action === ACTION_NAME.CLOSE_GALLERY) {
          handleCloseGallery();
        }
      });
    }
  }, [isPresentation]);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY, {
        idx,
      });
    }
    setShowGallery(idx);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY);
    }
    setShowGallery(false);
  };

  return (
    <div className="media-images-content">
      <div className="media-images-content-wrapper">
        {mediaLayout?.map((mediaRow, index) => (
          <div className={"media-images-content-wrapper-col"} key={index}>
            {mediaRow?.map((item, mediaIndex) => {
              const pathThumb =
                `${item?.path?.split(".")?.[0]}-thumb.jpeg` || item?.path;
              return (
                <div
                  key={mediaIndex}
                  onClick={() => handleShowGallery(item?.path)}
                  className="media-item"
                >
                  <LazyLoadImage
                    src={getUploadedServerMediaUrl(pathThumb)} // the source of the image
                    alt={item?.name} // alternative text for the image
                    effect="blur" // effect used when the image is loading (optional)
                    height="auto" // height of the image (optional)
                    width="100%" // width of the image (optional)
                    onError={(e) => {
                      e.currentTarget.src = getUploadedServerMediaUrl(item?.path)
                    }}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <PopupGallery
        isPresentation={isPresentation}
        show={showGallery}
        listImage={mediaData}
        onClose={handleCloseGallery}
      />
    </div>
  );
};

export default MediaImages;
