import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import unitApi from "../../apis/api/unit";
import { debounce } from "../../helper/unit";
import { useDispatch } from "react-redux";
import {
  reqIsShowUnitDetail,
  reqSetIsShowFilter,
  reqSetSelectedUnit,
} from "../../reduxs/unit-explore/action";
import { reqSetIsTransparent, reqSetPage } from "../../reduxs/home/action";
import { PAGES } from "../../constants/options";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { useTranslation } from "react-i18next";
import { BaseModal } from "../../framework/components/cms/modals/base-modal";

const SearchV2 = ({ resetState }) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState();
  const [units, setUnits] = useState([]);
  const [isShowModal, setShowModal] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    // const data = {
    //   search: keyword,
    // };
    // const unitRes = await unitApi.getUnitList(data);
    // setUnits(unitRes.data);
    // const amenityRes = await amenityApi.getAmenitiesList(data);
    // setAmenities(amenityRes.data);
    //
    // const poiRes = await poiApi.getPoisList(data);
    // // setPois(poiRes.data);
    handleSearch();
  }, [keyword]);

  const getSearch = async () => {
    const data = {
      search: keyword,
    };
    const unitRes = await unitApi.getUnitList(data);
    setUnits(unitRes.data);
  };

  const handleSearch = useCallback(debounce(getSearch, 600), [keyword]);

  const handleClickSearch = (item) => {
    dispatch(reqSetSelectedUnit(item.id));
    resetState();
    dispatch(reqSetIsShowFilter(true));
    dispatch(reqSetIsTransparent(false));
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
    dispatch(reqIsShowUnitDetail(true));
    // dispatch(reqIsShowUnitDetail(true));
    // const object = item['3d_asset'];
    // handleMoveCamera(object);
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const renderSearch = () => {
    return (
      <div className="searchPage">
        <span className="title-search">Search</span>
        <div className="search-input">
          <input
            onChange={(e) => setKeyword(e.target.value)}
            type="text"
            placeholder={t('Search Query')}
          />
          <button>Search</button>
        </div>
        <div className="wrapper">
          {units.map((i, idx) => {
            return (
              <div key={`${idx}-units`} className="item-search">
                <div
                  className="card-search"
                  onClick={() => handleClickSearch(i)}
                >
                  <span className="title">{i.name}</span>
                  <span className="textSub">{i.description}</span>
                </div>
              </div>
            );
          })}
          {units.length === 0 && (
            <div className="card-search">
              <span className="title" />
              <span className="textSub">No results found</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleClose = () => {
    setShowModal(false);
    resetState();
  };

  return (
    <BaseModal
      classNames="modal-search"
      open={isShowModal}
      onHide={handleClose}
      isOutSideClose={false}
      currentColor="#ffffff"
    >
      {renderSearch()}
    </BaseModal>
  );
};

export default SearchV2;
