import React from "react";
import jwtAuth from "../../../apis/utils/jwtAuth";
import icHome from "../../../assets/images/icHome.svg";
import { Link, useHistory, useLocation } from "react-router-dom";

const CMS_NAV_ITEMS = {
  left: [
    {
      label: 'RESIDENCES',
      href: '/cms/residence',
      show: true,
    },
    {
      label: 'AMENITIES',
      href: '/cms/amenities',
      show: true,
    },
    {
      label: 'CUSTOMERS',
      href: '/cms/customers',
      show: true,
    },
    {
      label: 'CONTENT',
      href: '/cms/content',
      show: true,
    }
  ],
  right: [
    {
      label: 'PROFILE',
      href: '/cms/profile',
      show: false,
    },
    {
      label: 'FAQS',
      href: '/cms/frequently-asked-questions',
      show: true,
    },
    {
      label: 'ANALYTICS',
      href: '/cms/analytics',
      show: false,
    },
    {
      label: 'HUBSPOT',
      href: '/cms/hubspot',
      show: true,
    },
  ]
}

const TopNavCMS = ({
  bg = 'black',
  color = '#fff',
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const getActiveClass = (path) => {
    return path === pathname ? 'border-b-[1px] border-[#ffffff] border-solid' : '';
  };

  const logout = () => {
    jwtAuth.removeToken();
    history.push("/holding");
  };
  return (
    <div className={`z-111 flex w-full h-[100px] py-[0] px-[50px] justify-center items-center sticky bg-[${bg}]`}>
      <div className="flex m-0 w-full justify-center items-center">
        <ul className="flex gap-y-2 gap-x-10 w-[40vw] mr-auto justify-start flex-wrap">
          {CMS_NAV_ITEMS.left.map((item, index) => {
            if (!item.show) return null;
            return (
              <li key={index} className={`flex items-center  ${getActiveClass(item.href)}`}>
                <Link to={item.href} className={`text-decoration-none text-[${color}]`}>
                  <div>{item.label}</div>
                </Link>
              </li>
            )
          })}
        </ul>
        <ul className="flex w-[35px] h-[35px] justify-center align-middle">
          <img src={icHome} alt="icon-cube" className="w-full h-full object-contain cursor-pointer" onClick={() => history.push('/')} />
        </ul>
        <ul className="flex gap-y-2 gap-x-10 w-[40vw] ml-auto justify-end">
          {CMS_NAV_ITEMS.right.map((item, index) => {
            if (!item.show) return null;
            return (
              <li key={index} className={`flex items-center ${getActiveClass(item.href)}`}>
                <Link to={item.href} className={`text-decoration-none text-[${color}]`}>
                  <div>{item.label}</div>
                </Link>
              </li>
            )
          })}
          <li onClick={() => logout()} className="flex items-center justify-end w-[90px]">
            <div className={`text-[${color}]`}>LOG OUT</div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TopNavCMS;
