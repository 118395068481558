import React, { useState, Fragment, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";

import ProjectTeamModal from "./components/ProjectTeamModal";
import { TopNavTitle } from "../top-nav/top-nav-title";

import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import imageFeatures1 from "../../assets/images/image-features-1.jpg";

const FeatureTab = ({ isPresentation }) => {
  const { t } = useTranslation();
  const [showProjectTeamModal, setShowProjectTeamModal] = useState(false);
  const [activeKeys, setActiveKeys] = useState([])

  const pageRef = useRef(null)

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.OPEN_FEATURE_DETAIL) {
          handleOpenFeature(content.data.currentActiveKeys, content.data.section)
        }
        if (content.action === ACTION_NAME.CLOSE_FEATURE_DETAIL) {
          handleCloseFeature(content.data.currentActiveKeys, content.data.section)
        }
        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop = content.data.scrollTop * pageRef.current.scrollHeight
          }
        }
      })
    }
  }, [isPresentation])

  const handleToggle = (section) => {
    if (activeKeys.includes(section)) {
      handleCloseFeature(activeKeys, section)
    } else {
      handleOpenFeature(activeKeys, section)
    }
  }

  const handleOpenFeature = (currentActiveKeys, section) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.OPEN_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    setActiveKeys([...currentActiveKeys, section])
  }

  const handleCloseFeature = (currentActiveKeys, section) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    const temp = currentActiveKeys.filter(x => x !== section)
    setActiveKeys(temp)
  }

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div ref={pageRef} onScroll={onScroll} className={`static-page-wf teamPage ${isPresentation && "presentation"}`}>
      <div className="main">
        <TopNavTitle title={t("common.FEATURE")} />
        <div className="header">
          {t("FeatureTab.timeless_design")}
          <br />
          {t("FeatureTab.exceptional_style")}
        </div>
        <img
          style={{ width: "100%", height: "100%", paddingBottom: 50 }}
          alt="imageFeatures1"
          src={imageFeatures1}
        />
        <div className="content apartment">
          <div className="content-message">
            <Accordion
              activeKey={activeKeys.includes("apartment") ? "apartment" : ""}
            >
              <Fragment key="list-group">
                <Accordion.Header
                  className={
                    "accordion-button accordion-sidebar-button txt-style"
                  }
                  eventKey="apartment"
                  onClick={() => handleToggle("apartment")}
                >
                  <span>{t("FeatureTab.apartment_features")}</span>
                  {!activeKeys.includes("apartment") ? (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 0.309071C29.064 0.348068 29.1495 0.405228 29.2214 0.477279C29.2935 0.54925 29.3507 0.634715 29.3897 0.728791C29.4287 0.822867 29.4487 0.923709 29.4487 1.02555C29.4487 1.12739 29.4287 1.22823 29.3897 1.3223C29.3507 1.41638 29.2935 1.50185 29.2214 1.57382L15.2725 15.5227C15.2006 15.5948 15.1151 15.6519 15.021 15.6909C14.927 15.7299 14.8261 15.75 14.7243 15.75C14.6224 15.75 14.5216 15.7299 14.4275 15.6909C14.3334 15.6519 14.248 15.5948 14.176 15.5227L0.2271 1.57382C0.1551 1.50182 0.0979872 1.41634 0.0590212 1.32227C0.0200552 1.2282 0 1.12737 0 1.02555C0 0.923725 0.0200552 0.8229 0.0590212 0.728827C0.0979872 0.634755 0.1551 0.549278 0.2271 0.477279C0.2991 0.405279 0.384576 0.348166 0.478649 0.3092C0.572721 0.270235 0.673547 0.250177 0.77537 0.250177C0.877193 0.250177 0.978019 0.270235 1.07209 0.3092C1.16616 0.348166 1.25164 0.405279 1.32364 0.477279L13.9493 13.103L14.3366 13.4496L14.7238 13.7962L15.1115 13.4496L15.4992 13.103L28.1249 0.477279C28.1969 0.405228 28.2823 0.348068 28.3764 0.309071C28.4705 0.270072 28.5713 0.25 28.6732 0.25C28.775 0.25 28.8759 0.270072 28.9699 0.309071Z"
                        fill="#2D2927"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 15.6909C29.064 15.6519 29.1495 15.5948 29.2214 15.5227C29.2935 15.4508 29.3507 15.3653 29.3897 15.2712C29.4287 15.1771 29.4487 15.0763 29.4487 14.9745C29.4487 14.8726 29.4287 14.7718 29.3897 14.6777C29.3507 14.5836 29.2935 14.4982 29.2214 14.4262L15.2725 0.477279C15.2006 0.405228 15.1151 0.34807 15.021 0.309071C14.927 0.270073 14.8261 0.25 14.7243 0.25C14.6224 0.25 14.5216 0.270073 14.4275 0.309071C14.3334 0.34807 14.248 0.405228 14.176 0.477279L0.2271 14.4262C0.1551 14.4982 0.0979872 14.5837 0.0590212 14.6777C0.0200552 14.7718 0 14.8726 0 14.9745C0 15.0763 0.0200552 15.1771 0.0590212 15.2712C0.0979872 15.3652 0.1551 15.4507 0.2271 15.5227C0.2991 15.5947 0.384576 15.6518 0.478649 15.6908C0.572721 15.7298 0.673547 15.7498 0.77537 15.7498C0.877193 15.7498 0.978019 15.7298 1.07209 15.6908C1.16616 15.6518 1.25164 15.5947 1.32364 15.5227L13.9493 2.89703L14.3366 2.55042L14.7238 2.20382L15.1115 2.55042L15.4992 2.89703L28.1249 15.5227C28.1969 15.5948 28.2823 15.6519 28.3764 15.6909C28.4705 15.7299 28.5713 15.75 28.6732 15.75C28.775 15.75 28.8759 15.7299 28.9699 15.6909Z"
                        fill="#2D2927"
                      />
                    </svg>
                  )}
                </Accordion.Header>
                <div className="mt-43" />
                <Accordion.Collapse eventKey="apartment">
                  <div className="content">
                    <div className="content-message">
                      <div className="paragraph">
                        <Markdown>
                          {t("FeatureTab.markdown_paragraph")}
                        </Markdown>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Fragment>
            </Accordion>
          </div>
        </div>

        <div className="content">
          <div className="content-message">
            <Accordion
              className="specification"
              activeKey={activeKeys.includes("specification") ? "specification" : ""}
            >
              <Fragment key="list-group">
                <Accordion.Header
                  className={
                    "accordion-button accordion-sidebar-button txt-style"
                  }
                  eventKey="specification"
                  onClick={() => handleToggle("specification")}

                >
                  <span>{t("FeatureTab.paragraph2_header")}</span>
                  {!activeKeys.includes("specification") ? (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 0.309071C29.064 0.348068 29.1495 0.405228 29.2214 0.477279C29.2935 0.54925 29.3507 0.634715 29.3897 0.728791C29.4287 0.822867 29.4487 0.923709 29.4487 1.02555C29.4487 1.12739 29.4287 1.22823 29.3897 1.3223C29.3507 1.41638 29.2935 1.50185 29.2214 1.57382L15.2725 15.5227C15.2006 15.5948 15.1151 15.6519 15.021 15.6909C14.927 15.7299 14.8261 15.75 14.7243 15.75C14.6224 15.75 14.5216 15.7299 14.4275 15.6909C14.3334 15.6519 14.248 15.5948 14.176 15.5227L0.2271 1.57382C0.1551 1.50182 0.0979872 1.41634 0.0590212 1.32227C0.0200552 1.2282 0 1.12737 0 1.02555C0 0.923725 0.0200552 0.8229 0.0590212 0.728827C0.0979872 0.634755 0.1551 0.549278 0.2271 0.477279C0.2991 0.405279 0.384576 0.348166 0.478649 0.3092C0.572721 0.270235 0.673547 0.250177 0.77537 0.250177C0.877193 0.250177 0.978019 0.270235 1.07209 0.3092C1.16616 0.348166 1.25164 0.405279 1.32364 0.477279L13.9493 13.103L14.3366 13.4496L14.7238 13.7962L15.1115 13.4496L15.4992 13.103L28.1249 0.477279C28.1969 0.405228 28.2823 0.348068 28.3764 0.309071C28.4705 0.270072 28.5713 0.25 28.6732 0.25C28.775 0.25 28.8759 0.270072 28.9699 0.309071Z"
                        fill="#2D2927"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 15.6909C29.064 15.6519 29.1495 15.5948 29.2214 15.5227C29.2935 15.4508 29.3507 15.3653 29.3897 15.2712C29.4287 15.1771 29.4487 15.0763 29.4487 14.9745C29.4487 14.8726 29.4287 14.7718 29.3897 14.6777C29.3507 14.5836 29.2935 14.4982 29.2214 14.4262L15.2725 0.477279C15.2006 0.405228 15.1151 0.34807 15.021 0.309071C14.927 0.270073 14.8261 0.25 14.7243 0.25C14.6224 0.25 14.5216 0.270073 14.4275 0.309071C14.3334 0.34807 14.248 0.405228 14.176 0.477279L0.2271 14.4262C0.1551 14.4982 0.0979872 14.5837 0.0590212 14.6777C0.0200552 14.7718 0 14.8726 0 14.9745C0 15.0763 0.0200552 15.1771 0.0590212 15.2712C0.0979872 15.3652 0.1551 15.4507 0.2271 15.5227C0.2991 15.5947 0.384576 15.6518 0.478649 15.6908C0.572721 15.7298 0.673547 15.7498 0.77537 15.7498C0.877193 15.7498 0.978019 15.7298 1.07209 15.6908C1.16616 15.6518 1.25164 15.5947 1.32364 15.5227L13.9493 2.89703L14.3366 2.55042L14.7238 2.20382L15.1115 2.55042L15.4992 2.89703L28.1249 15.5227C28.1969 15.5948 28.2823 15.6519 28.3764 15.6909C28.4705 15.7299 28.5713 15.75 28.6732 15.75C28.775 15.75 28.8759 15.7299 28.9699 15.6909Z"
                        fill="#2D2927"
                      />
                    </svg>
                  )}
                </Accordion.Header>
                <div className="mt-43" />
                <Accordion.Collapse eventKey="specification">
                  <>
                    <div>
                      <p className="paragraph2_title">
                        {t("FeatureTab.paragraph2_title")}
                      </p>
                      <p className="paragraph2_description">
                        {t("FeatureTab.paragraph2_description")}
                      </p>
                    </div>
                    <div className="content">
                      <div className="content-message">
                        <div className="paragraph">
                          {[...Array(6)].map((_, index) => (
                            <div key={`markdown-paragraph-${index}`}>
                              <Markdown>
                                {t(
                                  `FeatureTab.markdown_paragraph2_${index + 1}`
                                )}
                              </Markdown>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                </Accordion.Collapse>
              </Fragment>
            </Accordion>
          </div>
        </div>
        {
          !isPresentation && <div className="bottom-top" />
        }
      </div>
      <ProjectTeamModal
        show={showProjectTeamModal}
        onHide={() => setShowProjectTeamModal(false)}
      />
    </div>
  );
};

export default FeatureTab;
