import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reqGetListCustomers } from "../../../reduxs/cms/action";
import SearchIcon from "../../../framework/components/cms/search";
import CreateIcon from "../../../framework/components/cms/create";
import CmsDataTable from "../../../framework/components/cms/data-table";
import AddCustomerModal from "../../../framework/components/cms/modals/customer-modals/add-customer";
import EditCustomerModal from "../../../framework/components/cms/modals/customer-modals/edit-customer";
import CustomerFavoritesModal from "../../../framework/components/cms/modals/customer-modals/favorites";
import hubspotSettingsApi from "../../../apis/api/hubspotSettings";

const CMSCustomers = () => {
  const dispatch = useDispatch();

  const [isShowEditCustomerModal, setIsShowEditCustomerModal] = useState(false);
  const [isShowAddCustomerModal, setIsShowAddCustomerModal] = useState(false);
  const [isShowCustomerFavoritesModal, setIsShowCustomerFavoritesModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [search, setSearch] = useState('');
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [hubspotSettings, setHubspotSettings] = useState({});

  const customers = useSelector((state) => state.cms.customers);

  useEffect(() => {
    getListCustomers();
  }, [search, isSortAsc])

  useEffect(() => {
    getListCustomers();
  }, []);

  const getListCustomers = () => {
    dispatch(reqGetListCustomers({
      search: search || '',
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  useEffect(() => {
    getHubspotSettings();
  }, []);

  const getHubspotSettings = async () => {
    try {
      const res = await hubspotSettingsApi.getHubspotSettings();
      if (res && res.data) {
        setHubspotSettings(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSaveSuccess = () => {
    getListCustomers();
  }

  const onCreateSuccess = () => {
    getListCustomers();
  }

  const onEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setIsShowEditCustomerModal(true);
  }

  const onAddNewCustomer = () => {
    setIsShowAddCustomerModal(true);
  }

  const onViewFavorite = (customer) => {
    setSelectedCustomer(customer);
    setIsShowCustomerFavoritesModal(true);
  }

  const onSearch = (e) => {
    if (e.target?.value) {
      setSearch(e.target?.value);
    } else if (search) {
      setSearch('');
    }
  } 

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">CUSTOMERS CMS</h1>
      </div>
      <div className="text-center p-2">Last Synced timestamp: {hubspotSettings.lastSyncedCustomersTimestamp}</div>
      <div className="mt-20 pl-24 pr-20 page-body">
        <CmsDataTable
          type={'customers'}
          data={customers}
          onEdit={onEditCustomer}
          onViewFavorite={onViewFavorite}
          onSaveSuccess={onSaveSuccess}
        />
      </div>
      { isShowEditCustomerModal &&
        <EditCustomerModal 
          show={isShowEditCustomerModal}
          setShow={setIsShowEditCustomerModal}
          customer={selectedCustomer}
          onSaveSuccess={onSaveSuccess}
        />
      }
      {
        isShowAddCustomerModal &&
        <AddCustomerModal
          show={isShowAddCustomerModal}
          setShow={setIsShowAddCustomerModal}
          onCreateSuccess={onCreateSuccess}
        />
      }
      {
        isShowCustomerFavoritesModal && 
        <CustomerFavoritesModal
          show={isShowCustomerFavoritesModal}
          setShow={setIsShowCustomerFavoritesModal}
          customer={selectedCustomer}
        />
      }
      <div onClick={onAddNewCustomer}>
        <CreateIcon />
      </div>
      <SearchIcon handleInputChange={onSearch}/>
    </div>
  );
};
export default CMSCustomers;
