import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "../../utils/helper";
import { ACTION_NAME, PAGES } from "../../constants/options";
import socket from "../../../helper/socket";
import { useTranslation } from "react-i18next";
import { reqSetIsShowReplayVideo } from "../../../reduxs/precinct-explore/action";

const VideoSkipReplayButton = ({
    isPresentation,
    className = "",
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSkipReplayVideo = () => {
        if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.CLICK_SKIP_REPLAY_VIDEO);
        }
        dispatch(reqSetIsShowReplayVideo(false));
    }

    return (
        <button
          onClick={handleSkipReplayVideo}
          className={classNames(
            "absolute bottom-[75px] right-[35px] gap-[10px] flex-shrink-0 bg-transparent text-[white] justify-center items-center px-[18px] py-[12px] border-[1px] border-[solid] border-[#ffffff]",
            className
          )}
        >
          {t("common.skipVideo")}
        </button>
    );
}

export default VideoSkipReplayButton;
