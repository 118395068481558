import React, { useEffect, useState } from "react";

const SearchIcon = ({
	handleInputChange,
	className = "",
}) => {
	const [isOpenSearch, setIsOpenSearch] = useState(false);

	useEffect(() => {
		!isOpenSearch && handleInputChange?.("");
	}, [isOpenSearch])

	const handleOpenSearch = () => {
		setIsOpenSearch(!isOpenSearch);
	}

	return(
		<div
			className={
				`${className} absolute right-0 bottom-7 z-111 cursor-pointer flex items-center shrink-0 gap-3 h-10 py-2 px-2 rounded-l-full\
				bg-black shadow-[0_4px_5px_0_rgba(0,0,0,0.12)] transition-all duration-500 ${isOpenSearch ? "w-[300px]" : "w-20"}`
			}
		>
			<img className="img-fluid" src="/icons/icSearch.svg" alt="" onClick={handleOpenSearch}/>
			{
				isOpenSearch &&
				<input
					type="text"
					onChange={(e) => handleInputChange?.(e)}
					className="
						p-1 font-sans text-[#2D2927] text-left font-normal text-sm w-[90%]
						focus-visible:border-none focus-visible:outline-none
					"
				/>
			}
		</div>
	)
};

export default SearchIcon;
