import React from "react";
import {
  BEDROOM_OPTION,
  ASPECT_OPTION,
  AVAILABILITY_OPTIONS,
} from "../../constants/options";
import SideNav from "../../../components/sidenav";
import _ from "lodash";
import FilterGroupRange from "../../../components/filter-group-range";
import classNames from "classnames";
import useUnitFilter from "./hooks/useUnitFilter";

const UnitFilter = (props) => {
  const {
    isTransparent,
    refFilterRangePrice,
    t,
    refFilterRangeLot,
    ref,
    pageRef,
    isShowUnitList,
    filterUnitPrice,
    filterUnitAspect,
    filterUnitLotSize,
    filterUnitAvailability,
    filterUnitBedroom,
    onHideFilter,
    onScroll,
    isShowFilter,
    onChangeLotSize,
    onChangePrice,
    onResetAllFilter,
    onChangeBedroom,
    onFilterUnitAspect,
    onChangeAvailability
  } = useUnitFilter(props);


  return (
    <SideNav
      position="left"
      heading={t("UnitFilter.header")}
      id="filter"
      isDark={false}
      isTransparent={isTransparent}
    >
      <div
        ref={pageRef}
        className="scroll-filter disable-scroll-bar"
        onScroll={onScroll}
      >
        {/* filter avaibility */}
        <div className="filter-group">
          <h2 className="heading">{t("UnitFilter.AVAILABILITY")}</h2>
          <ul className="list-group flex flex-column justify-content-between flex-wrap">
            {AVAILABILITY_OPTIONS.map((item, key) => (
              <li
                className={`list-group-item border-none`}
                key={`filter-item-${key}`}
              >
                <div className="pretty p-svg p-plain">
                  <input
                    ref={(element) => {
                      ref.current[key] = element;
                    }}
                    onChange={(e) =>
                      onChangeAvailability(
                        e.target.checked,
                        item,
                        filterUnitAvailability,
                      )
                    }
                    type="checkbox"
                    checked={filterUnitAvailability
                      .map((avaibility) => avaibility.value)
                      .includes(item.value)}
                  />
                  <div className="state">
                    <img className="svg" src="icons/task-light.svg" />
                    <label>{t(`AVAILABILITY_OPTIONS.${item.title}`)}</label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* filter celling height */}

        <FilterGroupRange
          ref={refFilterRangePrice}
          label={{
            title: t("UnitFilter.PRICE_RANGE"),
            min: t("UnitFilter.min_price"),
            max: t("UnitFilter.max_price"),
          }}
          filterValue={{
            min: filterUnitPrice?.min,
            max: filterUnitPrice?.max,
          }}
          max={3000000}
          onChange={onChangePrice}
        />

        {/* filter celling height */}
        <FilterGroupRange
          ref={refFilterRangeLot}
          label={{
            title: t("UnitFilter.LOT_SIZE_RANGE"),
            min: t("UnitFilter.filterUnitLotSize1"),
            max: t("UnitFilter.filterUnitLotSize2"),
          }}
          max={400}
          icon={"----"}
          filterValue={{
            min: filterUnitLotSize?.min,
            max: filterUnitLotSize?.max,
          }}
          onChange={onChangeLotSize}
        />

        {/* filter bedrooms  */}
        <div className="filter-group">
          <h2 className="heading">{t("UnitFilter.BEDROOMS")}</h2>
          <ul className="list-group flex flex-row justify-content-between flex-wrap">
            {BEDROOM_OPTION.map((item, key) => (
              <li
                onClick={() => onChangeBedroom(item, filterUnitBedroom)}
                className={`list-group-number-item rounded-0 ${_.find(filterUnitBedroom, (i) => i.id == item.id)
                  ? "active"
                  : ""
                  }`}
                key={`filter-item-${key}`}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>

        {/* filter aspect */}
        <div className="filter-group aspect">
          <h2 className="heading">{t("UnitFilter.ASPECT")}</h2>
          <ul className="list-group flex flex-row">
            {ASPECT_OPTION.map((item, key) => (
              <li
                onClick={() => onFilterUnitAspect(item, filterUnitAspect)}
                className={`rounded-0 border-1 mb-1 list-group-item-btn ${item.id === filterUnitAspect?.id ? "active" : ""
                  }`}
                key={`filter-item-${key}`}
              >
                <div className="d-flex flex-column">
                  <small className="name">{t(item.name)}</small>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="btn-bottom">
          <span
            onClick={() => {
              onResetAllFilter();
            }}
            className={classNames(
              "text-center mt-1",
              (isShowUnitList && isShowFilter) ||
                filterUnitAspect !== "" ||
                filterUnitBedroom.length ||
                filterUnitPrice !== "" ||
                filterUnitLotSize !== "" ||
                filterUnitAvailability.length
                ? ""
                : "disable",
            )}
          >
            {t("UnitFilter.CLEAR_FILTERS")}
          </span>
          <span onClick={onHideFilter} className="text-center mt-1">
            {t("UnitFilter.HIDE_FILTERS")}
          </span>
        </div>
      </div>
    </SideNav>
  );
};

export default UnitFilter;
