import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "../../../framework/components/cms/search";
import { reqGetListAmenities } from "../../../reduxs/cms/action";
import EditAmenityModal from "./EditAmenityModal";
import CmsDataTable from "../../../framework/components/cms/data-table";

const CMSAmenities = () => {
  const dispatch = useDispatch();

  const [isShowEditAmenityModal, setIsShowEditAmenityModal] = useState(false);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [search, setSearch] = useState('');
  const [isSortAsc, toggleSortAsc] = useState(true);

  const amenities = useSelector((state) => state.cms.amenities);

  useEffect(() => {
    getListAmenities();
  }, [search, isSortAsc])

  useEffect(() => {
    getListAmenities();
  }, []);

  const getListAmenities = () => {
    dispatch(reqGetListAmenities({
      search: search || '',
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const onSaveSuccess = () => {
    getListAmenities();
  }

  const onEditAmenity = (amenity) => {
    setSelectedAmenity(amenity);
    setIsShowEditAmenityModal(true);
  }

  const onSearch = (e) => {
    setSearch(e.target?.value);
  }

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">AMENITIES CMS</h1>
      </div>
      <div className="page-body mt-20 pl-24 pr-20">
        <CmsDataTable
          type={'amenities'}
          data={amenities}
          onEdit={onEditAmenity}
        />
      </div>
      {
        isShowEditAmenityModal &&
        <EditAmenityModal 
          show={isShowEditAmenityModal}
          setShow={setIsShowEditAmenityModal}
          amenity={selectedAmenity}
          onSaveSuccess={onSaveSuccess}
        />
      }
      <SearchIcon handleInputChange={onSearch} />
    </div>
  );
};
export default CMSAmenities;
