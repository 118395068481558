import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  reqSetIsShowCreateConsultantProfileModal,
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowListConsultantModal,
  reqSetIsShowListInvestorModal,
  reqSetIsShowListTenantModal,
  reqSetIsShowUserTypeModal,
} from "../../../reduxs/guide-session/action";
import ErrorModal from "./error-modal";
import customerAPi from "../../../apis/api/customer";
import closeIcon from "../../../assets/images/close-white.svg";

import "./index.scss";

const ListUserSelectModal = (props) => {
  const { startDiscover } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [isShowNotFound, setShowNotFound] = useState(false);
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListTenantModal = useSelector(
    (state) => state.guideSession.isShowListTenantModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  const getListInvestor = async () => {
    const data = {
      firstname: firstName,
      surname: lastName,
      email: emailAddress,
    };

    const result = await customerAPi.findCustomer(data);
    if (result?.data) {
      onStartUserSession(result.data);
    } else {
      setShowNotFound(true);
    }
  };

  const getListConsultant = async () => {
    const data = {
      firstname: firstName,
      surname: lastName,
      email: emailAddress,
    };

    const result = await customerAPi.findCustomer(data);

    if (result?.data) {
      onStartUserSession(result.data);
    } else {
      setShowNotFound(true);
    }
  };

  const isShowCreateUserProfileModal = () => {
    history.push({
      search: "",
    });
    if (userSelectType === "investor") {
      dispatch(reqSetIsShowCreateInvestorProfileModal(true));
      dispatch(reqSetIsShowListInvestorModal(false));
    } else if (userSelectType === "tenant") {
      dispatch(reqSetIsShowCreateTenantProfileModal(true));
      dispatch(reqSetIsShowListTenantModal(false));
    } else if (userSelectType === "consultant") {
      dispatch(reqSetIsShowCreateConsultantProfileModal(true));
      dispatch(reqSetIsShowListConsultantModal(false));
    }
  };

  const handleClose = () => {
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));
  };

  const onStartUserSession = (user) => {
    history.push({
      search: `?customer=${user?.id}`,
    });
    dispatch(reqSetIsShowListInvestorModal(false));
    dispatch(reqSetIsShowListTenantModal(false));
    dispatch(reqSetIsShowListConsultantModal(false));

    analytics.track("Start Session", {
      customerType: userSelectType,
      customerFirstName: user.firstname,
      customerLastName: user.surname,
      customerEmail: user.email,
    });

    return startDiscover();
  };

  const handleCancel = () => {
    handleClose();
    dispatch(reqSetIsShowUserTypeModal(true));
  };

  const handleSubmitUser = async () => {
    if (isShowListInvestorModal) {
      await getListInvestor();
    }
    if (isShowListConsultantModal) {
      await getListConsultant();
    }
  };

  return (
    <>
      <Modal
        className="bg-[#000000e6] w-screen h-screen wrap-list-user-modal"
        show={
          isShowListTenantModal ||
          isShowListInvestorModal ||
          isShowListConsultantModal
        }
        onHide={handleClose}
        centered
      >
        <Modal.Body className="px-[40px] py-[36px] overflow-y-hidden static">
          <div className="absolute right-[80px] top-[50px] w-[26px] h-[24px] z-110">
            <img src={closeIcon} alt="close-icon" onClick={handleClose} />
          </div>
          <div className="text-[#002846] text-[30px] font-['Adobe_Caslon_Pro'] tracking-[2.1px] uppercase">
            {t("ListUserSelectModal.title")}
          </div>
          <div className="mb-4 text-[#2d2927] text-[14px] font-['Proxima_Nova']">
            {t("ListUserSelectModal.subTitle")}
            <span> </span>
            <button
              onClick={isShowCreateUserProfileModal}
              className="bg-[#fff] text-[#6b8fa3] font-normal border-[none] font-['Proxima_Nova'] text-[14px] not-italic leading-[125%]"
            >
              {t("ListUserSelectModal.create")}
            </button>
          </div>
          <div className="mb-[50px]">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col w-[48%]">
                <label
                  htmlFor="email"
                  className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]"
                >{t("ListUserSelectModal.fn")}</label>
                <input
                  autoCapitalize="none"
                  onChange={(e) => setFirstName(e.target.value)}
                  id="firsName"
                  placeholder={t("ListUserSelectModal.fn")}
                  className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
                />
              </div>
              <div className="flex flex-col w-[48%]">
                <label 
                  htmlFor="email"
                  className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]"
                >{t("ListUserSelectModal.ln")}</label>
                <input
                  autoCapitalize="none"
                  onChange={(e) => setLastName(e.target.value)}
                  id="lastName"
                  placeholder={t("ListUserSelectModal.ln")}
                  className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
                />
              </div>
            </div>
            <div className="flex flex-col w-[48%] w-100 mt-4">
              <label
                htmlFor="email"
                className="text-[rgba(45,_41,_39,_0.5)] text-left text-[14px] font-['Proxima_Nova'] leading-[125%]"
              >{t("ListUserSelectModal.Email")}</label>
              <input
                autoCapitalize="none"
                onChange={(e) => setEmailAddress(e.target.value)}
                id="email"
                placeholder={t("ListUserSelectModal.Email")}
                className="pl-0 border-[none] border-b-2 font-['Proxima_Nova'] text-[14px] text-left h-[50px] mt-[5px] bg-[#ffffff] focus:outline-none"
              />
            </div>
          </div>

          <div className="items-center flex justify-between">
            <button
              onClick={handleSubmitUser}
              className="bg-transparent uppercase border-[1px] border-[solid] border-[#59899d] text-[#59899d] text-[13px] font-['Adobe_Caslon_Pro'] tracking-[1.56px] px-[18px] py-[12px] h-[37px] leading-[normal]"
            >{t("common.Submit")}</button>
            <button
              onClick={handleCancel}
              className="bg-transparent uppercase border-[1px] border-[solid] border-[#59899d] text-[#59899d] text-[13px] font-['Adobe_Caslon_Pro'] tracking-[1.56px] px-[18px] py-[12px] h-[37px] leading-[normal]"  
            >{t("common.Cancel")}</button>
          </div>
        </Modal.Body>
      </Modal>
      <ErrorModal
        isShow={isShowNotFound}
        onClose={() => setShowNotFound(false)}
      />
    </>
  );
};

export default ListUserSelectModal;
