import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  Polygon,
  GroundOverlay,
} from '@react-google-maps/api';
import _ from 'lodash';
import { config, mapStyles } from './map-config';
import socket from "../../../helper/socket";

import './index.scss';
import { reqSetActivePoi } from '../../../reduxs/locations/action';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../../constants/options';
import { reqSetIsShowExploreModal } from '../../../reduxs/home/action';
import { CATEGORYs, POIs, markerIconSVG, activeMarkerIconSVG } from './test-data';

function svgIconToURL(svg) {
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
}

const markerIcon = {
  url: svgIconToURL(markerIconSVG),
};

const activeMarkerIcon = {
  url: svgIconToURL(activeMarkerIconSVG),
};

const activeMarkers = [];

let map;
let maps;

const onMapLoaded = (mapRef, mapsRef) => {
  map = mapRef;
  maps = mapsRef;
  map.setOptions({ styles: mapStyles.default.rules });
};

const Location = (props) => {
  //TODO: framework use fake data
  //When implement we will use from store ->  useSelector((state) => state.poi.data).sort((a, b) => a.index - b.index);
  const pois = POIs;
  const activePoi = useSelector((state) => state.poi.activePoi);
  const activeType = useSelector((state) => state.poi.activeType);
  const dispatch = useDispatch();
  const mapRef = useRef(false);
  const pageRef = useRef(null);
  const { isPresentation } = props;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyC2fmTkp-ummiBHRE2ee4MVD_rr-pZ9fcI',
  });

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      if (isPresentation) {
        socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      }
    };
  }, [isPresentation]);

  //TODO: framework use fake data
  // uncomment when implement
  // useEffect(() => {
  //   dispatch(reqGetPoiList());
  // }, []);


  useEffect(() => {
    // Set the color of the map
    const key = activeType === '' ? 'default' : activeType;
    const styles = mapStyles[Object.keys(mapStyles).includes(key) ? key : 'default'].rules;
    if (!!mapRef?.current?.state?.map) {
      mapRef.current.state.map.setOptions({ styles });
    }
  }, []);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_POI) {
      handleClickPoint(content.data.poi);
    }

    if (content.action === ACTION_NAME.CLICK_POI_ITEM) {
      handleShowItem(content.data.item);
    }

    if (content.action === ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED) {
      if (mapRef.current) {
        mapRef.current.state.map.setZoom(content.data.zoom);
      }
    }

    if (content.action === ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED) {
      if (mapRef.current) {
        mapRef.current.state.map.setCenter({
          lat: content.data.lat,
          lng: content.data.lng,
        });
      }
    }

    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      pageRef.current &&
        (pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight);
    }
  };

  const showInfoWindow = (data) => {
    dispatch(reqSetActivePoi(""));
    dispatch(reqSetActivePoi(data.id));
  };

  const handleClickSubPoint = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI, {
        poi,
      });
    }
    showInfoWindow(poi);
  };

  const handleClickPoint = (poi) => {
    if (poi.id === activePoi) {
      handleResetActivePoi();
    } else {
      handleClickSubPoint(poi);
    }
  };

  const handleResetActivePoi = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI, {
        poi,
      });
    }
    dispatch(reqSetIsShowExploreModal(false));
    clearActivePoi();
  }

  const handleShowItem = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI_ITEM, {
        item: poi,
      });
    }
    if (mapRef && mapRef.current) {
      poi.marker?.coordinates && mapRef.current.state.map.setCenter({ lat: poi.marker.coordinates.lat, lng: poi.marker.coordinates.lng });
      poi.marker?.coordinates && mapRef.current.state.map.setZoom(18);
    }
    handleClickPoint(poi);
  };

  const setActivePoi = (data) => {
    clearActivePoi();
    dispatch(reqSetActivePoi(data.id));
  };

  const clearActivePoi = () => {
    dispatch(reqSetActivePoi(''));
  };

  const renderMarker = (poi) => {
    if (!poi.action_types.includes('marker')) return;
    return (
      <Marker
        key={`${poi.id}_marker`}
        position={{
          lat: poi.marker.coordinates.lat,
          lng: poi.marker.coordinates.lng,
        }}
        icon={{
          ...markerIcon,
          clickable: true,
          scaledSize: !poi.marker.icon ? new google.maps.Size(35, 35) : undefined, // increase size for default marker icon only
          path: poi.marker.path ? poi.marker.path : markerIcon.path,
          url: poi.marker.icon ? svgIconToURL(poi.marker.icon) : (poi.id === activePoi ? activeMarkerIcon.url : markerIcon.url),
          anchor: !poi.marker.icon ? new google.maps.Point(13.5, 13.5) : undefined, // anchor 0 displaced the big icon
        }}
        onClick={() => {
          handleClickPoint(poi);
        }}
        onLoad={(e) => {
          activeMarkers.push(e.getPosition());
        }}
        onUnmount={(e) => {
          let lat = e.getPosition().lat();
          let lng = e.getPosition().lng();
          activeMarkers.splice(
            activeMarkers.findIndex((am) => {
              return am.lng() === lng && am.lat === lat;
            }, 1)
          );
        }}
        data={poi}
      >
        {activePoi === poi.id && poi.action_types.includes('info_window') && (
          <InfoWindow
            key={`${poi.id}_info_window`}
            onCloseClick={clearActivePoi}
            position={{
              lat: poi.marker.coordinates.lat,
              lng: poi.marker.coordinates.lng,
            }}
            options={{ closeBoxMargin: '100px 20px 2px 2px' }}
          >
            <div dangerouslySetInnerHTML={{ __html: poi.info_window.content }} />
          </InfoWindow>
        )}
      </Marker>
    );
  };

  const renderOverlay = (poi) => {
    if (!poi.action_types.includes('overlay')) return;
    return (
      <>
        <GroundOverlay
          url={poi.overlay.url}
          bounds={poi.overlay.bounds}
          opacity={0.5}
          onClick={() => {
            setActivePoi(poi);
          }}
          onDblClick={(e) => {
            e.domEvent.preventDefault();
          }}
        />
        {activePoi === poi.id && poi.action_types.includes('info_window') && (
          <InfoWindow
            onCloseClick={clearActivePoi}
            position={{
              lat: -37.81321293099207,
              lng: 144.93801607899866,
            }}
            options={{ closeBoxMargin: '100px 20px 2px 2px', padding: '100px' }}
          >
            <div dangerouslySetInnerHTML={{ __html: poi.info_window.content }} />
          </InfoWindow>
        )}
      </>
    );
  };

  const renderPolygon = (poi) => {
    if (!poi.action_types.includes('polygon')) return;
    return (
      <>
        <Polygon
          key={`${poi.id}_drawing`}
          paths={poi.polygon.coordinates}
          onClick={() => {
            setActivePoi(poi);
          }}
          onLoad={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => activeMarkers.push(item));
            });
          }}
          onUnmount={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => {
                let lat = item.lat();
                let lng = item.lng();
                activeMarkers.splice(
                  activeMarkers.findIndex((am) => {
                    return am.lng() === lng && am.lat === lat;
                  }, 1)
                );
              });
            });
          }}
          options={poi.polygon.options}
        />
        {poi.action_types.includes('info_window') && activePoi === poi.id && (
          <InfoWindow
            key={`${poi.id}_info_window`}
            onCloseClick={clearActivePoi}
            position={{
              lat: poi.polygon.coordinates[0].lat,
              lng: poi.polygon.coordinates[0].lng,
            }}
            options={{ closeBoxMargin: '100px 20px 2px 2px', padding: '100px' }}
          >
            <div dangerouslySetInnerHTML={{ __html: poi.info_window.content }} />
          </InfoWindow>
        )}
      </>
    );
  };

  const renderMap = () => {
    return (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={config.center}
        zoom={config.zoom}
        onLoad={(map, maps) => {
          onMapLoaded(map, maps);
        }}
        options={{
          maxZoom: 18,
          minZoom: 2.5,
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          isFractionalZoomEnabled: true,
        }}
        onZoomChanged={function () {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED, {
              zoom: this.zoom
            });
          }
        }}
        onCenterChanged={function () {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED, {
              lat: this.center.lat(),
              lng: this.center.lng(),
            });
          }
        }}
      >
        {pois.map((poi, index) => {
          if (
            (poi?.visibility?.default && activeType === '') ||
            poi?.visibility?.categories?.includes(activeType)
          )
            return (
              <React.Fragment key={index}>
                {renderMarker(poi)}
                {renderPolygon(poi)}
                {renderOverlay(poi)}
              </React.Fragment>
            );
        })}
      </GoogleMap>
    );
  };

  const renderPanel = () => {
    if (!activePoi) return;
    const poi = pois.find((poi) => poi.id === activePoi);
    if (!poi.action_types.includes('panel')) return;
    if (poi.title === 'Aba Mediterranean') {
      poi.panel.content = poi.panel.content.replaceAll('content-title', 'content-title font-size-26')
    }
    return (
      <div className='panel absolute overflow-hidden z-[100] flex w-[285px] h-[calc(100svh-122px-122px)] flex-col items-start gap-[30px] shrink-0 px-[35px] py-[30px] right-0 top-[122px] bg-[rgba(0,0,0,0.8)]'>
        <div className='w-full h-full' dangerouslySetInnerHTML={{ __html: poi.panel.content }} />
      </div>
    );
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <>
      <div className='absolute z-[100] flex w-[285px] h-[calc(100svh-122px-122px)] flex-col items-start gap-[30px] shrink-0 overflow-auto px-[35px] py-[30px] left-0 top-[122px] bg-[rgba(0,0,0,0.8)]' ref={pageRef} onScroll={onScroll}>
        <div className='text-white text-lg'>POINTS OF INTEREST</div>
        <div className='flex flex-col items-start gap-[30px] shrink-0 self-stretch'>
          {CATEGORYs.map((category) => (
            <React.Fragment key={category}>
              <div className='text-white border-t-[solid] border-t-[1px] flex flex-col justify-end items-start self-stretch pt-[15px]' onClick={() => {
                handleShowItem(category)
              }}>
                <span className='text-white text-lg not-italic font-normal leading-[normal] tracking-[2.25px] uppercase'>{category}</span>
              </div>
              {POIs.map((poi) => poi.category === category ? (
                <li
                  className={`list-none text-white flex items-start gap-4 cursor-pointer`}
                  key={poi.id}
                  onClick={() => {
                    handleShowItem(poi)
                  }}
                >
                  <span className={`text-sm not-italic font-normal leading-[14px] tracking-[1.4px] uppercase ${poi.id === activePoi ? 'text-[#aaf9f6]' : ''}`}>{poi.title}</span>
                </li>
              ) : null)}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className='absolute z-[90] w-full h-full overflow-hidden bg-[rgba(0,0,0,0)] left-0 top-0'>
        {isLoaded && renderMap()}
        {renderPanel()}
      </div>
    </>
  );
};

export default Location;
