import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PAGES } from "../../constants/options";
import icBaku from "../../../assets/images/baku_logo.svg";
import icBakuSmall from "../../../assets/images/baku_logo_small.png";
import icBakuSmallBlack from "../../../assets/images/baku_logo_small_black.png";
import { classNames } from "../../utils/helper";
import './index.scss';

const Header = ({
    isShow,
    handleClickCube,
    handleClickGallery,
    handleTeam,
    handleAmenities,
    handleServices,
    handleClickResidences,
    isPresentation,
    handleLocation
}) => {
    const { t } = useTranslation();
    const [isCollapse, setIsCollapse] = useState(false);
    const pageActive = useSelector((state) => state.home.page);
    const isShowFloorplan = useSelector(
        (state) => state.unitExplore.isShowFloorplan
    );
    
    const isShowReplayVideo = useSelector(
        (state) => state.precinctExplore.isShowReplayVideo
    );
    const page = useSelector((state) => state.home.page);
    
    const onClickCube = () => {
        handleClickCube();
        setIsCollapse(false);
    };
    
    useEffect(() => {
        isShow && onClickCube();
    }, [isShow]);
    
    const isActive = (menu) => {
        return pageActive === menu ? "active" : "";
    };
    
    const PageOnboardTopNav = () => (
        <div className="z-111 flex w-full h-[200px] pl-0 pr-0 py-[50px] justify-center items-center absolute top-[0]">
            <img className="w-[230px] cursor-pointer" src={icBaku} alt="icon-cube" onClick={onClickCube} />
        </div>
    );
    
    if(isPresentation) {
        return null
    }
    
    if (page === PAGES.ONBOARD_PAGE && !isShow && !isShowReplayVideo) {
        return <PageOnboardTopNav />;
    }

    return (
        <div
            className={classNames(
                "wrap-top-nav",
                "z-111 h-[80px] w-full absolute top-[0] bg-[#01344B] pointer-events-auto flex justify-between items-center px-[32px] py-0",
                isCollapse ? "hidden" : "block",
                page === PAGES.VIEWS_LEVEL && "bg-[linear-gradient(180deg,_#00000091_0%,_rgba(0,_0,_0,_0)_80%)]",
                isActive(PAGES.UNIT_EXPLORER_PAGE) ||
                isActive(PAGES.LANDING_PAGE) ||
                isActive(PAGES.AMENITIES_PAGE)
                ? isShowFloorplan
                ? "bg-[#F9F8F9]"
                : "bg-transparent"
                : "",
                page === PAGES.ONBOARD_PAGE && "bg-transparent",
            )}
        >
            {(isShow || isShowReplayVideo) && (
                <>
                    <img
                        className="cursor-pointer w-[264px] object-contain"
                        src={isShowFloorplan ? icBakuSmallBlack : icBakuSmall}
                        alt="icon-cube-small"
                        onClick={onClickCube}
                    />
                    <ul className="nav-right flex justify-center items-center gap-[40px] p-0 m-0 w-[fit-content]">
                        {[
                            [PAGES.UNIT_EXPLORER_PAGE, handleClickResidences, t("common.RESIDENCES")],
                            [PAGES.TEAM_PAGE, handleTeam, t("common.FEATURE")],
                            [PAGES.AMENITIES_PAGE_V2, handleAmenities, t("common.AMENITIES")],
                            [PAGES.SERVICES_PAGE, handleServices, t("common.SERVICES")],
                            [PAGES.GALLERY_LANDING_PAGE, handleClickGallery, t("common.MEDIA")],
                            [PAGES.LOCATION_PAGE, handleLocation, t("common.LOCATION")],
                        ].map(([page, onClickFunc, text]) => (
                            <li
                                key={text}
                                className={classNames(
                                    isActive(page),
                                    "text-[16px] not-italic font-semibold leading-[normal] uppercase flex h-[19px] flex-col items-center justify-center cursor-pointer"
                                )}
                                onClick={onClickFunc}
                            >
                                <span className={classNames(
                                    isShowFloorplan ? "text-[black]" : "text-[white]",
                                    "p-0 h-full"
                                )}>
                                    {text}
                                </span>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
}

export default Header;
