import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  reqGetCustomerProfile,
  reqGetUserProfile,
} from "../../../reduxs/user/action";
import { reqGetCustomerPreference } from "../../../reduxs/precinct-explore/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { classNames } from "../../utils/helper";
import socket from "../../../helper/socket";
import CustomerName from "../customer-name";
import { EndSessionButton, FavoriteButton, LanguageButton, LogoutButton } from "../buttons";
import { isDarkPage } from "../../utils/helper";
import CMSButton from "../buttons/cms-button";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Footer = ({
  isPresentation,
  setActiveObjectIds,
  className = "",
}) => {
  const dispatch = useDispatch();
  const customerId = useQuery().get("customer");
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const page = useSelector((state) => state.home.page);
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const languageBtnRef = useRef(null);
  const endSessionBtnRef = useRef(null);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action == ACTION_NAME.CLICK_END_SESSION) {
          endSessionBtnRef?.current?.handleShowPostSessionPopup?.();
        }
        if (content.action == ACTION_NAME.CLICK_LANGUAGE_BUTTON) {
          languageBtnRef?.current?.handleLanguages?.();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (customerId) {
      getCustomerPreference();
      getCustomerDetail();
    }
  }, [customerId]);

  const getCustomerDetail = () => {
    try {
      dispatch(reqGetCustomerProfile(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getCustomerPreference = async () => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      {isShowFloorplan && <div className="z-61 absolute bottom-[80px] h-px bg-[rgba(45,_41,_39,_0.50)] w-[calc(100% - 216px)] left-[108px]" />}
      <div
        className={classNames(
          "z-100 absolute bottom-0 w-full flex items-center px-[32px] py-[24px] text-[#000000]",
          !isDarkPage(page) && "bg-[#FCFCFC]",
          page === PAGES.VIEWS_LEVEL && "bg-[linear-gradient(360deg,_#00000091_0%,_rgba(0,_0,_0,_0)_80%)]",
          page === PAGES.SESSION_PAGE && "hidden",
          page === PAGES.ONBOARD_PAGE && "bg-transparent",
          isShowFloorplan && "bg-[#FCFCFC]",
          isPresentation && "hidden",
          className
        )}
      >
        <div className="flex-grow flex items-center text-[13px] font-bold font-[sans-serif]">
          <LanguageButton
            ref={languageBtnRef}
            isPresentation={isPresentation}
          />
          <LogoutButton
            setActiveObjectIds={setActiveObjectIds}
          />
          <EndSessionButton
            ref={endSessionBtnRef}
            setActiveObjectIds={setActiveObjectIds}
          />
        </div>
        {
          page !== PAGES.ONBOARD_PAGE &&
          <div className="flex items-center text-[13px] font-bold gap-[26px] text-[#FFFFFF]">
            <CustomerName />
            <FavoriteButton />
          </div>
        }
        {
          page === PAGES.ONBOARD_PAGE && !isShowReplayVideo &&
          <CMSButton />
        }
      </div>
    </>
  );
};

export default Footer;
