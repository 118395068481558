import './index.scss';
import { Modal } from "react-bootstrap";
import CmsDataTable from '../../data-table';
import React, { useState, useEffect } from "react";
import preferenceApi from '../../../../../apis/api/preference';
import LoadingIcon from "../../../../../components/loading-icon";
import closeIcon from "../../../../../assets/images/close-white.svg";

const CustomerFavoritesModal = ({ customer, show, setShow }) => {
	const [preference, setPreference] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		customer && getCustomerPreference();
	}, [customer]);

	const getCustomerPreference = async () => {
		try {
			setLoading(true);
			const res = await preferenceApi.getCustomerPreference({
				customer: customer?.id,
			});
			setLoading(false);
			setPreference(res.data);
		} catch (error) {
			setLoading(false);
		}
	}
	
	const handleClose = () => {
		setShow(false);
	};

	const renderGallery = () => {
		if (!preference || !preference?.units) return null;
		const sampleGalleries = preference?.units[0]?.gallery?.media || [];
		return (
			<>
				<div className="gallery-title">CONTENT</div>
				<div className="gallery">
					{
						sampleGalleries.slice(0, 3).map((item, index) => {
							return (
								<div key={item.id} className="gallery-item">
									<img src={`/uploads/${item.path}`} />
									<span>Image Title</span>
								</div>
							);
						})
					}
				</div>
			</>
		);
	}

	const renderContent = () => {
		if (loading) return <LoadingIcon />;

		if (!preference || !preference?.units) return <div className="content" style={{paddingLeft: "50px"}}>No favorites found.</div>;

		return (
			<div className="content">
				<CmsDataTable
					disable
          type={'favorites'}
          data={preference?.units}
					wrapperStyles={{ paddingLeft: 16 }}
					tBodyStyles={{ maxHeight: 221, paddingRight: 0 }}
				/>
				{renderGallery()}
			</div>
		);
	}

	return (
		<Modal
			className="wrap-customer-favorites-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header>
				<Modal.Title>{customer?.name}’s Favorites</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				{renderContent()}
			</Modal.Body>
		</Modal>
	);
};

export default CustomerFavoritesModal;
