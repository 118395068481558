import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as unitExploreAct from "../../../../reduxs/unit-explore/action";
import socket from "../../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";
const useUnitList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleUnitClick, isPresentation } = props;
  const refs = useRef({});
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const filteredUnits = useSelector((state) => state.unitExplore.filteredUnits);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const pageRef = useRef(null);
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SHOW_FILTER) {
          onShowFilter();
        }

        if (content.action === ACTION_NAME.PAGE_SCROLL) {
          if (pageRef.current && content.data) {
            pageRef.current.scrollTop =
              content.data.scrollTop * pageRef.current.scrollHeight;
          }
        }
      });
    }
  }, []);

  const onShowFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_FILTER);
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(true));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };
  return {
    t,
    refs,
    selectedUnit,
    filteredUnits,
    isShowFilter,
    pageRef,
    onScroll,
    onShowFilter,
    pageRef,
    handleUnitClick,
  };
};

export default useUnitList;
