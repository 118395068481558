import { FloatType, Mesh, PlaneGeometry, Scene, ShaderMaterial, Vector2, WebGLRenderTarget } from "three";
import Blit from "./blit";
let fw = window.innerWidth;
let fh = window.innerHeight;
const blurMaterial = new ShaderMaterial({
  uniforms: {
    uTexture:  { type: "t", value: null },
    uPosition: { type: "t", value: null },
    uNormal:   { type: "t", value: null },
    uHorizontal: { value: false },
    uStep: { value: 1 },
    uAR: { value: fw / fh },
    uPixelStep: { value: new Vector2(1 / fw, 1 / fh) },
  },
  vertexShader: `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = vec4(position.xy, 0.0, 1.0);    
    }
  `,
  fragmentShader: `
    uniform sampler2D uTexture;
    uniform sampler2D uPosition;
    uniform sampler2D uNormal;
    uniform vec2 uPixelStep;
    uniform bool uHorizontal;
    uniform float uStep;
    uniform float uAR;
    varying vec2 vUv;
    float weight[5] = float[] (0.227027, 0.1945946, 0.1216216, 0.054054, 0.016216);
    float rand(float co) { return fract(sin(co*(91.3458)) * 47453.5453); }
    float rand(vec2 co)  { return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453); }
    float rand(vec3 co)  { return rand(co.xy+rand(co.z)); }
    const float PI = 3.14159265359;
    float getAverageReflDistance(vec2 screenUV, float baseReflDistance) {
      float accum = 0.0;
      float effectiveSamples = 0.0;
      for (int i = 0; i < 10; i++) {
        float r = rand(gl_FragCoord.xy * 0.01789 + float(i) * 17.919);
        r *= 0.02 * baseReflDistance;
        float theta = rand(gl_FragCoord.xy * 0.13789 + float(i) * 27.919) * 2.0 * PI;
        float x = screenUV.x + r * cos(theta) * (1.0 / uAR);
        float y = screenUV.y + r * sin(theta);
        float meshId = texture2D(uPosition, vec2(x,y)).w;
        if (meshId == 0.0) {
          accum += texture2D(uTexture, vec2(x,y)).w;
          effectiveSamples += 1.0;
        }
      }
      if (effectiveSamples > 0.0) accum = accum / effectiveSamples;
      else accum = baseReflDistance;
      return accum;
    }
    void main() {   
      vec2 uv = gl_FragCoord.xy * uPixelStep;
      float blurRadius = uStep;
      vec4 ssrBuffer = texture2D(uTexture, uv);
      // in reality, reflection distance is recording the y-value of the reflected
      // skyscraper instead of actually calculating the length of the reflection
      float reflectionDistance = ssrBuffer.w;
      // reflectionDistance = getAverageReflDistance(uv, reflectionDistance);
      vec4 positionBuffer = texture2D(uPosition, uv);
      float centralMeshId = positionBuffer.w;
      vec3 positionVS = positionBuffer.xyz;
      vec3 normalVS = texture2D(uNormal, uv).xyz;
      vec3 viewDir = -normalize(positionVS);
      vec2 reflectionDir = normalize(reflect(-viewDir, normalVS)).xy; 
      vec2 reflectionNorm = vec2(-reflectionDir.y, reflectionDir.x); 
      vec2 reflectionOffset = uHorizontal ? reflectionNorm : reflectionDir;
      // setup 1 - follow reflection direction
      // if (uHorizontal) reflectionOffset *= 0.1;
      // setup 2 - follow reflection direction, and blur by reflection distance
      // if (uHorizontal) reflectionOffset *= 0.15;
      // if (reflectionDistance > 0.0) reflectionOffset *= max(reflectionDistance - 0.8, 0.025) * 2.0;
      // setup 3 - just blur vertically, and increase blur by reflection distance
      reflectionDir = vec2(0.0, -1.0);
      reflectionNorm = vec2(0.1, 0.0);
      reflectionOffset = uHorizontal ? reflectionNorm : reflectionDir;
      // if (reflectionDistance > 0.0) reflectionOffset *= max(reflectionDistance - 0.8, 0.0) * 2.0;
      // remember that the ssr algorithm will set reflectionDistance to 0 if no intersection is found
      reflectionOffset *= max(reflectionDistance - 0.8, 0.0) * 3.0;
      // reflectionOffset *= 0.0;
      vec3 accum = vec3(0.0);
      float effectiveSamples = 0.0;
      for(int i = -4; i <= +4; i++) {
        vec2 offs = reflectionOffset * vec2(
          uPixelStep.x * float(i) * blurRadius, 
          uPixelStep.y * float(i) * blurRadius
        );
        vec3 mult = vec3(1.0);
        if(i < 0) mult *= weight[abs(i)];
        if(i > 0) mult *= weight[i];
        if(i == 0) mult *= weight[0];
        vec3 value = texture2D(uTexture, uv + offs).xyz;
        // if we don't check that the meshId is the one of the ground, we'll
        // also blur some samples of the other meshes, because the position / normal
        // buffers are NearestFiltering
        float meshId = texture2D(uPosition, uv + offs).w;
        if (meshId > -0.5 && meshId < 0.5) {
          accum += value;
          effectiveSamples += 1.0;
        }
      }
      accum /= effectiveSamples;
      if (effectiveSamples == 0.0) {
        accum = ssrBuffer.xyz;
      }
 
      gl_FragColor = vec4(accum, 1.0);
    }
  `,
  depthTest: false,
  depthWrite: false,
});
const quadScene = new Scene();
const quadMesh = new Mesh(new PlaneGeometry(2, 2), blurMaterial);
quadMesh.frustumCulled = false;
quadScene.add(quadMesh);
let rt0 = new WebGLRenderTarget(fw, fh, { type: FloatType });
let rt1 = new WebGLRenderTarget(fw, fh, { type: FloatType });
const blitProgram = new Blit();
export function computeBlur(gl, camera, ssrRT, positionTexture, normalTexture) {
  blurMaterial.uniforms.uPosition.value = positionTexture;
  blurMaterial.uniforms.uNormal.value = normalTexture;
  // blitProgram.blit(gl, ssrRT.texture, rt0);
  blitProgram.blit(gl, ssrRT.texture, rt1);
  // const bloomSpread = 1.65;
  const bloomSpread = 2.0;
  gl.autoClear = false;
  // for (let i = 0; i < 3; i++) {
  for (let i = 0; i < 2; i++) {
    blurMaterial.uniforms.uStep.value = (i + 1) * bloomSpread;
    // horizontal pass
    gl.setRenderTarget(rt0);
    blurMaterial.uniforms.uHorizontal.value = true;
    blurMaterial.uniforms.uTexture.value = rt1.texture;
    gl.render(quadScene, camera);
    // vertical pass
    gl.setRenderTarget(rt1);
    blurMaterial.uniforms.uHorizontal.value = false;
    blurMaterial.uniforms.uTexture.value = rt0.texture;
    gl.render(quadScene, camera);
  }
  gl.autoClear = true;
  blitProgram.blit(gl, rt1.texture, ssrRT);
  return { rt1, rt0 };
}
