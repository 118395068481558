import React from "react";
import { classNames } from "../../utils/helper";
import { sendToastMessage } from "../../utils/helper";
import hubspotAPI from "../../../apis/api/hubspot";

const HubSpotConfirmSyncCustomer = ({
    customer,
    onSyncSuccess,
    className = "",
}) => {
    const handleSyncCustomer = async () => {
        if (!customer) return;

        try {
            const res = await hubspotAPI.syncUpdateCustomer({
                customerId: customer.id,
                isSyncHubSpot: true,    
            });
            if (res.data) {
                sendToastMessage("Sync with HubSpot successfully","success");
                onSyncSuccess?.();
            } else {
                sendToastMessage("Sync with HubSpot failed", "error");
            }
        } catch (ex) {
            sendToastMessage("Sync with HubSpot failed", "error");
        }
    };
    
    return (
        <div
            className={classNames(
                className
            )}
            onClick={handleSyncCustomer}
        >
            YES, SYNC NOW
        </div>    
    );
}

export default HubSpotConfirmSyncCustomer;
