import './login.scss';
import React from 'react';
import LoginHolding from '../../framework/components/auth/login-holding';

const Login = () => {
  return (
    <div className="wrap-login-page">
      <div className="wrap-login-form">
        <div className="login-form col-lg-4 col-md-4 col-xs-6 col-sm-10">
          <h3>Sign in</h3>
          <LoginHolding
            postLoginRedirectUrl = '/dashboard'
            enableForgotPassword
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
