import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import hubspotSettingsApi from "../../../apis/api/hubspotSettings";
import hubspotAPI from "../../../apis/api/hubspot";

import "./index.scss";

const ApiKeySetting = ({ apiKey, setApiKey, onSave }) => {

  const handleCopyURL = () => {
    navigator.clipboard.writeText(apiKey);
    toast.success('Copied!');
  }
  
  return (
    <div className="flex items-center w-[48%] gap-4">
      <span className="font-sans text-sm font-bold">
        API Key:
      </span>
      <input
        className="pl-0 border-2 font-sans text-[14px] text-left min-h-[30px] bg-[#ffffff]"
        placeholder=""
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
      />
      <span className="font-normal border-2 px-[12px] py-[3px] flex w-[fit-content] text-sm cursor-pointer" onClick={handleCopyURL}>COPY</span>
      <span className="font-normal border-2 px-[12px] py-[3px] flex w-[fit-content] text-sm cursor-pointer" onClick={onSave}>SAVE</span>
    </div>
  )
}

const CMSHubspot = () => {
  const [hubspotSettings, setHubspotSettings] = useState({});
  const [syncCustomersLoading, setSyncCustomersLoading] = useState(false);
  const [pollCustomersLoading, setPollCustomersLoading] = useState(false);
  const [syncResidencesLoading, setSyncResidencesLoading] = useState(false);
  const [pollResidencesLoading, setPollResidencesLoading] = useState(false);

  useEffect(() => {
    getHubspotSettings();
  }, []);

  const getHubspotSettings = async () => {
    try {
      const res = await hubspotSettingsApi.getHubspotSettings();
      if (res && res.data) {
        setHubspotSettings(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setApiKey = (apiKey) => {
    setHubspotSettings(prev => ({ ...prev, apiKey }));
  }

  const save = async () => {
    try {
			const res = await hubspotSettingsApi.updateHubspotSettings(hubspotSettings.id, hubspotSettings);
			if (res.data) {
				toast.success("Update Hubspot settings successfully!"); 
			}
		} catch (err) {
			toast.error(err.message);
		}
  }

  const handleSyncCustomersToHubspot = async () => {
    try {
      setSyncCustomersLoading(true);
      const res = await hubspotAPI.syncBatchCustomers();
      if (res.data) {
        toast.success("Sync all customers to Hubspot successfully!");
      } else {
        toast.error("Sync all customers to Hubspot failed!");
      }
      setSyncCustomersLoading(false);
    } catch (ex) {
      setSyncCustomersLoading(false);
      toast.error("Sync all customers to Hubspot failed!");
    }
  }

  const handlePollAllCustomers = async () => {
    try {
      setPollCustomersLoading(true);
      const res = await hubspotAPI.poolAllCustomers();
      if (res.data) {
        toast.success("Poll all customers from Hubspot successfully!");
      } else {
        toast.error("Poll all customers from Hubspot failed!");
      }
      setPollCustomersLoading(false);
    } catch (ex) {
      setPollCustomersLoading(false);
      toast.error("Poll all customers from Hubspot failed!");
    }
  }

  const handleSyncResidencesToHubspot = async () => {
    try {
      setSyncResidencesLoading(true);
      const res = await hubspotAPI.syncBatchResidences();
      if (res.data) {
        toast.success("Sync all residences to Hubspot successfully!");
      } else {
        toast.error("Sync all residences to Hubspot failed!");
      }
      setSyncResidencesLoading(false);
    } catch (ex) {
      setSyncResidencesLoading(false);
      toast.error("Sync all residences to Hubspot failed!");
    }
  }

  const handlePollAllResidences = async () => {
    try {
      setPollResidencesLoading(true);
      const res = await hubspotAPI.poolAllResidences();
      if (res.data) {
        toast.success("Poll all residences from Hubspot successfully!");
      } else {
        toast.error("Poll all residences from Hubspot failed!");
      }
      setPollResidencesLoading(false);
    } catch (ex) {
      setPollResidencesLoading(false);
      toast.error("Poll all residences from Hubspot failed!");
    }
  }

  return (
    <div className="page-container">
      <div className="page-header flex items-center">
        <h1 className="f-title mb-0">Hubspot</h1>
      </div>
      <div className="pl-[76px] pr-[108px] py-[0] mt-[85px] flex flex-col gap-4">
        { ('apiKey' in hubspotSettings) && <ApiKeySetting apiKey={hubspotSettings.apiKey} setApiKey={setApiKey} onSave={save}/> }
        <div className="flex items-center gap-4">
          <span>Customers:</span>
          <button
            className="border-[1px] w-[fit-content] cursor-pointer p-[4px] flex gap-1"
            onClick={handleSyncCustomersToHubspot}
          >
            Sync all customers to Hubspot
            { syncCustomersLoading && <i class="fa fa-spinner fa-spin"></i> }
          </button>
          <button
            className="border-[1px] w-[fit-content] cursor-pointer p-[4px] flex gap-1"
            onClick={handlePollAllCustomers}
          >
            Poll all customers from Hubspot
            { pollCustomersLoading && <i class="fa fa-spinner fa-spin"></i> }
          </button>
        </div>
        <div className="flex items-center gap-4">
          <span>Residences:</span>
          <button
            className="border-[1px] w-[fit-content] cursor-pointer p-[4px] flex gap-1"
            onClick={handleSyncResidencesToHubspot}
          >
            Sync all residences to Hubspot
            { syncResidencesLoading && <i class="fa fa-spinner fa-spin"></i> }
          </button>
          <button
            className="border-[1px] w-[fit-content] cursor-pointer p-[4px] flex gap-1"
            onClick={handlePollAllResidences}
          >
            Poll all residences from Hubspot
            { pollResidencesLoading && <i class="fa fa-spinner fa-spin"></i> }
          </button>
        </div>
      </div>
    </div>
  );
};
export default CMSHubspot;
