import React from "react";
import { classNames } from "../../utils/helper";
import { sendToastMessage } from "../../utils/helper";
import hubspotAPI from "../../../apis/api/hubspot";

const HubSpotStopSyncCustomer = ({
    customer,
    onStopSyncSuccess,
    className = "",
}) => {
    const handleStopSyncCustomer = async () => {
        if (!customer) return;

        try {
            const res = await hubspotAPI.syncUpdateCustomer({
                customerId: customer.id,
                isSyncHubSpot: false,    
            });
            if (res.data) {
                sendToastMessage("Stop syncing to HubSpot successfully","success");
                onStopSyncSuccess?.();
            } else {
                sendToastMessage("Stop syncing to HubSpot failed", "error");
            }
        } catch (ex) {
            sendToastMessage("Stop syncing to HubSpot failed", "error");
        }
    };
    
    return (
        <div
            className={classNames(
                className
            )}
            onClick={handleStopSyncCustomer}
        >
            YES, STOP SYNCING
        </div>    
    );
}

export default HubSpotStopSyncCustomer;
