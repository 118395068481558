import React from 'react';

const ModalFormRow = (props) => {
  return (
    <div className="flex flex-row justify-between mb-8">
      {props.children || null}
    </div>
  );
};

export default React.memo(ModalFormRow);
