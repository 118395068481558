import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isDarkPage } from "../../utils/helper";
import { ReactComponent as Logout } from "../../../assets/images/icLogout.svg";
import jwtAuth from "../../../apis/utils/jwtAuth";
import { classNames } from "../../utils/helper";

const LogoutButton = ({
    setActiveObjectIds,
    className = "",
}) => {
    const history = useHistory();
    const isShowFloorplan = useSelector((state) => state.unitExplore.isShowFloorplan);
    const page = useSelector((state) => state.home.page);
    const authUser = useSelector((state) => state.user.data);
    const customer = useSelector((state) => state.user.customer);

    const handleLogout = () => {
        setActiveObjectIds([])
        jwtAuth.removeToken();
        history.push("/holding");
    };
    
    if (customer || !authUser) return;

    return (
        <div
            onClick={handleLogout}
            className={classNames(
                "cursor-pointer ml-[20px] text-[20px]",
                className
            )}
        >
            <Logout
                fill={isShowFloorplan ? "#01344B"
                    : isDarkPage(page) ? "white"
                    : "#01344B"}
            />
        </div>
    );
}

export default LogoutButton;
