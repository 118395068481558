import React, { useEffect, useState, useRef } from "react";
import iconArrowBg from "../../../../../assets/images/bg-arrow.svg"
import "../index.css";
import { IconSwitchLevels } from "../../../svgs/icons";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../../constants/options";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {getImagePath} from "../../../../utils/media";
import socket from "../../../../../helper/socket";

export const ContentFloor = ({
                               data,
                               onClick,
                             }) => {
  data.sort((a, b) => (a.name === "PH" ? -1 : Number(b.name) - Number(a.name)));
  return (
    <div className="floor-plans-landing">
      <div className="header-content">the tower</div>
      <div className="line" />
      <div className="floor-plans-list">
        {data.map((floor) => (
          <div
            className="floor-plans-buttons"
            key={floor.name}
            onClick={() => onClick(floor)}
          >
            {floor.name === "PH" ? (
              <div className="floor-plans-buttons-ph">PH</div>
            ) : (
              <>
                <div className="floor-plans-buttons-title">FLOOR</div>
                <div className="floor-plans-buttons-number">{floor.name}</div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
const ContentPlans = ({
                        numberFloorSelected,
                        onSwitchFloor,
                        onShowGalleryData,
                        data,
                      }) => {
  const selectedItem = data?.find((i) => i.name == numberFloorSelected);
  const selectedItemMedia =
    selectedItem?.media?.length > 0 ? selectedItem?.media[0] : {};
  const _hotspots = selectedItemMedia?.hotspots
    ? Array.isArray(selectedItemMedia?.hotspots)
      ? selectedItemMedia?.hotspots
      : Object.values(selectedItemMedia?.hotspots)
    : [];

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleLoad = (event) => {
    const naturalWidth = event.target?.naturalWidth,
      naturalHeight = event.target?.naturalHeight;
    setImageSize({
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  return (
    <div className="content-plans">
      <div className="header-content">
        {numberFloorSelected === "PH"
          ? "PENTHOUSE"
          : `FLOOR ${numberFloorSelected}`}
      </div>
      <div className={`plans-body floor-${numberFloorSelected}`}>
        <img
          onLoad={handleLoad}
          src={getImagePath(selectedItemMedia?.path)}
          alt="plans-floor"
        />
        <div className="plans-marker">
          {_hotspots?.map((marker, index) => {
            return (
              <div
                key={String(index)}
                onClick={() =>
                  onShowGalleryData(`im-${marker?.floorplan}`, index)
                }
                style={{
                  position: "absolute",
                  left: marker.x,
                  top: marker.y,
                  cursor: "pointer",
                }}
              >
                <img src={getImagePath(marker?.image)} style={{ width: 40 }} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="btn-floor" onClick={() => onSwitchFloor()}>
        <IconSwitchLevels />
        <div className="btn-floor-text">SWITCH FLOORS</div>
      </div>
    </div>
  );
};

const FloorPlans = ({ data, isInfinity, isPresentation }) => {
  const [isShowFloorModal, setShowFloorModal] = useState(false);
  const [numberFloorSelected, setNumberFloorSelected] = useState("0");
  const [isFloorSelected, setIsFloorSelected] = useState(false);
  const [isShowViewPlans, setIsShowViewPlans] = useState(false);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_FLOOR_MODAL) {
      onShowFloorModal();
    }
    if (content.action === ACTION_NAME.HIDE_FLOOR_MODAL) {
      onHideFloorModal();
    }
    if (content.action === ACTION_NAME.SHOW_GALLERY_DATA) {
      onShowGalleryData(content.data.id, content.data.idx);
    }
    if (content.action === ACTION_NAME.SELECT_FLOOR) {
      onSelectedFloor(content.data.floor);
    }
    if (content.action === ACTION_NAME.SWITCH_FLOOR) {
      onSwitchFloor();
    }
    if (content.action === ACTION_NAME.SHOW_VIEW_PLAN) {
      handleShowViewPlan();
    }
    if (content.action === ACTION_NAME.CLOSE_VIEW_PLAN) {
      handleCloseViewPlans();
    }
    if (content.action === ACTION_NAME.CLOSE_RENDER_GALLERY) {
      onCloseImage();
    }
    if (content.action === ACTION_NAME.NEXT_IMAGE) {
      onNextImage(content.data.imgList);
    }
    if (content.action === ACTION_NAME.PREV_IMAGE) {
      onPrevImage(content.data.imgList);
    }
    if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
      if (!transformComponentRef.current) return
      if (content.data.state) {
        const ratioHeight = window.screen.height / content.data.height;
        const ratioWidth = window.screen.width * 0.7 / content.data.width;
        const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
        const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
        return transformComponentRef.current.setTransform(
          content.data.state.positionX * ratioWidth * marginWidth,
          content.data.state.positionY * ratioHeight * marginHeight,
          content.data.state.scale === 1 ? 1 : content.data.state.scale * (1 / marginWidth)
        )
      }
    }
  };

  const onHideFloorModal = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FLOOR_MODAL);
    }
    setShowFloorModal(false);
  };

  const onShowFloorModal = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_FLOOR_MODAL);
    }
    setShowFloorModal(true);
  };

  const [showGalleryData, setShowGalleryData] = useState({
    idxActive: 0,
    key: null
  });
  const refCurrentIdxActive = useRef(0);
  const selectedItem = data?.find((i) => i.name === numberFloorSelected);
  const imgList =
    (Array.isArray(selectedItem?.floorplans)
        ? selectedItem?.floorplans
        : []
    )?.map((currentValue) => {
      return { image: currentValue?.path, key: `im-${currentValue?.id}` };
    }) || [];

  const lengthImgList = imgList?.length || 0;

  const onShowGalleryData = (id = "", idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY_DATA, {
        id, idx
      });
    }
    setShowGalleryData({
      key: id,
      idxActive: idx,
    });
    refCurrentIdxActive.current = idx
  };

  const onSelectedFloor = (floor) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SELECT_FLOOR, {
        floor,
      });
    }
    setIsFloorSelected(true);
    setNumberFloorSelected(floor.name);
    setShowFloorModal(false);
  };

  const onSwitchFloor = (floor) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SWITCH_FLOOR, {
        floor,
      });
    }
    setIsFloorSelected(false);
    setNumberFloorSelected("");
    setShowFloorModal(false);
  };

  const handleShowViewPlan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_VIEW_PLAN);
    }
    setIsShowViewPlans(true);
  };

  const handleCloseViewPlans = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_VIEW_PLAN);
    }
    setIsShowViewPlans(false);
  };

  const onNextImage = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NEXT_IMAGE,{imgList});
    }
    if (refCurrentIdxActive.current === (data?.length - 1)) {
      setShowGalleryData({
        idxActive: 0,
        key: data[0].key,
      });
      refCurrentIdxActive.current = 0;
    } else {
      setShowGalleryData(v => ({
        idxActive: v?.idxActive + 1,
        key: data[v?.idxActive + 1].key,
      }));
      refCurrentIdxActive.current += 1;
    }
  };

  const onPrevImage = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PREV_IMAGE, {imgList});
    }
    if (refCurrentIdxActive.current === 0) {
      let idx = data?.length - 1;
      setShowGalleryData({
        idxActive: idx,
        key: data[idx].key,
      });
      refCurrentIdxActive.current = idx;
    } else {
      setShowGalleryData(v => ({
        idxActive: v?.idxActive - 1,
        key: data[v?.idxActive - 1].key,
      }));
      refCurrentIdxActive.current -= 1;
    }
  };

  const onCloseImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_RENDER_GALLERY);
    }
    setShowGalleryData({
      idxActive: 0,
      key: null
    });
  };
  const transformComponentRef = useRef(null);

  const renderViewImage = () => {
    return (
      <TransformWrapper
        ref={transformComponentRef}
        maxScale={2.5}
        minPositionX={window.screen.width * 1.55 * -1}
        minPositionY={window.screen.height * 1.7 * -1}
        maxPositionX={window.screen.width * 1.55}
        maxPositionY={window.screen.height * 1.7}
        wheel={{
          step: 0.1,
        }}
        pinch={{
          step: 0.1,
        }}
        onTransformed={(ref, state) => {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN, {
              state,
              height: window.screen.height,
              width: window.screen.width,
            });
          }
        }}
      >
        {({ zoomIn, zoomOut }) => (
          <>
            <div className="wrap-floor-plan-gallery">
              <TransformComponent
                contentStyle={{ height: "100%", width: "100vw" }}
              >
                {imgList?.length ? (
                  imgList.map((item, key) => {
                    if (item.key === showGalleryData.key)
                      return (
                        <img
                          key={key}
                          src={getImagePath(item.image)}
                          alt="floorplan"
                          className="floorplan-item"
                        />
                      );
                  })
                ) : (
                  <div className="floorplan-item img-not-found">
                    Image Not Found
                  </div>
                )}
              </TransformComponent>
            </div>
          </>
        )}
      </TransformWrapper>
    );
  };

  return (
    <div className={`floor-plans-content ${isInfinity ? "infinity" : ""}`}>
      {isFloorSelected ? (
        <ContentPlans
          numberFloorSelected={numberFloorSelected}
          setShowFloorModal={onShowFloorModal}
          onShowGalleryData={onShowGalleryData}
          onSwitchFloor={onSwitchFloor}
          data={data}
        />
      ) : (
        <ContentFloor onClick={onSelectedFloor} data={data} />
      )}
      {showGalleryData?.key && renderViewImage()}
      {!!showGalleryData?.key && (
        <>
          {lengthImgList !== 1 && (
            <div>
              <div
                className="fancy-btn-prev cursor-pointer"
                onClick={() => onPrevImage(imgList)}
              >
                <img src={iconArrowBg} alt="next" className="next-arrow" />
              </div>
              <div
                className="fancy-btn-next cursor-pointer"
                onClick={() => onNextImage(imgList)}
              >
                <img src={iconArrowBg} alt="next" className="pre-arrow" />
              </div>
            </div>
          )}

          <div className="wrap-fancy-close">
            <div className="cursor-pointer" onClick={onCloseImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
              >
                <path
                  d="M1 1L25 22.8182M25 1L1 22.8182"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default FloorPlans;
